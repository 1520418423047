// eslint-disable-next-line
//@ts-nocheck
import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid';

import {
  Area,
  CRTagMode,
  DisplayLimitData,
  Limits,
  Mode,
  SVGDefsSelection,
  TimeSeries,
} from '@controlrooms/models';
import { generateLimitRangeColor } from '@controlrooms/utils/src/chart-utils/chart-utils';

const filterData = (data: TimeSeries[]) => {
  return data.filter((item: TimeSeries) => item.max !== null && item.min !== null);
};

export const buildLimitArea = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  seriesData: TimeSeries[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
  limits: Limits,
  chartHeight: number,
  yExtended: [number, number],
  displayLimitData: DisplayLimitData,
  modes: Mode[] | CRTagMode[],
) => {
  const uuid = uuidv4();
  const data = filterData(seriesData);

  const colors = {
    darkRed: theme.chart.anomalyDarkerShadedArea,
    lightRed: theme.chart.anomalyLightlyShadedArea,
    transparent: theme.chart.areaFillColor,
  };

  const { displayHighLine, displayHighHighLine, displayLowLine, displayLowLowLine } =
    displayLimitData;
  //eslint-disable-next-line
  const getEffectiveRange = (...arrays: any) => {
    for (const array of arrays) {
      if (Array.isArray(array) && array.length > 0) {
        return array;
      }
    }
    return [];
  };

  const highRanges = () => {
    if (limits.highHighs.length) {
      return limits.highHighs;
    } else if (limits.highs.length) {
      return limits.highs;
    }
    return [];
  };

  const lowRanges = () => {
    if (limits.lowLows.length) {
      return limits.lowLows;
    } else if (limits.lows.length) {
      return limits.lows;
    }
    return [];
  };

  modes = getEffectiveRange(highRanges(), lowRanges(), modes);
  modes.map((limit: Mode, idx: number) => {
    const dataWithinTimeRange = data.filter(
      (d) => d.time >= (limit as Mode).start && d.time < (limit as Mode).end,
    );

    const highHighLimitValue = displayHighHighLine ? limits.highHighs[idx]?.value : undefined;
    const highLimitValue = displayHighLine ? limits.highs[idx]?.value : undefined;
    const lowLowLimitValue = displayLowLowLine ? limits.lowLows[idx]?.value : undefined;
    const lowLimitValue = displayLowLine ? limits.lows[idx]?.value : undefined;
    const yMin = yExtended[0];
    const yMax = yExtended[1];

    const colorRanges = generateLimitRangeColor(
      yMin,
      lowLowLimitValue,
      lowLimitValue,
      highLimitValue,
      highHighLimitValue,
      yMax,
      colors,
    );

    if (colorRanges.length === 1) return null;

    const area = d3
      .area<TimeSeries>()
      .x((d) => xScale(d.time))
      .y0((d) => yScale(d.min ?? 0))
      .y1((d) => yScale(d.max ?? 0)) as Area;

    const defs = chart.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', `area-gradient-${uuid}`)
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', chartHeight)
      .attr('x2', 0)
      .attr('y2', 0);

    colorRanges.forEach((range) => {
      const { start, end, color } = range;
      const startOffset = (start - yMin) / (yMax - yMin);
      const endOffset = ((end || 0) - yMin) / (yMax - yMin);

      gradient.append('stop').attr('offset', startOffset.toString()).attr('stop-color', color);
      gradient.append('stop').attr('offset', endOffset.toString()).attr('stop-color', color);
    });

    chart
      .append('path')
      .datum(dataWithinTimeRange)
      .attr('fill', `url(#area-gradient-${uuid})`)
      .attr('d', area);
  });
};
