import React, { useContext } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';

import { EventCardContainer } from './style';

import { Checkbox, Tooltip } from '@controlrooms/components';
import { Tag, TimeSearchResult } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const EventCard = ({
  event,
  selectedEvents,
  tagName,
  folder,
  tag,
  handleEventSelect,
}: {
  event: TimeSearchResult;
  selectedEvents: TimeSearchResult[];
  tagName: string;
  folder: number;
  tag: Tag;
  handleEventSelect: (event: TimeSearchResult) => void;
}) => {
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const { track } = useAnalytics();
  const { event: selectedEvent, setEvent } = useContext(TimeSearchContext);
  const dateTimeRange = TimeUtils.trendSearchDateTimeRange(
    event.start_time,
    event.end_time,
    timezone,
  );
  const isEventSelected = selectedEvents?.some(
    (selectedEvent) =>
      event.start_time === selectedEvent.start_time && event.end_time === selectedEvent.end_time,
  );
  const isCurrentEvent =
    event.start_time === selectedEvent.startTime && event.end_time === selectedEvent.endTime;

  const handleEventClick = () => {
    track('Trend Search - Event Click', {
      startTime: event.start_time,
      endTime: event.end_time,
      folder,
      tagName,
    });
    setEvent({
      id: 0,
      startTime: event.start_time,
      endTime: event.end_time,
      folder,
      tagName,
      tag,
    });
  };
  return (
    <EventCardContainer
      isSelected={isEventSelected}
      isCurrentEvent={isCurrentEvent}
      key={dateTimeRange}
      onClick={handleEventClick}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          className="event-selection"
          checked={isEventSelected}
          onChange={() => {
            handleEventSelect(event);
          }}
        />
      </span>
      <div className="info-section">
        <div className="date-time">
          <Tooltip label={dateTimeRange}>
            <span>{dateTimeRange}</span>
          </Tooltip>
        </div>
        <div className="data-range">
          <Tooltip label={String(event.resample_value_min)} className="min">
            <span>{Number(event.resample_value_min)?.toFixed(2)}</span>
          </Tooltip>
          <span>-</span>
          <Tooltip label={String(event.resample_value_max)} className="max">
            <span>{Number(event.resample_value_max)?.toFixed(2)}</span>
          </Tooltip>
        </div>
      </div>
    </EventCardContainer>
  );
};

export default EventCard;
