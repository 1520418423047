import React, { useContext } from 'react';

import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { ViewContext } from '../../context/view-context';

import { Button } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const TimeSearchAction: React.FC = () => {
  //Event tracking to be added
  const { searchMode, setSearchMode } = useContext(ViewContext);
  const { hasRole } = useContext(AuthorizationContext);

  const showTrendSearch = hasRole(Role.TAG_SEARCH);

  if (searchMode || !showTrendSearch) {
    return <></>;
  }
  return (
    <Button
      buttonSize="small"
      buttonType="secondary"
      iconName={ICONS.Sparkline}
      data-testid="search-by-shape"
      style={{ marginRight: '5px', width: 'auto', height: 'auto', padding: '3px 5px' }}
      onClick={() => {
        setSearchMode(true);
      }}
    >
      Trend Search
    </Button>
  );
};
