import styled, { DefaultTheme, css } from 'styled-components';

import { customScrollPrimary, customScrollSecondary } from '../../../app/global-styles';

import { FolderHeaderProps } from './types';

import { colors, text } from '@controlrooms/design-tokens';

interface IChartsContainerProps {
  isReport?: boolean;
  theme?: DefaultTheme;
}

export const StyledHeader = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ChartsContainerWrapper = styled.div`
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: column;
`;

export const ChartsContainer = styled.div<IChartsContainerProps>`
  ${({ isReport, theme }) =>
    !isReport &&
    `
    flex: 1;
    height: calc(100% - 95px);
    overflow: hidden;
    background: ${theme.chart.backgroundColor};
  `}
  .custom-scroll {
    ${customScrollPrimary}
    overflow-y: scroll !important;
    overflow-x: auto;
    > div > div {
      margin-top: 15px !important;
    }

    &--folder {
      ${customScrollSecondary}
    }
  }

  .custom-scroll .custom-scroll > div > div > div:first-child {
    padding-top: 0.5rem;
  }
`;

export const StyledFolder = styled.div<Pick<FolderHeaderProps, 'pinned'>>`
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  line-height: 19px;
  color: ${({ theme }) => theme.systemHeader.titleColor};
  background: ${({ theme }) => theme.systemHeader.background};

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  svg {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .pin-icon {
    margin-right: 6px;
    svg {
      fill: ${({ theme }) => theme.systemHeader.pinIconFill};
    }
  }

  .close-icon {
    min-width: 22px;
    svg {
      fill: ${({ theme }) => theme.systemHeader.closeIconFill};
      vertical-align: baseline;
      margin-top: -1px;
    }
  }

  ${(props) =>
    props.pinned &&
    css`
      background: ${({ theme }) => theme.systemHeader.backgroundPinned};
    `}

  > .top {
    padding: 8px 24px 6px 8px;
    margin-right: 19px;
    display: flex;

    .title {
      flex-grow: 1;
      font-weight: 300;
      font-size: 19px;
      font-family: 'Space Grotesk', 'Open Sans', sans-serif;
      line-height: 23px;
      margin-left: 30px;
      .description {
        font-size: 13px;
        font-family: 'Open Sans', sans-serif;
      }
    }

    .actions-wrapepr {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      > .upper,
      > .lower {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      > .upper {
        button {
          padding-right: 0;
        }

        svg {
          width: auto;
          padding-right: 0;
        }

        .close-icon {
          justify-content: flex-end;
        }
      }

      > .lower {
        min-height: 19px;

        button.hidden-tags {
          font-size: 11px;
          font-family: 'Open Sans', sans-serif;
          color: ${({ theme }) => theme.systemHeader.hiddenTagsCountColor};
          padding-right: 0;
          display: flex;
          position: relative;
        }

        .closed-eye-icon {
          margin-left: 2px;
          stroke: ${({ theme }) => theme.systemHeader.closedEyeFill};
        }
        .menu-select > ul {
          right: 5px;
        }
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.systemHeader.titleColorHover};
    .top {
      background: ${({ theme }) => theme.systemHeader.topBackgroundHover};

      ${(props) =>
        props.pinned &&
        css`
          background: ${({ theme }) => theme.systemHeader.topBackgroundHoverPinned};
        `}
    }

    .close-icon svg {
      fill: ${({ theme }) => theme.systemHeader.closeIconFillHover};
    }
  }

  .no-tags {
    font-size: ${text.size['11-label']};
    color: ${colors.content['neutral-4']};
    font-style: italic;
    font-family: 'Open Sans', sans-serif;
  }

  .menu-select {
    margin: 0;
    padding: 0;
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column nowrap;
  height: calc(100vh - 71px);
  background-color: colors.k[5];
`;
