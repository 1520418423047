import dayjs from 'dayjs';
import React, { useContext } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';

import { EventTabContainer, EventTabWrapper } from './style';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { TimeSearchResult, TrendSearchData, ViewType } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const EventTab = () => {
  const { track } = useAnalytics();
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const { tag, folder } = viewState.view[ViewType.ANALYZE].trendSearchData as TrendSearchData;
  const {
    event,
    selectedEvents: tabList,
    setSelectedEvents,
    selectedTab,
    setSelectedTab,
    timeSearchTimeSelection,
    setTimeSearchTimeSelection,
  } = useContext(TimeSearchContext);

  const dateTimeRange = TimeUtils.trendSearchDateTimeRange(
    event.startTime,
    event.endTime,
    timezone,
  );

  const defaultTab = {
    start_time: event.startTime || '',
    end_time: event.endTime || '',
    dtw_distance: 0,
    good_search_result: false,
    window_data: [0],
    event_type: 'default',
  };

  const findIsSelected = (tab: TimeSearchResult) => {
    return tab?.start_time === selectedTab?.start_time && tab?.end_time && selectedTab?.end_time;
  };

  const handleCloseTab = (idx: number) => {
    let currentTab;
    if (tabList.length > 1) {
      currentTab = tabList[idx - 1 >= 0 ? idx - 1 : idx + 1];
    } else {
      currentTab = defaultTab;
    }
    setTimeSearchTimeSelection({
      ...timeSearchTimeSelection,
      startTime: dayjs.utc(currentTab?.start_time).tz(timezone),
      endTime: dayjs.utc(currentTab?.end_time).tz(timezone),
    });
    setSelectedTab(currentTab);
    tabList.splice(idx, 1);
    setSelectedEvents([...tabList]);
  };

  const handleTabClick = (tab?: TimeSearchResult) => {
    track('Trend Search - Event Tab Click', {
      tab: tab ? { ...tab } : { ...defaultTab },
      tag,
      folder,
    });
    if (tab) {
      setSelectedTab({ ...tab });
      setTimeSearchTimeSelection({
        ...timeSearchTimeSelection,
        startTime: dayjs.utc(tab.start_time).tz(timezone),
        endTime: dayjs.utc(tab.end_time).tz(timezone),
      });
    } else {
      setSelectedTab({ ...defaultTab });
    }
  };

  return (
    <EventTabWrapper>
      <EventTabContainer
        key="default-tab"
        onClick={() => handleTabClick()}
        isSelected={selectedTab?.event_type === 'default'}
      >
        <span className="info">{`${tag?.description || tag?.name || ''} - ${dateTimeRange}`}</span>
      </EventTabContainer>
      {tabList?.map((tab, idx) => (
        <EventTabContainer
          key={idx}
          onClick={() => handleTabClick(tab)}
          isSelected={!!findIsSelected(tab) && selectedTab?.event_type !== 'default'}
        >
          <span className="info">
            {TimeUtils.trendSearchDateTimeRange(tab.start_time, tab.end_time, timezone)}
          </span>
          {findIsSelected(tab) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon
                name={ICONS.Close}
                onClick={() => {
                  handleCloseTab(idx);
                }}
              />
            </span>
          )}
        </EventTabContainer>
      ))}
    </EventTabWrapper>
  );
};

export default React.memo(EventTab);
