import { FC, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CSSProperties } from 'styled-components';

import { Paths } from '../../../app/constants/paths';
import { AnalyzeContext } from '../../context/analyze-context';
import { Header } from '../monitor/styles';

import { Li, OptionsGroup, Ul } from '@controlrooms/components';

export interface SelectOption {
  value: string;
  label: string;
  dataTestId: string;
}

export enum TimeFrameLabel {
  PREVIOUS_WEEK = 'Previous week',
  PREVIOUS_MONTH = 'Previous month',
  PREVIOUS_6_MONTH = 'Previous 6 months',
  PREVIOUS_YEAR = 'Previous year',
  ALL_TIME = 'All time',
  CUSTOM_TIMEFRAME = 'Custom timeframe',
}

export enum TimeFrameValue {
  PREVIOUS_WEEK = 'last-week',
  PREVIOUS_MONTH = 'last-month',
  PREVIOUS_6_MONTH = 'six-months',
  PREVIOUS_YEAR = 'last-year',
  ALL_TIME = 'all-time',
  CUSTOM_TIMEFRAME = 'custom-timeframe',
}

export const timeFrameOptions: SelectOption[] = [
  {
    value: TimeFrameValue.PREVIOUS_WEEK,
    label: TimeFrameLabel.PREVIOUS_WEEK,
    dataTestId: 'time-search-previous-week',
  },
  {
    value: TimeFrameValue.PREVIOUS_MONTH,
    label: TimeFrameLabel.PREVIOUS_MONTH,
    dataTestId: 'time-search-previous-month',
  },
  {
    value: TimeFrameValue.PREVIOUS_6_MONTH,
    label: TimeFrameLabel.PREVIOUS_6_MONTH,
    dataTestId: 'time-search-previous-quarter',
  },
  {
    value: TimeFrameValue.PREVIOUS_YEAR,
    label: TimeFrameLabel.PREVIOUS_YEAR,
    dataTestId: 'time-search-previous-year',
  },
  {
    value: TimeFrameValue.ALL_TIME,
    label: TimeFrameLabel.ALL_TIME,
    dataTestId: 'time-search-all-time',
  },
  {
    value: TimeFrameValue.CUSTOM_TIMEFRAME,
    label: TimeFrameLabel.CUSTOM_TIMEFRAME,
    dataTestId: 'time-search-custom-timeframe',
  },
];

export const SearchTimeRangeDropdown: FC<{
  isDropdownOpen: boolean;
}> = ({ isDropdownOpen }) => {
  const navigate = useNavigate();
  const { searchTimeRangeData } = useContext(AnalyzeContext);
  const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (isDropdownOpen) {
      setDropdownPosition({ x: searchTimeRangeData?.x || 0, y: searchTimeRangeData?.y || 0 });
    }
  }, [isDropdownOpen, searchTimeRangeData]);

  const dropdownPlacement: Partial<CSSProperties> = {
    position: 'absolute',
    top: `${dropdownPosition.y - 350}px`,
    left: `${dropdownPosition.x}px`,
  };

  const handleTimeRangeClick = (timeRange: string) => {
    navigate(generatePath(Paths.TIME_SEARCH, { tenantId: '46' }), {
      state: { ...searchTimeRangeData, timeRange },
    });
  };

  return (
    <OptionsGroup className="timeline-dropdown">
      <div style={dropdownPlacement}>
        <Ul isOpen={isDropdownOpen}>
          <div>
            <Header>WITHIN TIME FRAME</Header>
            {timeFrameOptions.map((option) => (
              <Li
                onClick={() => {
                  handleTimeRangeClick(option.value);
                }}
              >
                {option.label}
              </Li>
            ))}
          </div>
        </Ul>
      </div>
    </OptionsGroup>
  );
};
