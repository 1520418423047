import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { analyzeConfig } from '../../../app/constants/page-configs';
import { useTagsByName } from '../../../app/hooks/folders';
import { differenceInMinutes } from '../../../app/utils/time';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';
import { getTickFormat } from '../timeline/tick-utils';

import { appendGradients } from './gradients';
import { StyledEventChart } from './styles';
import { InfoTooltip } from './utils/tooltip-components';
import {
  addAnalyzeTooltips,
  handleEventTooltip,
  handleTooltipMouseOut,
  resetLimitLines,
} from './utils/tooltips';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import {
  EventChartProps,
  SVGDefsSelection,
  ModeType,
  Mode,
  TimeSeries,
  DisplayLimitData,
} from '@controlrooms/models';
import {
  yAccessorExtentMaxWithLimit,
  yAccessorExtentMinWithLimit,
  yScaleDomain,
  buildMinMaxArea,
  buildMode,
  buildLimitIndicator,
  buildSpline,
  buildAnomalyLines,
  buildRangeAnomalyLimitLines,
  buildLimitArea,
  TimeUtils,
  buildStepLine,
  buildStringLine,
} from '@controlrooms/utils';

dayjs.extend(utc);

export const EventChart: React.FC<EventChartProps> = ({
  startTime,
  endTime,
  seriesData,
  modes,
  limits,
  limitsArray,
  folder,
  tag,
  tagObj,
  anomalyData,
  isLoading,
  isError,
}) => {
  const { data: tagsByName } = useTagsByName();

  const svgRef = useRef(null);
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;

  const { timeSearchTimeSelection } = useContext(TimeSearchContext);

  const theme = useTheme();

  const displayFreqLine = true;
  const displayHighLine = true;
  const displayHighHighLine = true;
  const displayLowLine = true;
  const displayLowLowLine = true;

  const timeRangeInMinutes = differenceInMinutes(startTime, endTime);
  const duration = endTime.diff(startTime, 's');

  const isLinearChart = tagObj?.is_continuous && tagObj?.is_numeric;
  const isDiscreteChart = !tagObj?.is_continuous && tagObj?.is_numeric;
  const isStringValuesChart = !tagObj?.is_continuous && !tagObj?.is_numeric;

  // Need to do work on mobile responsive
  // window.onresize = () => {
  //   ChartWrapper = d3.select(`.event-chart-wrapper`);
  //   containerWidth = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().width;
  //   containerHeight = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().height;
  // };

  useEffect(() => {
    const ChartWrapper = d3.select(`.event-chart-wrapper`);
    const containerWidth = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().width;
    const containerHeight = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().height;

    if (!containerWidth && !containerHeight) {
      return;
    }
    // get the width of the svg parent div for responsive

    const defaultEndTime = timeSearchTimeSelection.endTime;

    const { chartMargin, stepLineMarginTop } = analyzeConfig;

    let limitArrayData: Mode[] = [];
    const displayLimitData = {} as DisplayLimitData;
    if (displayHighLine) {
      limitArrayData = [...limitArrayData, ...limits.highs];
      displayLimitData.displayHighLine = displayHighLine;
    }
    if (displayHighHighLine) {
      limitArrayData = [...limitArrayData, ...limits.highHighs];
      displayLimitData.displayHighHighLine = displayHighHighLine;
    }
    if (displayLowLine) {
      limitArrayData = [...limitArrayData, ...limits.lows];
      displayLimitData.displayLowLine = displayLowLine;
    }
    if (displayLowLowLine) {
      limitArrayData = [...limitArrayData, ...limits.lowLows];
      displayLimitData.displayLowLowLine = displayLowLowLine;
    }

    // define the width of the chart as it is smaller and slightly offset
    const chartWidth = containerWidth - chartMargin.left - chartMargin.right - 50;

    const dynamicChartHeight = containerHeight - chartMargin.top - chartMargin.bottom - 30 - 60;

    // define height of chart as it is set in the context and changes
    const chartHeight = dynamicChartHeight - chartMargin.top - chartMargin.bottom;

    const svgEl = d3
      .select(svgRef.current)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 600 400')
      // Class to make it responsive.
      .classed('svg-content-responsive', true)
      .attr('viewBox', `0 0 ${containerWidth || 600} ${containerHeight || 400}`);

    svgEl.selectAll('*').remove(); // Clear svg content before adding new elements

    // // Add gradients
    const svgDefs = svgEl.append('defs') as unknown as SVGDefsSelection;
    appendGradients(svgDefs, theme);

    const chart = svgEl
      .append('g')
      .classed('chart', true)
      .attr('width', chartWidth)
      .attr('height', dynamicChartHeight - 10)
      .attr(
        'transform',
        `translate(${chartMargin.left + 20}, ${chartMargin.top + 10})`,
      ) as unknown as SVGDefsSelection;

    chart
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', dynamicChartHeight - 10)
      .attr('transform', `translate(0, 0)`)
      .classed('chart-area', true);

    // Append the loading state inside the chart so the height remains the same
    if (isLoading || isError || !seriesData.length) {
      const selection = chart
        .append('g')
        .append('text')
        .attr('x', 30)
        .attr('y', 30)
        .style('fill', theme.chart.loadingTextColor)
        .style('font-size', '12px');

      if (isLoading || isError) {
        selection.text(isLoading ? 'Loading chart...' : 'Uh oh...').raise();
      } else {
        selection.raise();
      }

      return;
    }

    // remove all tooltips
    ChartWrapper.selectAll('.tooltip-content').remove();

    const uom = tagsByName[tag]?.uom;

    const tooltipData = seriesData.map((data: TimeSeries) => ({
      ...data,
      frequent: modes?.[0]?.value,
      uom,
    }));

    // add a tooltip for each chart in the data
    const tooltips = ChartWrapper.append('div')
      .data([{ tooltipData, limitArrayData: limitArrayData }])
      .attr('class', 'tooltip-content')
      .attr('id', `tooltip-${folder}-${tag}`);

    // add spans for value and min max
    tooltips.append('span').classed('value', true);
    tooltips.append('span').classed('minMax', true);

    const xScale = d3
      .scaleUtc()
      .domain([startTime.valueOf(), endTime.valueOf()])
      .range([0, chartWidth]);

    const yScale = d3
      .scaleLinear()
      .domain(yScaleDomain(tooltipData, limitArrayData, displayFreqLine))
      .range([chartHeight, isDiscreteChart ? stepLineMarginTop : 0]);

    const minValue = yAccessorExtentMinWithLimit(tooltipData, limitArrayData, displayFreqLine);
    const maxValue = yAccessorExtentMaxWithLimit(tooltipData, limitArrayData, displayFreqLine);
    const diff = maxValue - minValue;
    const middleTick = diff / 2 + minValue;

    const yAxisNoTicks = d3.axisLeft(yScale).tickValues([]).tickSize(0);

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues([minValue, middleTick, maxValue])
      .tickFormat((d) => {
        if (
          (maxValue === minValue && minValue === middleTick) ||
          ((d as number) > -1 && (d as number) < 1 && d !== 0)
        )
          return parseFloat(Number(d).toPrecision(6)).toFixed(5);

        return parseFloat(Number(d).toPrecision(6)).toFixed(2);
      })
      .tickSize(0);

    // add x axis
    const xAxis = d3.axisTop(xScale).tickFormat((d) => {
      return TimeUtils.toTimezone(dayjs(d.toString()), timezone).format(getTickFormat(duration));
    });

    const xAxisTranslate = chartHeight;

    chart
      .append('g')
      .classed('xaxis', true)
      .attr('transform', 'translate(0, ' + xAxisTranslate + ')')
      .call(xAxis)
      .call((g) => g.select('.domain').attr('stroke', '#ddd')); // remove line from axis

    // Returns path data for a rectangle with rounded right corners.
    // The top-left corner is ⟨x,y⟩.
    function rightRoundedRect(x: number, y: number, width: number, height: number, radius: number) {
      return (
        'M' +
        x +
        ',' +
        y +
        'h' +
        (width - radius) +
        'a' +
        radius +
        ',' +
        radius +
        ' 0 0 1 ' +
        radius +
        ',' +
        radius +
        'v' +
        (height - 2 * radius) +
        'a' +
        radius +
        ',' +
        radius +
        ' 0 0 1 ' +
        -radius +
        ',' +
        radius +
        'h' +
        (radius - width) +
        'z'
      );
    }

    // add y axis
    chart
      .append('g')
      .call(isDiscreteChart || isStringValuesChart ? yAxisNoTicks : yAxis)
      .call((g) => g.select('.domain').attr('stroke', '#ddd')) // remove line from axis
      .classed('yaxis', true)
      .append('path')
      .attr('width', chartWidth)
      .attr('height', isStringValuesChart ? 30 : chartHeight)
      .attr('class', 'chart-background')
      .attr('d', function () {
        return rightRoundedRect(1, 0, chartWidth, isStringValuesChart ? 30 : chartHeight, 6);
      });

    // add clipping so everything out of this area won't be drawn
    svgDefs
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('x', 0)
      .attr('y', 0);

    // add brushing
    const brush = d3
      .brushX() // Add the brush feature using the d3.brush function
      .extent([
        [0, 0],
        [chartWidth, chartHeight],
      ])
      .on('end', (e: { selection: number[] }) => {
        const extent = e?.selection;
        if (!extent || extent.length < 2) return;
      });

    const clippedArea = chart
      .append('g')
      .attr('clip-path', 'url(#clip)') as unknown as SVGDefsSelection;

    // define and build min max area

    buildMinMaxArea(clippedArea, xScale, yScale, tooltipData, theme, isDiscreteChart);

    //define and build red shaded limit area
    buildLimitArea(
      clippedArea,
      xScale,
      yScale,
      tooltipData,
      theme,
      limits,
      chartHeight,
      yScaleDomain(tooltipData, limitArrayData, displayFreqLine),
      displayLimitData,
      modes,
    );

    // define and build mode
    if (displayFreqLine) buildMode(clippedArea, xScale, yScale, modes, theme, ModeType.FREQUENT);

    // define and build high limit line
    if (displayHighLine) {
      buildLimitIndicator(chart, xScale, yScale, limits.highs, theme, ModeType.HLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.highs, theme, ModeType.HLIMIT);
    }

    // define and build high high limit line
    if (displayHighHighLine) {
      buildLimitIndicator(chart, xScale, yScale, limits.highHighs, theme, ModeType.HHLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.highHighs, theme, ModeType.HHLIMIT);
    }

    // define and build low limit line
    if (displayLowLine) {
      buildLimitIndicator(chart, xScale, yScale, limits.lows, theme, ModeType.LLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.lows, theme, ModeType.LLIMIT);
    }

    // define and build low low limit line
    if (displayLowLowLine) {
      buildLimitIndicator(chart, xScale, yScale, limits.lowLows, theme, ModeType.LLLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.lowLows, theme, ModeType.LLLIMIT);
    }

    // define and build line based on chart type
    if (isLinearChart) buildSpline(clippedArea, xScale, yScale, tooltipData, theme);

    if (isDiscreteChart)
      buildStepLine(clippedArea, xScale, yScale, tooltipData, theme, defaultEndTime);

    if (isStringValuesChart)
      buildStringLine(clippedArea, xScale, yScale, seriesData, theme, defaultEndTime, chartHeight);

    // check for normal chart or discrete
    if (isLinearChart || isDiscreteChart) {
      // add anomaly lines
      buildAnomalyLines(
        clippedArea,
        xScale,
        yScale,
        anomalyData,
        theme,
        false,
        containerWidth / timeRangeInMinutes,
      );
      // add range limits
      buildRangeAnomalyLimitLines(
        clippedArea,
        xScale,
        yScale,
        tooltipData,
        limits,
        displayLimitData,
        chartHeight,
        theme,
      );
    }

    // add tooltip for chart
    addAnalyzeTooltips(
      clippedArea,
      analyzeConfig,
      theme,
      tooltipData,
      chartHeight,
      folder,
      tag,
      displayFreqLine,
      limitArrayData,
    );

    // Add the brushing
    const brushContext = clippedArea.append('g').attr('class', 'brush').call(brush);

    // Re-use the brush created rect for tooltip events
    brushContext
      .select('.overlay')
      .on(
        'mouseout',
        () => {
          handleTooltipMouseOut('analyze');
          resetLimitLines(tag.replaceAll(/[^a-zA-Z0-9]/g, '-'), theme);
        },
        // on mouse out hide line, circles and text
      )
      .on('touchmouse mousemove', (e: MouseEvent) => {
        handleEventTooltip(
          e,
          xScale,
          theme,
          displayFreqLine,
          chart,
          clippedArea,
          isStringValuesChart,
        );
      })
      .classed('analyze-chart-rect', true)
      .raise();
  }, [
    modes,
    limits,
    limitsArray,
    seriesData,
    tag,
    isLoading,
    isError,
    theme,
    tagsByName,
    anomalyData,
    timezone,
    folder,
    displayFreqLine,
    displayHighLine,
    displayHighHighLine,
    displayLowLine,
    displayLowLowLine,
    timeRangeInMinutes,
    isDiscreteChart,
    isLinearChart,
    isStringValuesChart,
    startTime,
    endTime,
    duration,
    timeSearchTimeSelection.endTime,
  ]); // Redraw chart if data or height changes

  return (
    <StyledEventChart
      data-testid={`event-chart-${tag}`}
      className={'event-chart-wrapper'}
      data-tag={tag}
    >
      {(isDiscreteChart || isStringValuesChart) && (
        <div style={{ position: 'absolute', top: 200, left: 30 }}>
          <Tooltip
            type="secondary"
            label={<InfoTooltip chartData={seriesData} chartUom={tagObj?.uom} />}
          >
            <Icon width={'12'} name={ICONS.Info} />
          </Tooltip>
        </div>
      )}
      <svg ref={svgRef} />
    </StyledEventChart>
  );
};
