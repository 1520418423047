import styled from 'styled-components';

import { breakpoints } from '../../../app/global-styles';

import { StyledIcon } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  ${breakpoints.low} {
    gap: 5px;
  }
`;

export const YScaleActions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.chart.actionBorderColor};
  border-radius: 10px;
  width: 27px;
  align-items: center;
  span {
    font-size: 9px;
    font-family: 'Open Sans', sans-serif;
    color: ${colors.content['neutral-3']};
    padding-top: 3px;
    background: ${({ theme }) => theme.chart.headerBackgroundColor};
    height: 14px;
    width: 34px;
  }
  button {
    height: 12px;
    width: 18px;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: center;
    background: transparent;
    cursor: pointer;
    border: 0;
    &:first-child {
      align-items: end;
    }
    &:last-child {
      align-items: start;
      padding-top: 3px;
    }
    > div {
      flex: 1;
    }
    svg > path {
      fill: ${colors.content['neutral-3']};
    }

    &:hover svg > path {
      fill: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};
    }

    ${StyledIcon} {
      display: flex;
      justify-content: center;
    }
  }
`;

export const ViewActionsContainer = styled.div`
  // Temp. This is an empty placeholder for the view actions that will be later implemented.
  // Per Dave - Sort should not shift left while ViewMode is missing.
  //min-width: 130px;
  ${breakpoints.desk} {
    div {
      padding-left: 0px;
    }
  }
`;

export const SortActionsContainer = styled.div`
  min-width: auto;
  ${breakpoints.desk} {
    min-width: 130px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 13px;
    margin-bottom: 4px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
