import { useAuth0 } from '@auth0/auth0-react';
import * as d3 from 'd3';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Role } from '../../../app/constants/auth';
import { Paths } from '../../../app/constants/paths';
import { useRoles } from '../../../app/context/authorization-context';
import { useHeatmap } from '../../../app/hooks/anomalies';
import { FolderSort } from '../../../app/hooks/folders';
import { AnalyzeContext } from '../../context/analyze-context';
import { useLayoutContext } from '../../context/layout-context';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';
import { useTenants } from '../../hooks/tenants';
import { HeatmapMiniProps } from '../../pages/analyze/types';

import { StyledMiniChart } from './styles';

import { Anomaly, LabeledEvent, SVGDefsSelection, TimeSelection } from '@controlrooms/models';
import {
  appendFilters,
  appendGradients,
  buildCells,
  buildErrors,
  buildGroups,
  buildLabeledEventGroups,
  buildNoData,
} from '@controlrooms/utils';

export const HeatmapMini: React.FC<HeatmapMiniProps> = ({ folder }) => {
  const { pathname } = useLocation();
  const { activeModes, activeView } = useLayoutContext();
  const selectedMode = activeModes[activeView];
  const { timeSearchTimeSelection } = useContext(TimeSearchContext);
  const { viewState } = useViewContext();
  const { timeSelection: appTimeSelection, view } = viewState;
  const { showLimits } = view[selectedMode];
  const analyzeTimeSelection: TimeSelection = matchPath(Paths.TIME_SEARCH, pathname)
    ? timeSearchTimeSelection
    : appTimeSelection;
  const { selectedFolders, pinnedTags, showAnalyzeLimits } = useContext(AnalyzeContext);

  const {
    data: heatmapData,
    interval,
    labeledEvents,
  } = useHeatmap(analyzeTimeSelection, FolderSort.DEFAULT, true, selectedFolders, pinnedTags);
  const { currentTenant } = useTenants();
  const tenantConfig = currentTenant?.preferences;

  const theme = useTheme();

  const { userRoles } = useRoles();
  const { user } = useAuth0();

  const chartData = heatmapData?.pages[0]?.result?.[folder]?.anomalies;

  const { groupTooltipData, setGroupTooltipData, setLabelTooltipData } = useContext(AnalyzeContext);

  const limitsExceeded = heatmapData?.pages[0]?.result?.[folder]?.limits_exceeded;
  const anomalyData = useMemo(() => {
    return chartData?.filter((a: Anomaly) => a.value > 0) ?? ([] as Array<Anomaly>);
  }, [chartData]);

  const errorData = useMemo(() => {
    return chartData?.filter((a: Anomaly) => a.value < 0) ?? ([] as Array<Anomaly>);
  }, [chartData]);

  const svgRef = useRef(null);

  useEffect(() => {
    const ChartWrapper = d3.select(`.heatmap-folder-${folder}`);

    // get the width of the svg parent div for responsive
    const width = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().width;
    const rowHeight = 12;

    // Set viewbox on svg for responsive
    const svgEl = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', rowHeight)
      .attr('preserveAspectRatio', 'none')
      .attr('viewBox', `0 0 ${width} ${rowHeight}`)
      .attr('rx', 4);

    svgEl.selectAll('*').remove();

    if (!chartData && !limitsExceeded) {
      return;
    }

    const svgDefs = svgEl.append('defs') as unknown as SVGDefsSelection;

    // add Gradients to svg defs
    appendGradients(svgDefs, theme);

    // add filters to svg defs - drop shadows
    appendFilters(svgDefs);

    const xScale = d3
      .scaleUtc()
      .domain([analyzeTimeSelection.startTime.valueOf(), analyzeTimeSelection.endTime.valueOf()])
      .range([0, width]);

    const group = svgEl
      .append('g')
      .classed('heatmap-mini-container', true)
      .attr('data-testid', 'heatmap-mini-container')
      .attr('width', width)
      .attr('height', rowHeight) as unknown as SVGDefsSelection;

    buildCells(group, anomalyData, xScale, tenantConfig.monitorAnomalyThresholds, {
      theme,
      height: rowHeight - 4,
      interval,
      rowHeight,
      limitData: limitsExceeded,
      labeledEvents,
      showAnalyzeLimits: showLimits,
    });

    if (user?.email_verified && userRoles.includes(Role.GLOBAL_LABEL_EDITOR)) {
      buildGroups(group, anomalyData, xScale, folder, {
        height: rowHeight - 8,
        interval,
        rowHeight,
        groupTooltipData,
        setGroupTooltipData,
        theme,
      });
    }

    const getFolderEvents = () => {
      const thisFolderEvents: LabeledEvent[] = [];
      labeledEvents?.forEach((le) => {
        if (le.process_id === folder) {
          thisFolderEvents.push(le);
        }
      });
      return thisFolderEvents;
    };

    buildLabeledEventGroups(group, getFolderEvents(), xScale, folder, {
      theme,
      height: rowHeight,
      rowHeight,
      setGroupTooltipData,
      setLabelTooltipData,
    });

    const errorContainer = group.append('g').classed('error-container', true);

    buildNoData(errorContainer, errorData, folder, xScale, tenantConfig, {
      errorHeight: 8,
      interval,
      noDataHeight: 2,
      rowHeight,
    });

    buildErrors(errorContainer, errorData, folder, xScale, tenantConfig, {
      errorHeight: 10,
    });
  }, [
    folder,
    theme,
    analyzeTimeSelection.startTime,
    analyzeTimeSelection.endTime,
    interval,
    anomalyData,
    errorData,
    chartData,
    limitsExceeded,
    tenantConfig,
    showAnalyzeLimits,
    labeledEvents,
    groupTooltipData,
    setGroupTooltipData,
    setLabelTooltipData,
    user,
    userRoles,
    analyzeTimeSelection,
    showLimits,
  ]);

  return (
    <StyledMiniChart className={`heatmap-folder-${folder}`}>
      <svg ref={svgRef} />
    </StyledMiniChart>
  );
};
