import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ViewType } from '@controlrooms/models';

// Define the shape of the context
interface LayoutContextType {
  viewIds: string[]; // List of view IDs
  setViewIds: Dispatch<SetStateAction<string[]>>;
  activeModes: { [key: string]: ViewType }; // Object to store active mode for each viewId
  setActiveModes: Dispatch<SetStateAction<{ [key: string]: ViewType }>>;
  activeView: string; // Stores the active view ID
  setActiveView: Dispatch<SetStateAction<string>>;
}

// Create the context with a default value of undefined
const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

// Define separate session storage keys
const VIEW_IDS_KEY = 'layout-viewIds';
const ACTIVE_MODES_KEY = 'layout-activeModes';
const ACTIVE_VIEW_KEY = 'layout-activeView';

// Define the provider component
export const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Retrieve initial state from session storage or use defaults
  const storedViewIds = JSON.parse(sessionStorage.getItem(VIEW_IDS_KEY) || '["default"]');
  const storedActiveModes = JSON.parse(
    sessionStorage.getItem(ACTIVE_MODES_KEY) || `{"default":"${ViewType.MONITOR}"}`,
  );
  const storedActiveView = sessionStorage.getItem(ACTIVE_VIEW_KEY) || 'default';

  // State to hold the list of view IDs
  const [viewIds, setViewIds] = useState<string[]>(storedViewIds);
  // State to hold the active mode for each viewId
  const [activeModes, setActiveModes] = useState<{ [key: string]: ViewType }>(storedActiveModes);
  // State to hold the active viewId
  const [activeView, setActiveView] = useState<string>(storedActiveView);

  // Persist viewIds to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(VIEW_IDS_KEY, JSON.stringify(viewIds));
  }, [viewIds]);

  // Persist activeModes to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(ACTIVE_MODES_KEY, JSON.stringify(activeModes));
  }, [activeModes]);

  // Persist activeView to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(ACTIVE_VIEW_KEY, activeView);
  }, [activeView]);

  // Memoize the context value to avoid unnecessary re-renders
  const appState = useMemo(
    () => ({
      viewIds,
      setViewIds,
      activeModes,
      setActiveModes,
      activeView,
      setActiveView,
    }),
    [viewIds, activeModes, activeView],
  );

  return <LayoutContext.Provider value={appState}>{children}</LayoutContext.Provider>;
};

// Custom hook to use the LayoutContext
export const useLayoutContext = (): LayoutContextType => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};
