import { Composition } from 'atomic-layout';
import { useContext } from 'react';

import TimeSearchContextProvider from '../../context/time-search-context';
import { ViewContext } from '../../context/view-context';

import { EventSearchChart } from './event-search-chart';
import { SearchEventResults } from './search-event-results';
import { ResultSection, SidebarContent } from './style';

import { Button, Container } from '@controlrooms/components';
import { Tag, TrendSearchData, ViewType } from '@controlrooms/models';

const areasMobile = `
sidebar content
`;

export type SearchType = {
  startTime: string;
  endTime: string;
  tag: Tag;
};

export interface SearchParams extends SearchType {
  folder: number;
  timeRange?: string;
}

const TimeSearch: React.FC = () => {
  const { viewState } = useContext(ViewContext);

  const { startTime, endTime, tag, folder } = viewState.view[ViewType.ANALYZE]
    .trendSearchData as TrendSearchData;

  if (!startTime || !endTime || !tag.name || !folder) {
    return <>Bad search criteria</>;
  }

  return <TimeSearchComposition />;
};

const TimeSearchComposition = () => {
  const { clearTrendSearchData, setSearchMode, viewState } = useContext(ViewContext);

  const { startTime, endTime, tag, folder } = viewState.view[ViewType.ANALYZE]
    .trendSearchData as TrendSearchData;
  return (
    <Container isPage>
      <Composition
        areas={areasMobile}
        areasLg={areasMobile}
        templateCols="auto"
        templateColsLg="1fr 3fr;"
        templateRows="auto"
        flexGrow="1"
      >
        {(Areas) => (
          <TimeSearchContextProvider>
            <Areas.Sidebar style={{ width: '320px' }}>
              <SidebarContent>
                <div className="navHeader">
                  <Button
                    className="backBtn"
                    buttonSize="small"
                    buttonType="text"
                    onClick={() => {
                      setSearchMode(true);
                      clearTrendSearchData();
                      // setTimeout(() => navigate(-1), 100);
                    }}
                    iconName="jump-back"
                  >
                    Back To Analyze
                  </Button>
                  <h1 className="module-title">Trend Search</h1>
                </div>
                <ResultSection>
                  <h3>You searched on:</h3>
                  <SearchEventResults
                    startTime={startTime}
                    endTime={endTime}
                    tagName={tag.name}
                    folder={folder}
                    tag={tag}
                  />
                </ResultSection>
              </SidebarContent>
            </Areas.Sidebar>
            <Areas.Content style={{ overflow: 'hidden' }}>
              <EventSearchChart />
            </Areas.Content>
          </TimeSearchContextProvider>
        )}
      </Composition>
    </Container>
  );
};

export default TimeSearch;
