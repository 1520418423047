/*eslint-disable */
import html2canvas from 'html2canvas';
/*eslint-enable */
import React, { useEffect, useRef, useState } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { FolderSort, useFlatFolders, usePlantFolders } from '../../../app/hooks/folders';
import { useLayoutContext } from '../../context/layout-context';
import { ErrorText } from '../error-text/error-text';

import {
  CanvasContainer,
  CopyLinkIcon,
  ModalBodyContainer,
  ScreenshotContainer,
  SharableLink,
  SharableLinkContainer,
  SharableLinkHeader,
  ShareLabel,
  ShareText,
} from './styles';

import { Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { DateFormats, ViewState, ViewType } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface Props {
  link?: string;
  isError?: boolean;
  view: ViewState;
}

const CopyToClipboardButton = ({ link }: { link: string }) => {
  const [copied, setCopied] = useState(false);
  const { track } = useAnalytics();

  const onCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(link);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  useEffect(() => {
    if (copied) {
      track('Share Screen', {
        copyLink: 'clicked',
      });
    }
    // eslint-disable-next-line
  }, [copied]);

  return (
    <div data-testid="copy-export-link">
      {copied ? (
        <Tooltip label="Copied to clipboard!">
          <CopyLinkIcon name={ICONS.Copy} width="14" height="14" onClick={onCopy} />
        </Tooltip>
      ) : (
        <CopyLinkIcon name={ICONS.Copy} width="14" height="14" onClick={onCopy} />
      )}
    </div>
  );
};

const ScreenshotView = ({ viewState }: { viewState: ViewState }) => {
  const { viewId } = viewState;
  const { activeModes } = useLayoutContext();
  const view = viewState.view[activeModes[viewId]];
  const { data: plant } = usePlantFolders();
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT);
  const [loading, setLoading] = useState(false);

  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeModes[viewId] === ViewType.ANALYZE)
      generateScreenshot(`#analyze-chart-container-${viewId}`);

    generateScreenshot(`#folder-heatmap-system-${viewId}`);
  }, [activeModes, viewId]);

  const generateScreenshot = (screenshotId: string) => {
    console.log('generateScreenshot', screenshotId);
    const screenshotNode = document.querySelector(screenshotId) as HTMLElement;
    const canvasNode = canvasRef.current;
    if (screenshotNode && canvasNode) {
      try {
        setLoading(true);
        setTimeout(() => {
          html2canvas(screenshotNode, {}).then((canvas) => {
            canvasNode.innerHTML = ''; // clear previous content
            const imageDataUrl = canvas.toDataURL();
            const img = new Image();
            img.src = imageDataUrl;
            img.width = 450;
            img.height = 150;
            canvasNode.appendChild(img);
          });
        }, 1000);
      } catch (error) {
        console.error(`Failed to generate screenshot for "${screenshotId}":`, error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getSystemNames = () => {
    return plant?.subfolders.map((sub) => sub.name).join(', ');
  };

  const getSelectedSubsystems = () => {
    const getSelectedSubsystemsInfo = () => {
      return flatFolders
        ?.filter((item) => view?.selectedFolders.includes(item.folder))
        .map((subFolder) => ({ name: subFolder.name, tags: subFolder?.tags?.length }));
    };

    return getSelectedSubsystemsInfo()
      ?.map((subsystem) => `${subsystem.name} (${subsystem.tags})`)
      .join(', ');
  };

  return (
    <ScreenshotContainer>
      <CanvasContainer id="canvas-container" ref={canvasRef}></CanvasContainer>
      <div data-testid="info-area" className="info-area">
        {loading
          ? 'Loading...'
          : view && (
              <>
                <span data-testid="view-name" className="view-name">
                  {getSystemNames()}{' '}
                </span>
                <span data-testid="share-screen-time">
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.startTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.TIME)}{' '}
                  -{' '}
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.endTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.TIME)}
                  {', '}
                </span>
                <span data-testid="share-screen-date">
                  {TimeUtils.toTimezone(
                    viewState.timeSelection?.startTime,
                    viewState.timeSelection?.timezone,
                  ).format(DateFormats.DATE)}
                </span>
                {view?.selectedFolders && view.selectedFolders?.length > 0 && (
                  <div
                    data-testid="subsystem-display-selected-folders"
                    className="subsystem-display"
                  >
                    <span>{getSelectedSubsystems()}</span>
                  </div>
                )}
                {/* {view?.pinnedTags && view?.pinnedTags?.length > 0 && (
                  <div data-testid="subsystem-display-pinned-tags" className="subsystem-display">
                    Pinned tags: {view.pinnedTags?.length}
                  </div>
                )} */}
              </>
            )}
      </div>
    </ScreenshotContainer>
  );
};

export const ExportModal: React.FC<Props> = ({ link, isError, view }) => {
  // const { track } = useAnalytics();
  // const [isScriptLoaded, setScriptLoaded] = React.useState(false);

  // React.useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://teams.microsoft.com/share/launcher.js';
  //   script.async = true;
  //   script.defer = true;
  //   script.onload = () => setScriptLoaded(true);
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [link, view]);

  return (
    <ModalBodyContainer>
      <ShareText data-testid="share-text">
        Captures a snapshot of these systems and tags within this timeframe.
      </ShareText>
      <ShareLabel data-testid="share-label">Share Preview</ShareLabel>
      <ScreenshotView viewState={view} />
      {/* <ExportHeader>Share via:</ExportHeader>
      <ExportSocials>
        <div
          onClick={() => {
            if (isScriptLoaded) {
              track('Share Screen', {
                shareViaTeams: 'clicked',
              });
            } else {
              console.error('Teams launcher script not loaded yet.');
            }
          }}
          data-testid="ms-teams-share-popup"
          data-icon-px-size="64"
          className={`teams-share-button`}
          data-href={link}
        ></div>
      </ExportSocials> */}
      <SharableLinkHeader>or copy this link:</SharableLinkHeader>
      <SharableLinkContainer>
        {link ? <SharableLink>{link}</SharableLink> : 'Loading...'}
        {link && <CopyToClipboardButton link={link} />}
      </SharableLinkContainer>
      {isError && <ErrorText text="An error occurred while generating the report. Please retry." />}
    </ModalBodyContainer>
  );
};
