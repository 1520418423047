import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useRef, useState } from 'react';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserPreferences } from '../../../app/components/account/user-preferences-modal';
import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import UserPreferencesContextProvider from '../../../app/context/user-preferences-context';
import logo from '../../../assets/cr-logo.svg';
import { useLayoutContext } from '../../context/layout-context';

import alerts from './alerts.svg';
import assess from './assess.svg';
import investigate from './investigate.svg';
import monitor from './monitor.svg';
// import share from './share.svg';
import { Logo, Menu, MenuItem, Nav, Profile, StyledSidebar } from './style';

import { Li, Modal, ModalContents, ModalOpenButton } from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';
import { ViewType } from '@controlrooms/models';

const Ul = styled.ul<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 55px;
  flex-direction: column;
  justify-content: flex-start;
  width: 211px;
  padding: 0;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  list-style: none;
  border: 1px solid black;
  border-radius: 4px;
  ${(props) => {
    if (!props.isOpen) {
      return `
        display: none;
      `;
    }
  }}
`;

const StyledUserDropdown = styled(Ul)`
  li {
    margin: 0;
  }
  li > button {
    color: ${({ theme }) => theme.modal.panel.color};
    cursor: pointer;
    border: 0;
    background: transparent;
  }
`;

const NavMenuItem = styled(NavLink)`
  color: white;
  padding: 15px 0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-right: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 1, 1, 0.5); // Adjust hover color as needed
    border-right: 2px solid #c1eff9;
  }
  &.active {
    border-right: 2px solid #c1eff9;
  }
`;

const Sidebar = () => {
  const { activeModes, setActiveModes, activeView } = useLayoutContext();
  const { logout, user } = useAuth0();
  const { tenant } = useTenant();
  const navigate = useNavigate();
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const userUlRef = useRef(null);
  useClickOutside(userUlRef, () => setIsUserSelectOpen(false));

  const profileInitial = useMemo(() => {
    const name = user?.name || '';
    const initial =
      name
        .match(/(\b\S)?/g)
        ?.join('')
        ?.match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase() || 'U';
    return initial;
  }, [user?.name]);

  const handleViewChange = (view: ViewType) => {
    setActiveModes((prev) => {
      return {
        ...prev,
        [activeView]: view,
      };
    });
  };

  return (
    <StyledSidebar>
      <Logo>
        <img src={logo} />
      </Logo>
      <Nav>
        <Menu>
          <MenuItem
            className={activeModes[activeView] === ViewType.MONITOR ? 'active' : ''}
            onClick={() => handleViewChange(ViewType.MONITOR)}
          >
            <img src={monitor} />
          </MenuItem>
          <MenuItem
            className={activeModes[activeView] === ViewType.ANALYZE ? 'active' : ''}
            onClick={() => handleViewChange(ViewType.ANALYZE)}
          >
            <img src={assess} />
          </MenuItem>
          <MenuItem
            className={activeModes[activeView] === ViewType.INVESTIGATE ? 'active' : ''}
            onClick={() => handleViewChange(ViewType.INVESTIGATE)}
          >
            <img src={investigate} />
          </MenuItem>
          <NavMenuItem to={generatePath(Paths.MANAGE_ALERTS, { tenantId: tenant.toString() })}>
            <img src={alerts} />
          </NavMenuItem>
        </Menu>
        {/* <Menu>
          <MenuItem to={generatePath(Paths.MONITOR, { tenantId: tenant.toString() })}>
            <img src={share} />
          </MenuItem>
        </Menu> */}
      </Nav>
      <Profile
        buttonSize="small"
        buttonType="text"
        onClick={(evt) => {
          evt.stopPropagation();
          setIsUserSelectOpen((prevState) => !prevState);
        }}
      >
        {profileInitial}
      </Profile>
      <StyledUserDropdown isOpen={isUserSelectOpen} ref={userUlRef} className="user-dropdown">
        <Li>
          <button
            onClick={() => {
              navigate({
                pathname: generatePath(Paths.MANAGE_LIMITS, {
                  tenantId: tenant.toString(),
                }),
              });
            }}
          >
            Limit Manager
          </button>
        </Li>
        <Li data-testid="user-preferences" className="user-prefs-menu-item">
          <UserPreferencesContextProvider>
            <Modal>
              <ModalOpenButton>
                <button>User Preferences</button>
              </ModalOpenButton>
              <ModalContents title="User Preferences" closeButtonText="Close">
                <UserPreferences />
              </ModalContents>
            </Modal>
          </UserPreferencesContextProvider>
        </Li>
        <Li data-testid="log-out" id="logout" className="logout">
          <button
            onClick={() => {
              sessionStorage.clear();
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            Log Out
          </button>
        </Li>
      </StyledUserDropdown>
    </StyledSidebar>
  );
};

export default Sidebar;
