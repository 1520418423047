import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { TimeRangeUtil } from '../../../app/utils/time';
import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';
import { ExportModal } from '../export-modal';

import { Icon, Modal, ModalContents, TextInput } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { DateFormats, TimeSelection, ViewState, ViewType } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

const Tabs = styled.div`
  display: flex;
  padding: 10px 0px 4px 0px;
  border-bottom: 2px solid #a0a38d;
  gap: 4px;
  .container {
    display: flex;
    margin-bottom: -6px;

    &.hasMoreTabs {
      .label {
        display: none;
      }
    }
  }

  .add-new,
  .viewsets-option {
    width: 40px;
    padding: 0px;
  }

  .tabs {
    flex-grow: 1;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: flex-start;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
`;

const TabBaseLine = styled.div`
  width: 100%;
  height: 2px;
  background: #a0a38d;

  width: 100%;
  height: 2px;
  box-shadow: none;
`;

const Tab = styled.button<{ isActive?: boolean }>`
  background: transparent;
  border: none;
  color: #faffda;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px;
  padding-left: 2px;
  flex-direction: column;
  cursor: pointer;

  .subMenu-link {
    padding: 10px;
  }
  ${({ isActive }) =>
    isActive &&
    `
    ${TabBaseLine} {
      background-color: #93BBC3;
      box-shadow: 0px -3px 10px 2px #93BBC3;
    }
  `}
  ${({ isActive }) =>
    !isActive &&
    `
    .label {
      text-transform: capitalize;
      ::before {
        content: '';
        display: block;
        width: 100%;
        height: 20px;
        background: #a0a38d;
      }
    }
  `}
    &:hover {
    ${TabBaseLine} {
      background-color: #93bbc3;
      box-shadow: 0px -3px 10px 2px #93bbc3;
    }
  }

  &:focus {
    outline: none;
  }

  &.viewsets-option {
    padding: 5px;
  }
  .tab-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }
  .label {
    text-transform: capitalize;
    max-width: 150px;
    display: flex;
    flex: 1 0 0;
    overflow: hidden;
    color: #faffda;
    text-overflow: ellipsis;

    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    white-space: nowrap;
    align-items: center;

    ::before {
      content: '';
      display: block;
      width: 100%;
      height: 20px;
      background: #a0a38d;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  position: absolute;
  top: 40px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  border-radius: 4px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.6);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding: 5px 25px;
      align-items: center;
      align-self: stretch;

      &:hover {
        background-color: #232323;
      }

      &.separator {
        border-bottom: 1px solid #a0a38d;
      }

      &.disabled {
        cursor-event: not-allowed;
        opacity: 0.5;
      }

      &.option {
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        color: #faffda;
      }

      &.label {
        font-family: 'Open Sans';
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 9px; /* 100% */
        text-transform: uppercase;
      }
    }
  }
`;

export const TabContainer = () => {
  //TODO: Add logic to list all viewsets on the options
  // const { data: viewsets } = useViewSet();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { setViewIds, setActiveModes, setActiveView, viewIds } = useLayoutContext();
  const [showCollectionSubmenu, setShowCollectionSubmenu] = useState(false);
  const hasNewTabs = viewIds.length > 3;
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (scrollRef.current) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleAddNewView = () => {
    const uuid = uuidv4();
    setViewIds((prevItems) => [...prevItems, uuid]);
    setActiveModes((prev) => ({ ...prev, [uuid]: ViewType.MONITOR }));
    setActiveView(uuid);
  };

  const handleCloseAll = () => {
    setActiveView('default');
    setViewIds(['default']);
  };

  return (
    <Tabs>
      <div
        className={`container default ${hasNewTabs ? 'hasMoreTabs' : ''}`}
        id="default-tab-container"
      ></div>
      <div className="container tabs" id="tab-container" ref={scrollRef}></div>
      <div className="container menu">
        <Tab className="add-new" onClick={() => handleAddNewView()}>
          <div className="tab-content">
            <Icon name={ICONS.Plus} width="15" height="20" />
          </div>
        </Tab>
        <Tab className="viewsets-option" onClick={() => setShowCollectionSubmenu((prev) => !prev)}>
          <div className="tab-content">
            <Icon name={ICONS.Kebab} width="15" height="20" color="#A0A38D" />
          </div>
        </Tab>
        {showCollectionSubmenu && (
          <Menu style={{ right: '10px', zIndex: 2, color: '#faffda' }}>
            <ul>
              <li className={`option`}>Open views</li>
              <li className="separator"></li>
              <li className={`option`} onClick={handleCloseAll}>
                Close all views
              </li>
              <li className={`option`}>Save open views as new collection</li>
              <li className={`option`}>Manage your views and collections</li>
              <li className="separator"></li>
              <li className={`option label`}>YOUR COLLECTIONS</li>
              <li className="separator"></li>
              <li className={`option label`}>YOUR VIEWS</li>
              <li className="separator"></li>
              <li className={`option label`}>SHARED WITH YOU</li>
            </ul>
          </Menu>
        )}
      </div>
    </Tabs>
  );
};

export const preparePrimaryLabel = (timeselection: TimeSelection) => {
  const hoursDisplay = TimeRangeUtil.calculateTimePreset(
    'label',
    timeselection.streamingTimeInSeconds,
  );
  if (timeselection.nowSelected && !hoursDisplay) {
    return (
      <>
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          DateFormats.DATE,
        )}{' '}
        <span className="time">
          {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
            DateFormats.TIME,
          )}
        </span>{' '}
        - Now
      </>
    );
  }
  return (
    hoursDisplay || (
      <span className="time">
        {TimeUtils.toTimezone(timeselection.startTime, timeselection.timezone).format(
          'MM-DD-YYYY HH:mm:ss a zz',
        )}
      </span>
    )
  );
};

export const ViewTab = ({ view_id }: { view_id: string }) => {
  const { setActiveView, activeView, activeModes } = useLayoutContext();
  const { viewState, duplicateView, saveView, deleteView, closeView } = useViewContext();
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const [openSaveModel, setOpenSaveModel] = useState(false);
  const [confirmCloseModel, setConfirmCloseModel] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setOpenSubMenu(false));

  const methods = useForm<{ name: string }>({
    mode: 'onBlur',
    defaultValues: {
      name: viewState.name || '',
    },
  });
  return (
    <Tab key={view_id} isActive={activeView === view_id}>
      <div className="tab-content">
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent tab selection when clicking submenu link
            setActiveView(view_id);
            // setSelectedView(view_id);
          }}
        >
          <span>{viewState.isDirty && '*'}</span>
          {view_id === 'default' && <Icon name={ICONS.Home} width="20" height="20" />}
          <span className="label">
            {activeModes[view_id]}: {preparePrimaryLabel(viewState.timeSelection)}
          </span>
        </div>
        <div ref={menuRef}>
          <Icon
            className="subMenu-link"
            name={ICONS.Chevron}
            width="10"
            color="#A0A38D"
            onClick={(e) => {
              e.stopPropagation(); // Prevent tab selection when clicking submenu link
              setOpenSubMenu((prev) => !prev);
            }}
          />
          {openSubMenu && (
            <Menu>
              <ul>
                <li
                  className={`option ${!viewState.isDirty ? 'disabled' : ''}`}
                  onClick={() => {
                    if (viewState.view_id === 'default') {
                      saveView('default');
                      return;
                    }
                    setOpenSaveModel(true);
                    setOpenSubMenu(false);
                  }}
                >
                  {viewState.view_id !== '' && viewState.view_id !== 'default'
                    ? 'Save as new view'
                    : 'Save Changes'}
                </li>
                <li
                  className="option"
                  onClick={() => {
                    duplicateView();
                    setOpenSubMenu(false);
                  }}
                >
                  Duplicate
                </li>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      deleteView();
                      setOpenSubMenu(false);
                    }}
                  >
                    Delete
                  </li>
                )}
                <li className="separator"></li>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      // if (viewState.isDirty) {
                      setConfirmCloseModel(true);
                      setOpenSubMenu(false);
                      return;
                      // }
                    }}
                  >
                    Close
                  </li>
                )}
                <li
                  className="option"
                  onClick={() => {
                    setShowShareModal(true);
                    setOpenSubMenu(false);
                  }}
                >
                  Share
                </li>
              </ul>
            </Menu>
          )}
        </div>
      </div>
      {openSaveModel && (
        <FormProvider {...methods}>
          <SaveViewModal
            onClose={() => {
              setOpenSaveModel(false);
            }}
          />
        </FormProvider>
      )}
      {confirmCloseModel && (
        <ConfirmCloseModal
          onConfirm={async (state) => {
            console.log('state----', state);
            if (state) await closeView();
            setConfirmCloseModel(false);
          }}
        />
      )}
      {showShareModal && <ShareViewLinkModal view={viewState} onClose={setShowShareModal} />}

      <TabBaseLine />
    </Tab>
  );
};

const FieldLabel = styled.label`
  color: #a0a38d;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  margin-bottom: 5px;
  display: inline-block;
`;

const SaveViewModal = ({ onClose }: { onClose: (closeState: boolean) => void }) => {
  const { register, watch } = useFormContext();
  const { viewState, saveView } = useViewContext();
  return (
    <Modal open={true}>
      <ModalContents
        title={'Save View'}
        styles={{ content: { maxWidth: '450px' } }}
        confirmButtonText="Save View"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onClose(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          console.log('save view', watch(`name`));
          await saveView(watch(`name`));
          // onClose(false);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Save the time range, tags, and charting options you are currently using as a View.</p>
          <div>
            <FieldLabel>Name</FieldLabel>
            <TextInput
              type="string"
              value={watch(`name`)}
              className="view-name"
              label=""
              placeholder=""
              errorId={'view-name-required'}
              disabled={false}
              {...register(`name`, {
                setValueAs: (v) => {
                  if (!v) return '';
                  return v;
                },
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view name';
                  }
                  return true;
                },
              })}
              errorMessage={''}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <FieldLabel>Timeframe</FieldLabel>
            <div>{preparePrimaryLabel(viewState.timeSelection)}</div>
          </div>
        </div>
      </ModalContents>
    </Modal>
  );
};

const ConfirmCloseModal = ({ onConfirm }: { onConfirm: (closeState: boolean) => void }) => {
  return (
    <Modal open={true}>
      <ModalContents
        title={'Confirm to close view'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Close View"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onConfirm(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onConfirm(true);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Are you sure you want to close this view?</p>
        </div>
      </ModalContents>
    </Modal>
  );
};

const ShareViewLinkModal = ({
  view,
  onClose,
}: {
  view: ViewState;
  onClose: (closeState: boolean) => void;
}) => {
  const { tenant } = useTenant();
  const viewLink = `${window.location.origin}${generatePath(Paths.SHARE, {
    tenantId: tenant.toString(),
    hash: view.view_id,
  })}`;
  return (
    <Modal open={true}>
      <ModalContents
        title={'Share View'}
        styles={{ content: { minWidth: '450px' } }}
        confirmButtonText="Close"
        closeButtonCallback={() => {
          onClose(false);
        }}
        shouldCloseOnEsc={true}
        confirmButtonCallback={async () => {
          onClose(false);
        }}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <ExportModal view={view} link={viewLink} isError={false} />
      </ModalContents>
    </Modal>
  );
};
