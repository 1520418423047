import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { DefaultTheme } from 'styled-components';

import { conditionalLimitTooltipValue, tooltipMinMax, tooltipValue } from './tooltip-components';

import {
  Anomaly,
  Dimensions,
  Mode,
  SVGDefsSelection,
  TenantConfiguration,
  TimeSeries,
  TooltipContentData,
  TooltipData,
} from '@controlrooms/models';
import {
  cleanTagName,
  generateAbbreviation,
  xAccessor,
  yAccessor,
  yAccessorMax,
  yAccessorMin,
  yScaleDomain,
} from '@controlrooms/utils';
import { TimeUtils } from '@controlrooms/utils';

// Tooltip transition constants
const TOOLTIP_DELAY_IN = 650;
const TOOLTIP_DELAY_OUT = 0;
const TOOLTIP_DURATION_IN = 150;
const TOOLTIP_DURATION_OUT = 200;
const TOOLTIP_EASING_IN = d3.easeCubicIn;
const TOOLTIP_EASING_OUT = d3.easeCubicOut;

export type Context = 'analyze' | 'monitor';

type ChartDataType = {
  timeseries: TimeSeries[];
  limits?: Mode[];
};

// Utility for transitioning tooltips in/out
const transitionTooltip = (
  s: Selection<d3.BaseType, unknown, HTMLElement | null, undefined>,
  dir: 'in' | 'out' = 'in',
) => {
  const tIn = dir === 'in';

  s.transition()
    .delay(tIn ? TOOLTIP_DELAY_IN : TOOLTIP_DELAY_OUT)
    .duration(tIn ? TOOLTIP_DURATION_IN : TOOLTIP_DURATION_OUT)
    .ease(tIn ? TOOLTIP_EASING_IN : TOOLTIP_EASING_OUT)
    .style('opacity', tIn ? 1 : 0);
};

export const addAnalyzeTooltips = (
  chart: SVGDefsSelection,
  dimensions: Dimensions,
  theme: DefaultTheme,
  seriesData: TimeSeries[],
  chartHeight: number,
  folder: number,
  tag: string,
  displayFreqLine: boolean,
  limitData?: Mode[],
) => {
  const chartData: ChartDataType[] = [{ timeseries: seriesData, limits: limitData }];

  // add frequent tooltip
  if (displayFreqLine)
    chart
      .append('text')
      .attr('x', 5)
      .attr('y', 14)
      .style('font-size', '11px')
      .attr('class', 'tooltip-frequent')
      .attr('id', `tooltip-frequent-${folder}-${tag}`)
      .data([seriesData]);

  chart
    .append('text')
    .attr('y', 14)
    .style('font-size', '11px')
    .attr('class', 'tooltip-limit')
    .attr('id', `tooltip-limit-${folder}-${tag}`)
    .data([limitData]);

  // add tooltip
  const tooltip = chart
    .append('g')
    .style('opacity', 0)
    .classed('tooltip', true)
    .data<ChartDataType>(chartData)
    .attr('pointer-events', 'none');

  tooltip
    .append('circle')
    .attr('r', 4)
    .attr('fill', theme.chart.highlightColor)
    .attr('stroke', 'black')
    .attr('stroke-width', 1)
    .style('pointer-events', 'none')
    .classed('tooltipDot', true);

  tooltip
    .append('line')
    .attr('y2', chartHeight)
    .style('stroke', '#2E4547')
    .style('stroke-width', '2px')
    .classed('tooltipLine', true);
  //Todo: look into adding gradient
  // .attr('stroke', 'url(#tooltipLine)');
  // .style('stroke', 'url(#tooltipLine)');
};

const resetHighLimits = (tag: string, theme: DefaultTheme) => {
  const hLineClass = `path[class*='limit-high-limit-${tag}-']`;
  const hhLineClass = `path[class*='limit-high-limit-${tag}-']`;
  const hTriangleClass = `polygon[class*='triangle-high-limit-${tag}-']`;
  const hhTriangleClass = `polygon[class*='triangle-high-high-${tag}-']`;

  const defaultColor = theme.chart.limitIndicatorDefault;

  d3.selectAll(hLineClass)?.style('stroke', defaultColor);
  d3.selectAll(hhLineClass).style('stroke', defaultColor);
  d3.selectAll(hTriangleClass).style('stroke', defaultColor);
  d3.selectAll(hhTriangleClass).style('stroke', defaultColor).style('fill', defaultColor);
};
const resetLowLimits = (tag: string, theme: DefaultTheme) => {
  const lLineClass = `path[class*='limit-low-limit-${tag}-']`;
  const llLineClass = `path[class*='limit-low-limit-${tag}-']`;
  const lTriangleClass = `polygon[class*='triangle-low-limit-${tag}-']`;
  const llTriangleClass = `polygon[class*='triangle-low-low-${tag}-']`;

  const defaultColor = theme.chart.limitIndicatorDefault;

  d3.selectAll(lLineClass).style('stroke', defaultColor);
  d3.selectAll(llLineClass).style('stroke', defaultColor);
  d3.selectAll(lTriangleClass).style('stroke', defaultColor);
  d3.selectAll(llTriangleClass).style('stroke', defaultColor).style('fill', defaultColor);
};

export const resetLimitLines = (tag: string, theme: DefaultTheme) => {
  resetHighLimits(tag, theme);
  resetLowLimits(tag, theme);
};

export const handleAnomalyMouseOver = () => {
  d3.select('.time-value').text('anomaly hover');
};

export const handleTooltipMouseOut = (context: Context) => {
  if (context === 'analyze') {
    _handleTooltipMouseOut(
      '.tooltip,.tooltip-content,.tooltip-timeline,.tooltip-frequent,.tooltip-limit',
    );
  }
  if (context === 'monitor') {
    _handleTooltipMouseOut('.tooltip-content,.tooltip-timeline');
  }
};

const _handleTooltipMouseOut = (selector: string) => {
  // on mouse out hide line, circles and text
  const tooltipEls = d3.selectAll(selector);
  transitionTooltip(tooltipEls, 'out');
};

export const handleTooltipMouseOver = (
  context: Context,
  opts?: { folder?: number; tag?: string; viewId?: string },
) => {
  if (context === 'analyze') {
    handleTooltipMouseOverAnalyze(opts?.folder, opts?.tag, opts?.viewId);
  }
  if (context === 'monitor') {
    handleTooltipMouseOverMonitor(opts?.folder, opts?.viewId);
  }
};

const handleTooltipMouseOverAnalyze = (folder?: number, tag?: string, viewId = '') => {
  if (d3.select(`.analyze-chart-rect-${viewId}`).empty()) return;

  // on mouse in show line, circles and text
  d3.selectAll(
    '.tooltip,.tooltip-content,.tooltip-timeline,.tooltip-frequent,.tooltip-limit',
  ).classed('active', false);

  if (folder && tag) {
    const activeContent = `tooltip-${viewId}-${folder}-${tag}`;
    d3.select(`.tooltip-content[id="${activeContent}"]`).classed('active', true);

    const activeFrequent = `tooltip-frequent-${viewId}-${folder}-${tag}`;
    d3.select(`.tooltip-frequent[id="${activeFrequent}"]`).classed('active', true);

    const activeLimit = `tooltip-limit-${viewId}-${folder}-${tag}`;
    d3.select(`.tooltip-limit[id="${activeLimit}"]`).classed('active', true);
  }

  // transitionTooltip(tooltipEls);
};

const handleTooltipMouseOverMonitor = (folder?: number, viewId = '') => {
  if (d3.select(`.heatmap-chart-rect-${viewId}`).empty()) return;

  d3.selectAll('.tooltip-content').classed('active', false);

  const timeline = d3.select(`.tooltip-timeline-${viewId}`);

  const activeContent = `tooltip-content-${folder}`;
  const content = d3.select(`.tooltip-content[id="${activeContent}"]`).classed('active', true);

  [timeline, content].forEach((s) => transitionTooltip(s));
};

export const handleTooltipMouseMove = (
  context: Context,
  e: MouseEvent,
  xScale: d3.ScaleTime<number, number, never>,
  timezone: string,
  theme: DefaultTheme,
  opts?: {
    tenantConfig?: TenantConfiguration;
    wideSpacing?: boolean;
    interval?: number;
    displayFreqLine?: boolean;
    chartWidth?: number;
    hideFreqValueOnHover?: boolean;
    isStringValuesChart?: boolean;
    viewId?: string;
  },
  chart?: SVGDefsSelection,
  clippedArea?: SVGDefsSelection,
) => {
  if (context === 'analyze') {
    handleTooltipMouseMoveAnalyze(
      e,
      xScale,
      timezone,
      theme,
      opts?.interval,
      opts?.displayFreqLine,
      chart,
      clippedArea,
      opts?.chartWidth,
      opts?.hideFreqValueOnHover,
      opts?.isStringValuesChart,
      opts?.viewId,
    );
  }
  if (context === 'monitor') {
    handleTooltipMouseMoveMonitor(
      e,
      xScale,
      timezone,
      opts?.tenantConfig,
      opts?.wideSpacing,
      opts?.viewId,
    );
  }
};

export const handleEventTooltip = (
  e: MouseEvent,
  xScale: d3.ScaleTime<number, number, never>,
  theme: DefaultTheme,
  displayFreqLine?: boolean,
  chart?: SVGDefsSelection,
  clippedArea?: SVGDefsSelection,
  isStringValuesChart?: boolean,
) => {
  const analyzeChart = d3.select('.analyze-chart-rect');
  if (analyzeChart.empty()) return;

  const mousePos = d3.pointer(e, this);
  const date = xScale.invert(mousePos[0]);

  // Custom Bisector - left, center, right
  const bisector = d3.bisector(xAccessor).left;

  const chartHeight = parseInt(analyzeChart.style('height'), 10);

  d3.selectAll('.tooltip').each(function (d: ChartDataType | unknown) {
    const chartData = d as ChartDataType;
    const seriesData = chartData?.timeseries as TimeSeries[];
    const limit = chartData?.limits as Mode[];
    const yScale = d3
      .scaleLinear()
      .domain(yScaleDomain(seriesData, limit, displayFreqLine))
      .range([chartHeight, 0]);

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];
    const tooltip = d3.select(this).raise();

    if (!timeSeries) {
      tooltip.style('opacity', 0);
      return;
    } else {
      tooltip.style('opacity', 1);
    }

    const dot: Selection<d3.BaseType, unknown, null, undefined> = tooltip
      .select('circle.tooltipDot')
      .attr('cx', xScale(xAccessor(timeSeries)))
      .attr('cy', yScale(yAccessor(timeSeries)))
      .raise();

    const line: Selection<d3.BaseType, unknown, null, undefined> = tooltip
      .select('.tooltipLine')
      .attr('x1', xScale(xAccessor(timeSeries)))
      .attr('x2', xScale(xAccessor(timeSeries)))
      .attr('y1', 0)
      .attr('y2', chartHeight);

    transitionTooltip(dot);
    transitionTooltip(line);
  });

  d3.selectAll('.tooltip-content').each(function (d: TooltipContentData | unknown) {
    const { tooltipData: seriesData, limitArrayData } = d as TooltipContentData;

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];
    const dateTimestamp = date.valueOf();
    const matchedSeries = [] as Mode[];
    const regularLimits: Mode[] = limitArrayData.filter(
      (limit: Mode) => limit.limitCondition === 'n/a',
    );

    regularLimits.forEach((limit: Mode) => {
      if (dateTimestamp >= limit.start && dateTimestamp <= limit.end) {
        matchedSeries.push(limit);
      }
    });

    const yScale = d3
      .scaleLinear()
      .domain(yScaleDomain(seriesData, limitArrayData, displayFreqLine))
      .range([chartHeight, 0]);

    const content: Selection<d3.BaseType, unknown, null, undefined> = d3.select(this);

    if (!timeSeries) {
      content.style('opacity', 0);
      return;
    } else {
      content.style('opacity', 1);
    }

    // position and show tooltip content
    content
      .style('left', `${xScale(xAccessor(timeSeries)) - 10}px`)
      .style('top', !isStringValuesChart ? `${yScale(yAccessor(timeSeries)) - 40}px` : '0px');

    // add main value text
    content.select('span.value').html(tooltipValue(timeSeries, isStringValuesChart));

    if (limitArrayData.length) {
      const tagName = cleanTagName(limitArrayData[0].tagName) || '';

      // Filtering out the 'highs' and 'lows' ranges
      const highHighRanges = limitArrayData.filter((limit) => limit.type === 'highHighs');
      const highRanges = limitArrayData.filter((limit) => limit.type === 'highs');
      const lowRanges = limitArrayData.filter((limit) => limit.type === 'lows');
      const lowLowRanges = limitArrayData.filter((limit) => limit.type === 'lowLows');

      // Finding the index of the range the mouse is currently in for both highs and lows
      const currentHighHighRangeIndex = highHighRanges.findIndex(
        (range) => date >= new Date(range.start) && date <= new Date(range.end),
      );
      const currentHighRangeIndex = highRanges.findIndex(
        (range) => date >= new Date(range.start) && date <= new Date(range.end),
      );

      const currentLowRangeIndex = lowRanges.findIndex(
        (range) => date >= new Date(range.start) && date <= new Date(range.end),
      );
      const currentLowLowRangeIndex = lowLowRanges.findIndex(
        (range) => date >= new Date(range.start) && date <= new Date(range.end),
      );

      if (currentHighRangeIndex == -1 && currentHighHighRangeIndex == -1) {
        resetHighLimits(tagName, theme);
      }
      if (currentLowRangeIndex == -1 && currentLowLowRangeIndex == -1) {
        resetLowLimits(tagName, theme);
      }

      const currentHighHigh =
        currentHighHighRangeIndex !== -1 ? highRanges[currentHighHighRangeIndex]?.value : null;
      const currentHigh =
        currentHighRangeIndex !== -1 ? highRanges[currentHighRangeIndex]?.value : null;
      const currentLow =
        currentLowRangeIndex !== -1 ? lowRanges[currentLowRangeIndex]?.value : null;
      const currentLowLow =
        currentLowLowRangeIndex !== -1 ? lowRanges[currentLowLowRangeIndex]?.value : null;

      const maxVal = parseFloat(Number(yAccessorMax(timeSeries)).toPrecision(6)).toFixed(5);
      const minVal = parseFloat(Number(yAccessorMin(timeSeries)).toPrecision(6)).toFixed(5);

      const isHighHighs = currentHighHigh && Number(maxVal) > Number(currentHighHigh);
      const isHigh = currentHigh && Number(maxVal) > Number(currentHigh);
      const isLow = currentLow && Number(currentLow) > Number(minVal);
      const isLowLow = currentLowLow && Number(currentLowLow) > Number(minVal);

      //action on high high limit line
      const lastHighHighRangeIdx = currentHighHighRangeIndex;
      const lastHighRangeIdx = currentHighRangeIndex;
      const lastLowRangeIdx = currentLowRangeIndex;
      const lastLowLowRangeIdx = currentLowLowRangeIndex;

      updateChartStyle(
        !!(maxVal && isHighHighs),
        'high-high',
        currentHighRangeIndex,
        lastHighHighRangeIdx,
        theme,
        tagName,
        clippedArea,
        chart,
      );
      updateChartStyle(
        !!(maxVal && isHigh),
        'high',
        currentHighRangeIndex,
        lastHighRangeIdx,
        theme,
        tagName,
        clippedArea,
        chart,
      );
      updateChartStyle(
        !!(minVal && isLow),
        'low',
        currentLowRangeIndex,
        lastLowRangeIdx,
        theme,
        tagName,
        clippedArea,
        chart,
      );
      updateChartStyle(
        !!(minVal && isLowLow),
        'low-low',
        currentLowLowRangeIndex,
        lastLowLowRangeIdx,
        theme,
        tagName,
        clippedArea,
        chart,
      );
    }

    // add min max values
    content
      .select('span.minMax')
      .html(tooltipMinMax(timeSeries, limitArrayData, isStringValuesChart));

    // Animate tooltip content in
    transitionTooltip(content);
  });
};

const handleTooltipMouseMoveAnalyze = (
  e: MouseEvent,
  xScale: d3.ScaleTime<number, number, never>,
  timezone: string,
  theme: DefaultTheme,
  interval?: number,
  displayFreqLine?: boolean,
  chart?: SVGDefsSelection,
  clippedArea?: SVGDefsSelection,
  chartWidth?: number,
  hideFreqValueOnHover?: boolean,
  isStringValuesChart?: boolean,
  viewId = '',
) => {
  console.log('viewId>>>', viewId);
  const analyzeChart = d3.select(`.analyze-chart-rect-${viewId}`);
  if (analyzeChart.empty()) return;

  const mousePos = d3.pointer(e, this);
  const date = xScale.invert(mousePos[0]);

  // Custom Bisector - left, center, right
  const bisector = d3.bisector(xAccessor).left;

  const chartHeight = parseInt(analyzeChart.style('height'), 10);

  d3.selectAll(`.tooltip-${viewId}`).each(function (d: ChartDataType | unknown) {
    const chartData = d as ChartDataType;
    const seriesData = chartData?.timeseries as TimeSeries[];
    const limit = chartData?.limits as Mode[];
    const yScale = d3
      .scaleLinear()
      .domain(yScaleDomain(seriesData, limit, displayFreqLine))
      .range([chartHeight, 0]);

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];

    const tooltip = d3.select(this).raise();

    if (!timeSeries) {
      tooltip.style('opacity', 0);
      return;
    } else {
      tooltip.style('opacity', 1);
    }

    const dot: Selection<d3.BaseType, unknown, null, undefined> = tooltip
      .select('circle.tooltipDot')
      .attr('cx', xScale(xAccessor(timeSeries)))
      .attr('cy', yScale(yAccessor(timeSeries)))
      .raise();

    const line: Selection<d3.BaseType, unknown, null, undefined> = tooltip
      .select('.tooltipLine')
      .attr('x1', xScale(xAccessor(timeSeries)))
      .attr('x2', xScale(xAccessor(timeSeries)))
      .attr('y1', 0)
      .attr('y2', yScale(yAccessor(timeSeries)));

    // Animate all together
    [dot, line].forEach((s) => transitionTooltip(s));
  });

  // Align timeline tooltip with the current data point tooltip of the current chart where the mouse is being hovered.
  d3.selectAll(`.tooltip-content-${viewId}.active`).each(function (d: TimeSeries[] | unknown) {
    const { tooltipData: seriesData } = d as TooltipContentData;

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];

    const timeline: Selection<d3.BaseType, unknown, HTMLElement, undefined> = d3
      .select(`.tooltip-timeline-${viewId}`)
      .style('left', `${xScale(xAccessor(timeSeries)) - 12}px`)
      .style('bottom', `15px`);

    d3.select(`.time-value-${viewId}`).text(
      `
    ${TimeUtils.toTimezone(xAccessor(timeSeries), timezone).format('MMM-DD-YY')}
    ${TimeUtils.toTimezone(xAccessor(timeSeries), timezone).format('HH:mm:ss')}
    `,
    );
    transitionTooltip(timeline);
  });

  d3.selectAll(`.tooltip-frequent-${viewId}.active`).each(function (d: TimeSeries[] | unknown) {
    const seriesData = d as TooltipData[];

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];
    const frequentTooltipContent: Selection<d3.BaseType, unknown, null, undefined> =
      d3.select(this);

    if (!timeSeries || hideFreqValueOnHover) {
      frequentTooltipContent.style('opacity', 0);
      return;
    } else {
      frequentTooltipContent
        .style('opacity', 1)
        .text(`Freq. Value ${timeSeries.frequent ? timeSeries.frequent.toPrecision(6) : '--'}`);
    }
  });

  d3.selectAll(`.tooltip-content-${viewId}`).each(function (d: TooltipContentData | unknown) {
    const { tooltipData: seriesData, limitArrayData } = d as TooltipContentData;

    const index = bisector(seriesData, date);
    const timeSeries = seriesData?.[index - 1];
    const dateTimestamp = date.valueOf();
    const matchedSeries = [] as Mode[];
    const hasOnlyRegularLimits = limitArrayData.every(
      (limit: Mode) => limit.limitCondition === 'n/a',
    );
    const regularLimits = limitArrayData.filter((limit: Mode) => limit.limitCondition === 'n/a');

    regularLimits.forEach((limit: Mode) => {
      if (dateTimestamp >= limit.start && dateTimestamp <= limit.end) {
        matchedSeries.push(limit);
      }
    });

    const limitIndex = limitArrayData.findIndex(
      (limit: Mode) => dateTimestamp >= limit.start && dateTimestamp <= limit.end,
    );
    const currentLimitOnHover = limitArrayData[limitIndex];

    const content: Selection<d3.BaseType, unknown, null, undefined> = d3.select(this);
    content.style('min-height', '32px');
    if (!timeSeries) {
      content.style('opacity', 0);
      return;
    } else {
      content.style('opacity', 1);
    }

    const tooltipWidth = (content.node() as HTMLElement).getBoundingClientRect()?.width ?? 140;
    // position and show tooltip content
    content
      .style('left', `${xScale(xAccessor(timeSeries)) - (tooltipWidth / 2 - 44)}px`)
      .style('bottom', `${chartHeight + 6}px`);

    if (currentLimitOnHover && currentLimitOnHover.limitCondition !== 'n/a') {
      content
        .select('span.value')
        .html(conditionalLimitTooltipValue(timeSeries, currentLimitOnHover, isStringValuesChart));
    } else {
      // add main value text
      content
        .select('span.value')
        // d3's .html() function has a built-in clear on value change
        .html(tooltipValue(timeSeries, isStringValuesChart));

      if (limitArrayData.length) {
        const tagName = cleanTagName(limitArrayData[0].tagName) || '';

        // Filtering out the 'highs' and 'lows' ranges
        const highHighRanges = limitArrayData.filter((limit) => limit.type === 'highHighs');
        const highRanges = limitArrayData.filter((limit) => limit.type === 'highs');
        const lowRanges = limitArrayData.filter((limit) => limit.type === 'lows');
        const lowLowRanges = limitArrayData.filter((limit) => limit.type === 'lowLows');

        // Finding the index of the range the mouse is currently in for both highs and lows
        const currentHighHighRangeIndex = highHighRanges.findIndex(
          (range) => date >= new Date(range.start) && date <= new Date(range.end),
        );
        const currentHighRangeIndex = highRanges.findIndex(
          (range) => date >= new Date(range.start) && date <= new Date(range.end),
        );

        const currentLowRangeIndex = lowRanges.findIndex(
          (range) => date >= new Date(range.start) && date <= new Date(range.end),
        );
        const currentLowLowRangeIndex = lowLowRanges.findIndex(
          (range) => date >= new Date(range.start) && date <= new Date(range.end),
        );

        if (currentHighRangeIndex == -1 && currentHighHighRangeIndex == -1) {
          resetHighLimits(tagName, theme);
        }
        if (currentLowRangeIndex == -1 && currentLowLowRangeIndex == -1) {
          resetLowLimits(tagName, theme);
        }

        const currentHighHigh =
          currentHighHighRangeIndex !== -1 ? highRanges[currentHighHighRangeIndex]?.value : null;
        const currentHigh =
          currentHighRangeIndex !== -1 ? highRanges[currentHighRangeIndex]?.value : null;
        const currentLow =
          currentLowRangeIndex !== -1 ? lowRanges[currentLowRangeIndex]?.value : null;
        const currentLowLow =
          currentLowLowRangeIndex !== -1 ? lowRanges[currentLowLowRangeIndex]?.value : null;

        const maxVal = parseFloat(Number(yAccessorMax(timeSeries)).toPrecision(6)).toFixed(5);
        const minVal = parseFloat(Number(yAccessorMin(timeSeries)).toPrecision(6)).toFixed(5);

        const isHighHighs = currentHighHigh && Number(maxVal) > Number(currentHighHigh);
        const isHigh = currentHigh && Number(maxVal) > Number(currentHigh);
        const isLow = currentLow && Number(currentLow) > Number(minVal);
        const isLowLow = currentLowLow && Number(currentLowLow) > Number(minVal);

        //action on high high limit line
        const lastHighHighRangeIdx = currentHighHighRangeIndex;
        const lastHighRangeIdx = currentHighRangeIndex;
        const lastLowRangeIdx = currentLowRangeIndex;
        const lastLowLowRangeIdx = currentLowLowRangeIndex;

        updateChartStyle(
          !!(maxVal && isHighHighs),
          'high-high',
          currentHighRangeIndex,
          lastHighHighRangeIdx,
          theme,
          tagName,
          clippedArea,
          chart,
        );
        updateChartStyle(
          !!(maxVal && isHigh),
          'high',
          currentHighRangeIndex,
          lastHighRangeIdx,
          theme,
          tagName,
          clippedArea,
          chart,
        );
        updateChartStyle(
          !!(minVal && isLow),
          'low',
          currentLowRangeIndex,
          lastLowRangeIdx,
          theme,
          tagName,
          clippedArea,
          chart,
        );
        updateChartStyle(
          !!(minVal && isLowLow),
          'low-low',
          currentLowLowRangeIndex,
          lastLowLowRangeIdx,
          theme,
          tagName,
          clippedArea,
          chart,
        );
      }

      // add min max values
      content
        .select('span.minMax')
        .style('height', timeSeries?.value === null ? '0px' : '')
        // d3's .html() function has a built-in clear on value change
        .html(tooltipMinMax(timeSeries, limitArrayData, isStringValuesChart));
    }
    // Animate tooltip content in
    transitionTooltip(content);

    if (hasOnlyRegularLimits) {
      d3.selectAll(`.tooltip-limit-${viewId}`).each(function (d: Mode[] | unknown) {
        const limitData = d as Mode[];
        const referenceDate = date.valueOf();
        const matchedSeries = [] as Mode[];

        limitData.forEach((limit) => {
          if (referenceDate >= limit.start && referenceDate <= limit.end) {
            matchedSeries.push(limit);
          }
        });

        const limitsTooltipContent: Selection<d3.BaseType, unknown, null, undefined> =
          d3.select(this);

        if (matchedSeries.length > 0) {
          const tooltipText = matchedSeries
            .map(({ type, value }) => `${generateAbbreviation(type)}: ${value}`)
            .join(', ');

          const tooltipsOfLimits = document.getElementsByClassName('tooltip-limit active');
          let widthOfText = 0,
            textPosition = 100;
          if (tooltipsOfLimits.length > 0) {
            const rect = tooltipsOfLimits[0]?.getBoundingClientRect();
            widthOfText = rect.width;
          }

          if (chartWidth) textPosition = chartWidth - widthOfText - 10;
          limitsTooltipContent
            .text(tooltipText)
            .attr('x', textPosition)
            .style('opacity', 1)
            .classed(`tooltip-limit-show-${viewId}`, true);
        } else {
          limitsTooltipContent.classed(`tooltip-limit-show-${viewId}`, false).style('opacity', 0);
          return;
        }
      });
    }
  });
};

const updateChartStyle = (
  condition: boolean,
  prefix: string,
  currentIndex: number,
  lastIndex: number,
  theme: DefaultTheme,
  tagName?: string,
  clippedArea?: SVGDefsSelection,
  chart?: SVGDefsSelection,
) => {
  if (condition) {
    clippedArea
      ?.select(`.limit-${prefix}-limit-${tagName}-${currentIndex}`)
      .style('stroke', theme.chart.limitIndicator);
    chart
      ?.select(`.triangle-${prefix}-limit-${tagName}-${currentIndex}`)
      .style('stroke', theme.chart.limitIndicator);
    if (prefix === 'high-high' || prefix == 'low-low') {
      chart
        ?.select(`.triangle-${prefix}-limit-${tagName}-${currentIndex}`)
        .style('fill', theme.chart.limitIndicator);
    }
  } else {
    clippedArea
      ?.select(`.limit-${prefix}-limit-${tagName}-${lastIndex}`)
      .style('stroke', theme.chart.limitIndicatorDefault);
    chart
      ?.select(`.triangle-${prefix}-limit-${tagName}-${lastIndex}`)
      .style('stroke', theme.chart.limitIndicatorDefault);
    if (prefix === 'high-high' || prefix == 'low-low') {
      chart
        ?.select(`.triangle-${prefix}-limit-${tagName}-${lastIndex}`)
        .style('fill', theme.chart.limitIndicatorDefault);
    }
  }
};

const handleTooltipMouseMoveMonitor = (
  e: MouseEvent,
  xScale: d3.ScaleTime<number, number, never>,
  timezone: string,
  tenantConfig?: TenantConfiguration,
  wideSpacing?: boolean,
  viewId = '',
) => {
  const monitorChart = d3.select(`.heatmap-chart-rect-${viewId}`);
  if (monitorChart.empty()) return;

  const mousePos = d3.pointer(e, this);
  const date = xScale.invert(mousePos[0]);

  const timeline: Selection<d3.BaseType, unknown, HTMLElement, undefined> = d3
    .select(`.tooltip-timeline-${viewId}`)
    .style('bottom', `15px`);
  const timeLineWidth = (timeline.node() as HTMLElement).getBoundingClientRect()?.width ?? 200;
  timeline.style('left', `${mousePos[0] + 30 - timeLineWidth / 2}px`);

  d3.select(`.time-value-${viewId}`).text(`
    ${TimeUtils.toTimezone(date, timezone).format('HH:mm')}
  `);

  const content: Selection<d3.BaseType, unknown, HTMLElement, undefined> = d3.selectAll(
    `.tooltip-content-${viewId}.active`,
  );
  content.style('min-width', `unset`);
  const millis = date.getTime();
  const data = (content.data()?.[0] as Anomaly[] | undefined)?.find(
    (d) => millis >= d.time && millis < d.endTime,
  );

  if (data && tenantConfig) {
    const xVal = xScale((data.endTime - data.time) / 2 + data.time);
    if (data.value > 0) {
      const timeLabel = `${TimeUtils.toTimezone(data.time, timezone).format('HH:mm')} -
      ${TimeUtils.toTimezone(data.endTime, timezone).format('HH:mm')}`;
      d3.select(`.time-value-${viewId}`).text(timeLabel);
      content.text(timeLabel);
    }
    if (data.value === tenantConfig.monitorNoData) {
      content.text('No Data');
    } else if (tenantConfig.monitorErrorThresholds[data.value]) {
      content.text(`${tenantConfig.monitorErrorThresholds[data.value].error} Error`);
    }

    const width = (content.node() as HTMLElement).getBoundingClientRect()?.width ?? 200;
    content
      .style('left', `${xVal + 30 - width / 2}px`)
      .style('bottom', wideSpacing ? '50px' : '30px');

    [timeline, content].filter(Boolean).forEach((s) => transitionTooltip(s));
  } else {
    transitionTooltip(timeline);
    transitionTooltip(content, 'out');
  }
};
