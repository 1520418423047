import { useAuth0 } from '@auth0/auth0-react';
import initHelpHero, { HelpHero } from 'helphero';
import React, { createContext, useEffect, useMemo } from 'react';

import { useTheme } from '../hooks/accounts';
import { isDemoApp } from '../utils/app';

const defaultState = {
  startTour: () => null,
  advanceTour: () => null,
  cancelTour: () => null,
  identify: () => null,
  update: () => null,
  anonymous: () => null,
  on: () => null,
  off: () => null,
  openChecklist: () => null,
  closeChecklist: () => null,
  startChecklist: () => null,
  setOptions: () => null,
};

export const HelpHeroContext = createContext<HelpHero>(defaultState);

const HelpHeroContextProvider: React.FC = ({ children }) => {
  const appId = import.meta.env.REACT_APP_HELP_HERO_APP_ID;
  const isDemo = isDemoApp(location.pathname);

  const [mouseMoved, setMouseMoved] = React.useState(false);
  const [userAgentIsBot, setUserAgentIsBot] = React.useState(false);

  const { user, isAuthenticated } = useAuth0();
  const [theme] = useTheme();

  const helpHero = useMemo(
    () => (appId && process.env.NODE_ENV !== 'development' ? initHelpHero(appId) : defaultState),
    [appId],
  );

  // eslint-disable-next-line
  const helpHeroTrackEvent = (ev: any, info: any) => {
    console.log('event', ev.kind, {
      tour_name: info.tour.name,
    });
  };

  useEffect(() => {
    setUserAgentIsBot(
      /bot|google|crawler|spider|robot|crawling|HeadlessChrome/i.test(navigator.userAgent),
    );

    // Detect mouse movement
    const handleMouseMove = () => {
      setMouseMoved(true);
      window.removeEventListener('mousemove', handleMouseMove);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!helpHero) return;
    if (user?.sub && !isDemo) {
      helpHero.identify(user.sub, {
        email: user?.email,
        name: user?.name,
        firstName: user?.given_name,
        lastName: user?.family_name,
        locale: user?.locale,
        mode: theme.name.toLowerCase(),
      });
    } else {
      if (!mouseMoved || userAgentIsBot) return;

      helpHero.anonymous();
    }
  }, [isDemo, helpHero, isAuthenticated, user, theme, mouseMoved, userAgentIsBot]);

  helpHero.on('tour_started', helpHeroTrackEvent);
  helpHero.on('tour_completed', helpHeroTrackEvent);
  helpHero.on('tour_cancelled', helpHeroTrackEvent);

  if (!appId) {
    // TODO - log this somewhere
    console.error('Help Hero application id not configured for environment');
    return <HelpHeroContext.Provider value={defaultState}>{children}</HelpHeroContext.Provider>;
  }

  return <HelpHeroContext.Provider value={helpHero}>{children}</HelpHeroContext.Provider>;
};

export default HelpHeroContextProvider;
