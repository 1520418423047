import styled from 'styled-components';

import { breakpoints } from '../../../app/global-styles';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-left: 10px;
  cursor: pointer;
  &:hover > div {
    color: ${({ theme }) => theme.v2.timeDisplay.primaryTextHover};
    filter: drop-shadow(0px 0px 8px #80f7ff);
    * {
      color: ${({ theme }) => theme.v2.timeDisplay.primaryTextHover};
    }
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TimeRangeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const DurationContainer = styled.div`
  font-weight: 300;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  font-family: 'Open Sans', sans-serif;
  color: ${({ theme }) => theme.v2.timeDisplay.secondaryTextColor};

  ${breakpoints.xl} {
    font-size: 17px;
  }
`;

export const TimeCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
`;

export const PrimaryLabel = styled(Label)`
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  margin-right: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.v2.timeDisplay.primaryTextColor};

  .date {
    font-weight: 300;
    color: ${({ theme }) => theme.v2.timeDisplay.secondaryTextColor};
  }
  ${breakpoints.xl} {
    font-size: 31px;
  }
`;

export const SubLabel = styled(Label)`
  font-size: 11px;
  color: ${({ theme }) => theme.v2.timeDisplay.secondaryTextColor};

  .time {
    color: ${({ theme }) => theme.v2.timeDisplay.primaryTextColor};
  }
`;

export const TimeZone = styled(SubLabel)`
  font-weight: 400;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;

  ${breakpoints.xl} {
    font-size: 13px;
  }
`;

export const Separator = styled.div`
  font-size: 20px;
  margin: 0 10px;
  font-weight: 600;
  line-height: 24px;
`;

export const Chevron = styled(Icon).attrs((props) => ({
  color: props.theme.v2.timeDisplay.chevronColor,
  name: ICONS.Chevron,
}))``;

export const TimeSelectContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.modal.containerBorderColor};
  width: 100%;
  height: 100%;

  .date-time-range-input {
    margin-top: 8px;
  }
`;
