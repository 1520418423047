import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';

import { useSubSystemsById } from '../../../app/hooks/folders';
import { useTagMinMax, useTagMinMaxString } from '../../../app/hooks/tags';
import { EventChart } from '../../components/analyze-charts/event-chart';
import { SearchEvent, TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';
import { Payload } from '../analyze/analyze';
import { ChartGroup } from '../analyze/chart-group';

import EventTab from './event-tab';
import { ChartsContainerWrapper, DatePlaceholder, Tz } from './style';

import { Tag } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

export const EventSearchChart = () => {
  const { event } = useContext(TimeSearchContext);

  if (!event) {
    return <></>;
  }

  return <EventChartViewer event={event} />;
};

const getStringTagNames = (allTags: Tag[] | undefined, tagName: string): boolean => {
  return !!allTags?.find((tag) => tag.name === tagName && !tag.is_numeric);
};

const EventChartViewer = ({
  event: { startTime, endTime, folder, tagName, tag },
}: {
  event: SearchEvent;
}) => {
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const { selectedTab } = useContext(TimeSearchContext);
  const { data: subsystemById } = useSubSystemsById();
  const isStringValueTag = getStringTagNames(subsystemById[folder]?.tags, tagName);

  const {
    data: tagData,
    isLoading: isLoadingTagData,
    isError: isErrorTagData,
  } = useTagMinMax(
    {
      folder: folder,
      tag: tagName,
      streamingTimeInSeconds: 0,
      startTime: dayjs.utc(startTime),
      endTime: dayjs.utc(endTime),
    },
    !isStringValueTag,
  );

  const {
    data: stringTagData,
    isLoading: isLoadingStringTagData,
    isError: isErrorStringTagData,
  } = useTagMinMaxString(
    {
      folder: folder,
      tag: [tagName],
      streamingTimeInSeconds: 0,
      startTime: dayjs.utc(startTime),
      endTime: dayjs.utc(endTime),
    },
    isStringValueTag,
  );

  const payload = useMemo<Payload>(() => {
    return {
      startTime: dayjs.utc(selectedTab?.start_time),
      endTime: dayjs.utc(selectedTab?.end_time),
      streamingTimeInSeconds: undefined,
      searchKeys: [],
      isTrendSearch: true,
    };
  }, [selectedTab?.start_time, selectedTab?.end_time]);

  const {
    timeseries = [],
    anomalies = [],
    modes = [],
    highs = [],
    highHighs = [],
    lows = [],
    lowLows = [],
  } = tagData ?? {};

  const { timeseries: timeSeriesStringData = [] } = stringTagData ?? {};

  const isChartLoading = !isStringValueTag
    ? isLoadingTagData && !tagData
    : isLoadingStringTagData && !stringTagData;

  return (
    <ChartsContainerWrapper>
      <EventTab />
      {isErrorTagData || isErrorStringTagData ? (
        <>Loading...</>
      ) : (
        <>
          {selectedTab?.event_type === 'default' ? (
            <>
              <DatePlaceholder>
                {TimeUtils.trendSearchDateTimeRange(startTime, endTime, timezone)}
                <Tz>{TimeUtils.zoneFormat(timezone, 'z')}</Tz>
              </DatePlaceholder>
              <EventChart
                startTime={dayjs.utc(startTime).tz(timezone)}
                endTime={dayjs.utc(endTime).tz(timezone)}
                key={tagName}
                seriesData={!isStringValueTag ? timeseries : timeSeriesStringData}
                anomalyData={anomalies}
                modes={modes}
                limitsArray={[...highs, ...highHighs, ...lows, ...lowLows]}
                limits={{ highs, highHighs, lows, lowLows }}
                folder={folder}
                tag={tagName}
                tagObj={tag}
                isIOWtag={false} //TODO: IOW-HIDE - Remove after making it generic
                pinned={false}
                isLoading={isChartLoading}
                isError={isErrorTagData || isErrorStringTagData}
              />
            </>
          ) : (
            <ChartGroup payload={payload} />
          )}
        </>
      )}
    </ChartsContainerWrapper>
  );
};
