import dayjs from 'dayjs';
import {
  CSSProperties,
  Context,
  Dispatch,
  FC,
  FormEvent,
  ReactElement,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Role } from '../../../app/constants/auth';
import { Paths } from '../../../app/constants/paths';
import { useRoles } from '../../../app/context/authorization-context';
import { useLabelsAPI } from '../../../app/hooks/accounts-customize';
import { useHeatmap } from '../../../app/hooks/anomalies';
import { FolderSort } from '../../../app/hooks/folders';
import { LabelCheckboxList } from '../../components/heatmap/label-checkbox-list';
import {
  ListContainer,
  TextInputLi,
} from '../../components/timeframe-modal/time-range-selector/styles';
import { AnalyzeContext, AnalyzeContextProps } from '../../context/analyze-context';
import { MonitorContext, MonitorContextProps } from '../../context/monitor-context';
import { CheckboxChange } from '../../context/monitor-filtering-context';
import { useViewContext } from '../../context/view-context';

import { GroupTooltip, Header } from './styles';

import {
  Button,
  Checkbox,
  Icon,
  Li,
  OptionsGroup,
  StyledButton,
  TextInput,
  Ul,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { GroupTooltipData, LabeledEvent } from '@controlrooms/models';
import { delayedMouseOut } from '@controlrooms/utils';

export interface SelectOption {
  value: string;
  label: string;
}

enum AnomalyLabelsDropdownTypes {
  USEFUL = 'Useful',
  NOT_USEFUL = 'Not Useful',
}

// TODO: Can we modify this in the API to avoid conversion?
const convertTzToOffset = (tz: string) => {
  const storedTimezone = dayjs().tz(tz);

  const offset = storedTimezone.utcOffset();
  const offsetHours = Math.floor(Math.abs(offset) / 60);
  const offsetMinutes = Math.abs(offset) % 60;
  const offsetSign = offset < 0 ? '-' : '+';
  const formattedOffset =
    offsetSign +
    String(offsetHours).padStart(2, '0') +
    ':' +
    String(offsetMinutes).padStart(2, '0');
  return formattedOffset;
};

export const AnomalyLabelsDropdown: FC<{
  disabled: boolean;
  type: AnomalyLabelsDropdownTypes;
  children: ReactElement;
  checkboxes: Record<string, boolean>;
  setCheckboxes: Dispatch<SetStateAction<Record<string, boolean>>>;
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
}> = ({
  disabled,
  type,
  children,
  checkboxes,
  setCheckboxes,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const ref = useRef() as RefObject<HTMLUListElement>;

  const listRef = useRef<HTMLDivElement>(null);

  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect(() => {
    const tooltipWidth = ref.current?.clientWidth;

    if (!tooltipWidth) {
      return;
    }

    const viewportWidth = window.innerWidth;
    let leftPosition = type === AnomalyLabelsDropdownTypes.USEFUL ? 25 : 50;

    const elementRect = ref.current.getBoundingClientRect();
    const comparePositionX = elementRect.left + leftPosition;

    // If the tooltip is going off the right edge of the screen
    if (comparePositionX + tooltipWidth > viewportWidth) {
      leftPosition = -tooltipWidth;
    }

    // Ensuring the tooltip does not go off the left edge of the screen
    if (comparePositionX < 0) {
      leftPosition = 0;
    }

    setTooltipPosition({ x: leftPosition, y: 0 });
  }, [type, ref, isDropdownOpen]);

  const tooltipPlacement: Partial<CSSProperties> = {
    position: 'absolute',
    bottom: '280px',
    left: `${tooltipPosition.x}px`,
  };

  const { createLabelTypeMutation, useLabelTypesQuery } = useLabelsAPI();

  const [labelIndex, setLabelIndex] = useState(0);

  const { data: labels } = useLabelTypesQuery();

  // Add a state to manage the 'Other...' checkbox
  const [isOtherChecked, setIsOtherChecked] = useState(false);

  // Add a state to manage the text input
  const [textInput, setTextInput] = useState('');

  const getUsefulNotUsefulIndices = () => {
    const usefulIndex = labels?.result.label_types.findIndex(
      ({ type_name }: { type_name: string }) => type_name === AnomalyLabelsDropdownTypes.USEFUL,
    );
    const notUsefulIndex = labels?.result.label_types.findIndex(
      ({ type_name }: { type_name: string }) => type_name === AnomalyLabelsDropdownTypes.NOT_USEFUL,
    );
    return { usefulIndex, notUsefulIndex };
  };

  const { usefulIndex, notUsefulIndex } = getUsefulNotUsefulIndices();

  const handleEnterPress = async (e: FormEvent) => {
    e.preventDefault();
    const res = await createLabelTypeMutation.mutateAsync({
      description: 'description',
      parent_id: labels?.result.label_types[labelIndex].label_type_id,
      type_name: textInput,
      custom_flag: true,
      visibility: 'public',
      sequence: 6,
    });

    // Make sure the new checkbox is checked
    handleCheckboxChange({
      optionValue: res.result.parent_id,
      subOptionValue: res.result.label_type_id,
    });

    setIsOtherChecked(false);

    // Then clear the input
    setTextInput('');
  };

  useEffect(() => {
    setLabelIndex(type === AnomalyLabelsDropdownTypes.USEFUL ? usefulIndex : notUsefulIndex);
  }, [type, labels, labelIndex, usefulIndex, notUsefulIndex]);

  const handleChangeSubOptionState = (prev: Record<string, boolean>, props: CheckboxChange) => {
    // Only change the state if there is no subSubOptionValue
    if (props.subSubOptionValue !== undefined) {
      return;
    }

    return !prev[props.subOptionValue as number];
  };

  const handleCheckboxChange = (props: CheckboxChange) =>
    setCheckboxes((prev) => {
      return {
        ...prev,
        ...{ [props.subOptionValue as number]: handleChangeSubOptionState(prev, props) },
        ...{ [props.subSubOptionValue as number]: !prev[props.subSubOptionValue as number] },
      };
    });

  const selectMapper = () => {
    return (
      <div>
        <Header>Label This Anomaly</Header>
        <ListContainer ref={listRef} className="scrollable-container">
          <Li>{type}</Li>
          <LabelCheckboxList
            option={{
              label_type_id: -1,
              type_name: 'root',
              child_types: labels?.result.label_types[labelIndex].child_types,
            }}
            checkboxStatusById={checkboxes}
            handleCheckboxChange={handleCheckboxChange}
            parentRef={listRef}
          />
          <TextInputLi>
            <Checkbox
              checked={isOtherChecked}
              onChange={() => setIsOtherChecked(!isOtherChecked)}
            />
            <div style={{ marginRight: '0.5rem' }} />
            {isOtherChecked ? (
              <form onSubmit={handleEnterPress}>
                <TextInput
                  placeholder="Other..."
                  disabled={disabled}
                  value={textInput}
                  onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                    setTextInput((e.target as HTMLInputElement).value)
                  }
                />
              </form>
            ) : (
              'Other...'
            )}
          </TextInputLi>
        </ListContainer>
      </div>
    );
  };

  // The click end (mouse up or touch end) function
  const handleClickEnd = () => {
    setIsDropdownOpen(true);
  };

  return (
    <OptionsGroup isInactive={disabled} style={{ margin: 0, padding: 0 }}>
      <Button
        className="group-tooltip-button"
        buttonSize="large"
        buttonType="icon"
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
        disabled={disabled}
        onMouseUp={handleClickEnd}
        onTouchEnd={handleClickEnd}
      >
        {children}
      </Button>
      <div style={tooltipPlacement}>
        <Ul isOpen={isDropdownOpen} ref={ref}>
          {selectMapper()}
        </Ul>
      </div>
    </OptionsGroup>
  );
};

const findOverlappingEvent = (
  events: LabeledEvent[],
  processId: number,
  startTime: string,
  endTime: string,
): number | undefined => {
  for (const event of events) {
    if (event.process_id === processId) {
      const eventStart = new Date(event.ui_start_time).getTime();
      const eventEnd = new Date(event.ui_end_time).getTime();
      const newStart = new Date(startTime).getTime();
      const newEnd = new Date(endTime).getTime();

      // Check if the new start time or end time falls within the event's time range
      if (
        (newStart >= eventStart && newStart <= eventEnd) ||
        (newEnd >= eventStart && newEnd <= eventEnd)
      ) {
        return event.label_event_id;
      }
    }
  }

  return; // No overlapping event found
};

export const GroupTooltipComponent: FC = () => {
  const location = useLocation();
  const { viewState } = useViewContext();
  const { timeSelection } = viewState;
  const { timezone } = timeSelection;

  type MonitorOrAnalyzeContext = Context<MonitorContextProps | AnalyzeContextProps>;

  let currentContext: Partial<MonitorOrAnalyzeContext> =
    AnalyzeContext as Partial<MonitorOrAnalyzeContext>;

  const isMonitorPage = (): boolean => {
    const pattern = /app\/t\/\d+\/monitor/;
    return pattern.test(location.pathname);
  };

  if (isMonitorPage()) {
    currentContext = MonitorContext as Partial<MonitorOrAnalyzeContext>;
  }

  const { groupTooltipData, selectedFolders } = useContext(
    currentContext as MonitorOrAnalyzeContext,
  );
  const tooltipRef = useRef() as RefObject<HTMLDivElement>;
  const initialElement = document.getElementById(groupTooltipData?.targetElementId as string);
  const initialPosition = initialElement?.getBoundingClientRect();
  const [adjustedGroupTooltipPosition, setAdjustedGroupTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: initialPosition?.x as number, y: (initialPosition?.y || 0) + 24 });

  const { setGroupTooltipData } = useContext(currentContext as MonitorOrAnalyzeContext);

  const { userRoles } = useRoles();

  const [isUsefulDropdownOpen, setIsUsefulDropdownOpen] = useState(false);
  const [isNotUsefulDropdownOpen, setIsNotUsefulDropdownOpen] = useState(false);

  const { createLabeledEventMutation, deleteLabeledEventMutation } = useLabelsAPI();

  useClickOutside(tooltipRef, () => {
    onTooltipClose();
  });

  // Setting ref to tooltip data for mouseouts
  useEffect(() => {
    if (
      groupTooltipData?.targetElementId &&
      !groupTooltipData?.tooltipRef?.current &&
      tooltipRef.current
    ) {
      setGroupTooltipData({
        ...(groupTooltipData as GroupTooltipData),
        tooltipRef,
      });
    }
    // Note: Specifically isabling react-hooks/exhaustive-deps fails in the CI linter. Hacky fix here.
    // eslint-disable-next-line
  }, [groupTooltipData, tooltipRef]);

  // Add a state to manage the checkboxes
  const [checkboxes, setCheckboxes] = useState<Record<string, boolean>>({});

  const closeAllDropdowns = () => {
    setIsUsefulDropdownOpen(false);
    setIsNotUsefulDropdownOpen(false);

    setGroupTooltipData(undefined);
  };

  const { pathname } = useLocation();

  // Get existing labeled events
  const { labeledEvents } = useHeatmap(
    {
      startTime: timeSelection.startTime,
      endTime: timeSelection.endTime,
      streamingTimeInSeconds: timeSelection.streamingTimeInSeconds,
    },
    FolderSort.DEFAULT,
    matchPath(Paths.ANALYZE, pathname) ? true : false,
    selectedFolders,
  );

  const onTooltipClose = async () => {
    if (
      createLabeledEventMutation.isLoading ||
      (!isUsefulDropdownOpen && !isNotUsefulDropdownOpen)
    ) {
      return;
    }

    closeAllDropdowns();

    let keys = Object.keys(checkboxes);

    const overlappingEventId = findOverlappingEvent(
      labeledEvents,
      groupTooltipData?.folderId as number,
      dayjs(groupTooltipData?.start).format('YYYY-MM-DDTHH:mm:ss'),
      dayjs(groupTooltipData?.end).format('YYYY-MM-DDTHH:mm:ss'),
    );

    // Remove empty keys
    keys.forEach((key) => {
      if (key === null || key === 'null' || key === 'undefined') {
        delete checkboxes[key];
        keys = Object.keys(checkboxes);
      }
    });

    if (keys.length === 0) {
      return;
    }

    const formattedOffset = convertTzToOffset(timezone);

    if (overlappingEventId && overlappingEventId > 0) {
      deleteLabeledEventMutation.mutateAsync(overlappingEventId);
    }

    await createLabeledEventMutation.mutateAsync({
      start_time: dayjs(groupTooltipData?.start).format('YYYY-MM-DDTHH:mm:ss') + formattedOffset,
      end_time: dayjs(groupTooltipData?.end).format('YYYY-MM-DDTHH:mm:ss') + formattedOffset,
      label_type_ids: keys.map((key) => Number(key)),
      process_id: groupTooltipData?.folderId as number,
      ensemble_id: 1,
    });

    // Clear the checkboxes
    setCheckboxes({});
  };

  const handleResize = useCallback(() => {
    if (!tooltipRef.current || !groupTooltipData) {
      return;
    }

    const element = document.getElementById(groupTooltipData?.targetElementId as string);

    if (!element || !initialPosition) {
      return;
    }

    const x = element?.getBoundingClientRect().x;

    const getAdjustedTooltipXPosition = (x: number) => {
      if (!tooltipRef.current) {
        return x;
      }

      const tooltipWidth = tooltipRef.current.clientWidth;
      const viewportWidth = window.innerWidth;
      const halfTooltipWidth = tooltipWidth / 2;
      const centeredX = x + (element?.getBoundingClientRect().width || 0) / 2 - halfTooltipWidth;

      // Adjust if the tooltip goes off the right edge of the screen
      if (centeredX + tooltipWidth > viewportWidth) {
        return viewportWidth - tooltipWidth;
      }

      // Adjust if the tooltip goes off the left edge of the screen
      if (centeredX < 0) {
        return 0;
      }

      return centeredX;
    };

    if (
      adjustedGroupTooltipPosition.x === getAdjustedTooltipXPosition(x) &&
      adjustedGroupTooltipPosition.y === initialPosition?.y + 24
    ) {
      return;
    }

    setAdjustedGroupTooltipPosition({
      x: getAdjustedTooltipXPosition(x),
      y: initialPosition?.y + 24,
    });
  }, [groupTooltipData, adjustedGroupTooltipPosition, tooltipRef, initialPosition]);

  useEffect(() => {
    handleResize();
    // Adjust tooltip position on screen resize
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, groupTooltipData]);

  const clearLabel = async (labelId: number) => {
    await deleteLabeledEventMutation.mutateAsync(labelId);

    setGroupTooltipData(undefined);
  };

  // Reset tooltip position offscreen when tooltip is closed
  // Prevents jumping when tooltip is reopened
  if (!groupTooltipData || !groupTooltipData.targetElementId) {
    adjustedGroupTooltipPosition.x = -1000;
    return null;
  }

  // Only show the tooltip if the user has the correct permissions
  if (!userRoles.includes(Role.GLOBAL_LABEL_EDITOR)) {
    return null;
  }

  // Allow mouse outside of element for n seconds before close
  // Prevents accidental close
  const onMouseOut = () => {
    delayedMouseOut({
      elements: [tooltipRef.current as HTMLElement],
      callback: onTooltipClose,
      delay: 1000,
    });
  };

  return (
    <GroupTooltip
      ref={tooltipRef}
      groupTooltipPosition={adjustedGroupTooltipPosition}
      onMouseOut={onMouseOut}
    >
      {groupTooltipData.labeledEventId ? (
        <div style={{ display: 'flex', alignItems: 'baseline', padding: '0 1rem' }}>
          <Icon name={ICONS.Close} width="8" height="8" style={{ display: 'block' }} />
          <StyledButton
            buttonSize="large"
            buttonType="text"
            onClick={() => clearLabel(groupTooltipData.labeledEventId as number)}
          >
            Clear Label
          </StyledButton>
        </div>
      ) : (
        <div style={{ display: 'flex', gap: '5px', padding: '5px 10px' }}>
          <AnomalyLabelsDropdown
            disabled={isNotUsefulDropdownOpen}
            type={AnomalyLabelsDropdownTypes.USEFUL}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            isDropdownOpen={isUsefulDropdownOpen}
            setIsDropdownOpen={(state) => {
              setIsNotUsefulDropdownOpen(!state);
              setIsUsefulDropdownOpen(state);
            }}
          >
            <Icon width="24" height="24" name={ICONS.ThumbUp} />
          </AnomalyLabelsDropdown>
          <AnomalyLabelsDropdown
            disabled={isUsefulDropdownOpen}
            type={AnomalyLabelsDropdownTypes.NOT_USEFUL}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            isDropdownOpen={isNotUsefulDropdownOpen}
            setIsDropdownOpen={(state) => {
              setIsNotUsefulDropdownOpen(state);
              setIsUsefulDropdownOpen(!state);
            }}
          >
            <Icon width="24" height="24" name={ICONS.ThumbDown} />
          </AnomalyLabelsDropdown>
        </div>
      )}
    </GroupTooltip>
  );
};
