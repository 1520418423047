import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useHideTag } from '../../app/hooks/tags';
import { ChartInteractionUtil } from '../../app/utils/chart-interaction';

import { useViewContext } from './view-context';

import {
  AnalyzeView,
  AnomalySort,
  HiddenTag,
  TimeRanges,
  ViewFilters,
  ViewFiltersValue,
  ViewType,
  Zoomable,
  ZoomTo,
} from '@controlrooms/models';

export const ANALYZE_MIN_ZOOM = dayjs.duration(1, 's').as('seconds');
export const chartHeightIncrements = [70, 90, 110, 130, 150, 170, 200];

interface ContextProps {
  chartHeight: number;
  chartSortKey: AnomalySort;
  hiddenTags: HiddenTag[];
  chartViewFilters: ViewFilters[];
  hideTag: (folder: number, tag: string, type: string) => void;
  unhideTagsByFolder: (folder: number) => void;
  unhideTagsByName: (folder: number, tag_name: string) => void;
  changeChartViewFilters: (currentValue: ViewFiltersValue) => void;
  setChartHeight: Dispatch<SetStateAction<number>>;
  setChartSortKey: Dispatch<SetStateAction<AnomalySort>>;
  setHiddenTags: Dispatch<SetStateAction<HiddenTag[]>>;
  setChartViewFilters: Dispatch<SetStateAction<ViewFilters[]>>;
  increaseChartHeight: () => void;
  decreaseChartHeight: () => void;
  panNext: () => void;
  panPrevious: () => void;
  zoomIn: Zoomable;
  zoomOut: () => void;
  jumpToNow: () => void;
}

const defaultState = {
  chartHeight: chartHeightIncrements[1],
  chartSortKey: AnomalySort.SEVERITY,
  hiddenTags: [],
  chartViewFilters: [
    {
      label: 'Frequent Value',
      value: ViewFiltersValue.FREQUENT_VALUE,
      checked: false,
      dataTestId: 'frequent-value',
    },
    {
      label: 'High High Value',
      value: ViewFiltersValue.HIGH_HIGH_VALUE,
      checked: true,
      dataTestId: 'high_high',
    },
    {
      label: 'High Value',
      value: ViewFiltersValue.HIGH_VALUE,
      checked: true,
      dataTestId: 'high',
    },

    {
      label: 'Low Value',
      value: ViewFiltersValue.LOW_VALUE,
      checked: true,
      dataTestId: 'low',
    },
    {
      label: 'Low Low Value',
      value: ViewFiltersValue.LOW_LOW_VALUE,
      checked: true,
      dataTestId: 'low-low',
    },
  ],
  hideTag: () => null,
  unhideTagsByFolder: () => null,
  unhideTagsByName: () => null,
  setChartHeight: () => null,
  setChartSortKey: () => null,
  setHiddenTags: () => null,
  changeChartViewFilters: () => null,
  setChartViewFilters: () => null,
  increaseChartHeight: () => null,
  decreaseChartHeight: () => null,
  panNext: () => null,
  panPrevious: () => null,
  zoomIn: () => null,
  zoomOut: () => null,
  jumpToNow: () => null,
};

export const AnalyzeChartContext = createContext<ContextProps>(defaultState);

interface AnalyzeRouteState {
  view?: AnalyzeView;
}

const AnalyzeChartContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const view = (location.state as AnalyzeRouteState)?.view;
  const { mutateAsync: snoozeTag } = useHideTag();
  const { setViewState, updateTimeSelection, viewState } = useViewContext();
  const [chartHeight, setChartHeight] = useState<number>(defaultState.chartHeight);
  const [chartSortKey, setChartSortKey] = useState<AnomalySort>(defaultState.chartSortKey);
  const [hiddenTags, setHiddenTags] = useState<HiddenTag[]>(
    view?.hiddenTags ?? defaultState.hiddenTags,
  );

  const [chartViewFilters, setChartViewFilters] = useState<ViewFilters[]>(
    defaultState.chartViewFilters,
  );

  const increaseChartHeight = useCallback(() => {
    const index = chartHeightIncrements.indexOf(chartHeight);

    if (index !== chartHeightIncrements.length - 1) {
      const newHeight = chartHeightIncrements[index + 1];
      setChartHeight(newHeight);
    }
  }, [chartHeight, setChartHeight]);

  const decreaseChartHeight = useCallback(() => {
    const index = chartHeightIncrements.indexOf(chartHeight);

    if (index !== 0) {
      const newHeight = chartHeightIncrements[index - 1];
      setChartHeight(newHeight);
    }
  }, [chartHeight, setChartHeight]);

  const zoomIn = useCallback(
    (params?: Partial<ZoomTo>) => {
      const prevTimeSelection = viewState.timeSelection;
      const [newStartTime, newEndTime] = ChartInteractionUtil.inCalculateBounds(
        prevTimeSelection,
        ANALYZE_MIN_ZOOM,
        params,
      );
      updateTimeSelection(
        {
          ...prevTimeSelection,
          startTime: newStartTime,
          endTime: newEndTime,
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        },
        true,
      );
    },
    [updateTimeSelection, viewState],
  );

  const zoomOut = useCallback(() => {
    const prevTimeSelection = viewState.timeSelection;
    const [newStartTime, newEndTime] = ChartInteractionUtil.outCalculateBounds(prevTimeSelection);
    updateTimeSelection(
      {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      },
      true,
    );
  }, [updateTimeSelection, viewState]);

  const panPrevious = useCallback(() => {
    const prevTimeSelection = viewState.timeSelection;
    const [newStartTime, newEndTime] =
      ChartInteractionUtil.panLeftCalculateBounds(prevTimeSelection);
    updateTimeSelection(
      {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      },
      true,
    );
  }, [updateTimeSelection, viewState]);

  const panNext = useCallback(() => {
    // disable pan next if in relative mode
    // if (streamingTimeInSeconds) return;

    // TODO: disabled for ux testable - needs more ticket detail
    // const newEndTime = dayjs(endTime).add(panStep, 's');

    const prevTimeSelection = viewState.timeSelection;
    const [newStartTime, newEndTime] =
      ChartInteractionUtil.panRightCalculateBounds(prevTimeSelection);
    updateTimeSelection(
      {
        ...prevTimeSelection,
        startTime: newStartTime,
        endTime: newEndTime,
        streamingTimeInSeconds: undefined,
        timeRange: TimeRanges.CUSTOM,
      },
      true,
    );
  }, [updateTimeSelection, viewState]);

  const jumpToNow = useCallback(() => {
    // disable jump to now for V2
    return;
  }, []);

  const hideTag = useCallback(
    (folder: number, tag: string, type: string) => {
      if (type === 'private') {
        snoozeTag({ folder, tag });
      }
      setViewState((prev) => {
        const currentView = prev;
        const currentHiddenTags = currentView.view[ViewType.ANALYZE].hiddenTags as HiddenTag[];
        currentView.view[ViewType.ANALYZE].hiddenTags = [
          ...currentHiddenTags,
          {
            folder,
            tag,
          },
        ];

        return { ...prev, currentView };
      });
    },
    [snoozeTag, setViewState],
  );

  const unhideTagsByFolder = useCallback(
    (folder) => {
      setViewState((prev) => {
        const currentView = prev;
        const currentHiddenTags = currentView.view[ViewType.ANALYZE].hiddenTags as HiddenTag[];
        currentView.view[ViewType.ANALYZE].hiddenTags = currentHiddenTags.filter(
          (tag) => tag.folder !== folder,
        );

        return { ...prev, currentView };
      });
    },
    [setViewState],
  );

  const unhideTagsByName = useCallback(
    (folder, tag_name) => {
      const newHiddenTagsState = hiddenTags.filter(
        (tag) => tag.folder !== folder && tag.tag !== tag_name,
      );
      setHiddenTags(newHiddenTagsState);
    },
    [hiddenTags],
  );

  const changeChartViewFilters = useCallback((currentValue) => {
    setChartViewFilters((prevState) => {
      return prevState.map((option) =>
        option.value === currentValue ? { ...option, checked: !option.checked } : option,
      );
    });
  }, []);

  const analyzeChartState = useMemo(
    () => ({
      chartHeight,
      chartSortKey,
      hiddenTags: viewState.view[ViewType.ANALYZE].hiddenTags as HiddenTag[],
      hideTag,
      unhideTagsByFolder,
      unhideTagsByName,
      chartViewFilters,
      changeChartViewFilters,
      setChartViewFilters,
      setHiddenTags,
      setChartHeight,
      setChartSortKey,
      increaseChartHeight,
      decreaseChartHeight,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      jumpToNow,
    }),
    [
      chartHeight,
      chartSortKey,
      hideTag,
      unhideTagsByFolder,
      unhideTagsByName,
      chartViewFilters,
      changeChartViewFilters,
      increaseChartHeight,
      decreaseChartHeight,
      panNext,
      panPrevious,
      zoomIn,
      zoomOut,
      jumpToNow,
      viewState,
    ],
  );

  return (
    <AnalyzeChartContext.Provider value={analyzeChartState}>
      {children}
    </AnalyzeChartContext.Provider>
  );
};

export default AnalyzeChartContextProvider;
