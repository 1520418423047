import React, { useContext } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';

import { SortActionsContainer } from './styles';

import { Select } from '@controlrooms/components';
import { AnomalySort } from '@controlrooms/models';

const options = [
  {
    value: AnomalySort.EARLIEST,
    label: 'Chronological',
    dataTestId: 'sort-chronological',
  },
  {
    value: AnomalySort.ALPHA,
    label: 'Alphabetical',
    dataTestId: 'sort-alphabetical',
  },
  {
    value: AnomalySort.SEVERITY,
    label: 'Severity',
    dataTestId: 'sort-severity',
  },
  {
    value: AnomalySort.MAX_CHANGE,
    label: 'Max change',
    dataTestId: 'sort-max-change',
  },
];

const defaultOption = options[2];

export const SortActions: React.FC = () => {
  const { setChartSortKey } = useContext(AnalyzeChartContext);
  const { track } = useAnalytics();

  return (
    <SortActionsContainer data-testid="sort-severity-button">
      <Select
        prefix="Sort:"
        options={options}
        markSelectedOption
        defaultOption={defaultOption}
        onChange={(e) => {
          track('Analyze Chart - Sort', {
            sortSelected: e.label,
          });
          setChartSortKey(e.value as AnomalySort);
        }}
      />
    </SortActionsContainer>
  );
};
