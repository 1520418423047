import classNames from 'classnames';
import React, { useState, useRef, useContext } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import AlertModal from '../../../app/components/alert-modal/alert-modal';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
  SystemViewContainer,
} from './styles/system-styles';

import { Button, Checkbox, Icon, Li, OptionsGroup, Tooltip, Ul } from '@controlrooms/components';
import { ICONS, SUB_SYSTEMS_SEARCH_PARAM, TAGS_SEARCH_PARAM } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { Checkable, Expandable, Navigable, Plant, SubSystem, System } from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

// put this in a config?
const MAX_SUBFOLDER_DEPTH = 4;

const NavIcon: React.FC<{ onClick: () => void; dataTestId: string }> = ({
  onClick = () => undefined,
  dataTestId,
}) => (
  <div className="nav-icon" onClick={onClick} data-testid={dataTestId}>
    <Icon height="6" name={ICONS.Chevron} width="11" />
  </div>
);

interface SubSystemViewProps extends Partial<SubSystem>, Checkable, Expandable, Navigable {
  depth?: number;
  isChecked?: boolean;
}

// TODO: extract shareable expand/collapse logic into reusable component
const SubSystemView: React.FC<SubSystemViewProps> = ({
  depth = 1,
  description,
  expanded: _expanded = false,
  folder: id,
  handleCheckboxCheck,
  name,
  onNav = () => undefined,
  selectedFolders,
  subfolders,
  tags,
}) => {
  const hasSubfolders = Boolean(subfolders?.length) && depth < MAX_SUBFOLDER_DEPTH;
  const hasTags = Boolean(tags?.length);
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { tenant } = useTenant();
  const [search] = useSearchParams();
  const { pathname } = useLocation();
  const isTimeSearch = matchPath(Paths.TIME_SEARCH, pathname);
  const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
  const tagNameParams = search.get(TAGS_SEARCH_PARAM);

  // state
  const [expanded, setExpanded] = useState(_expanded);

  // handlers
  const handleExpand = () => {
    track('Analyze - SubSystem View', {
      expandSubFolders: expanded,
      expandedSystemId: id,
      expandedSystemName: name,
    });
    setExpanded((prev) => !prev);
  };

  const handleNav = () => id && onNav(id);

  const isChecked = selectedFolders?.includes(id as number);

  const renderSubfolders = () => {
    if (!hasSubfolders) {
      return null;
    }

    depth++;

    return subfolders?.map((subfolder) => (
      <SubSystemView
        depth={depth}
        handleCheckboxCheck={handleCheckboxCheck}
        key={sanitizeString(`${id}-${subfolder.name}`)}
        onNav={onNav}
        selectedFolders={selectedFolders}
        {...subfolder}
      />
    ));
  };

  const subSystemViewContainerClasses = classNames({
    expanded: expanded,
    'has-tags': hasTags,
    'has-subfolders': hasSubfolders,
    'is-child': depth > 1,
  });

  const removeQueryParams = () => {
    navigate({
      pathname: generatePath(Paths.ANALYZE, {
        tenantId: tenant.toString(),
      }),
      search: '',
    });
  };

  const handleNavClick = () => {
    track('Analyze - SubSystem View', {
      navigateToFolderWithId: id,
      navigate: 'clicked',
      hasTags: true,
    });
    handleNav();
  };

  return (
    <SubSystemViewContainer className={subSystemViewContainerClasses} depth={depth}>
      <div className={`header ${isChecked ? 'checked' : ''}`}>
        <SubSystemContainer depth={depth}>
          <Checkbox
            dataTestId={`system-view-checkbox-${name}`}
            checked={Boolean(isChecked)}
            className="sub-system-check"
            onChange={() => {
              if (subSystemParams || tagNameParams) {
                removeQueryParams();
              }
              track('Analyze - SubSystem View', {
                selectedSystemId: id,
                selectedSystemName: name,
              });
              handleCheckboxCheck(id as number);
            }}
          />
          {hasSubfolders && (
            <div className="expand-icon" onClick={handleExpand}>
              <div>
                <ExpandIcon />
              </div>
            </div>
          )}
          <div
            data-testid={`system-view-${name}`}
            className="name truncate"
            onClick={() => {
              track('Analyze - SubSystem View', {
                checkedSystemId: id,
                checkedSystemName: name,
              });
              handleCheckboxCheck(id as number);
              return;
            }}
          >
            <Tooltip label={name || ''} place="top">
              {name}
            </Tooltip>
          </div>

          <div
            className="description"
            onClick={(e) => {
              e.preventDefault();
              track('Analyze - SubSystem View', {
                navigateToFolderWithId: id,
                navigate: 'clicked',
              });
              handleNav();
            }}
          >
            {description && name && <span>:&nbsp;</span>}
            {description}
          </div>
          {!isTimeSearch && (
            <>
              <SystemMenu
                handleNavClick={handleNavClick}
                targetId={id as number}
                name={name || ''}
              />
              {hasTags && (
                <NavIcon dataTestId={`navigate-to-tags-${name}`} onClick={handleNavClick} />
              )}
            </>
          )}
        </SubSystemContainer>
      </div>
      <div className="content">{renderSubfolders()}</div>
    </SubSystemViewContainer>
  );
};

type SystemSectionProps = Partial<System<SubSystem>> & Checkable & Expandable & Navigable;

export const SystemSection: React.FC<SystemSectionProps> = ({
  expanded: _expanded = true,
  folder,
  name,
  onNav = () => undefined,
  description,
  subfolders,
  handleCheckboxCheck,
  selectedFolders,
}) => {
  // state
  const [expanded, setExpanded] = useState(!subfolders?.length || _expanded);
  const { track } = useAnalytics();

  // handlers
  const handleExpand = () => subfolders?.length && setExpanded((prev) => !prev);

  return (
    <SystemSectionContainer className={expanded ? 'expanded' : ''}>
      <div
        className="section-header plant-name"
        data-testid="section-header-plant-name"
        onClick={() => {
          track('Analyze - System Section', {
            expandSection: expanded,
            expandedSectionName: name,
          });
          handleExpand();
        }}
      >
        <div className="icon-wrapper">{!!subfolders?.length && <ExpandIcon />}</div>
        {name && (
          <div className="name">
            <Tooltip label={name}>{name}</Tooltip>
          </div>
        )}
        <div className="description">
          {description && <Tooltip label={description}>{description}</Tooltip>}
          {description && name && <span>:&nbsp;</span>}
        </div>
      </div>
      <div className="section-content" data-testid="section-content">
        {subfolders?.map((subfolder) => (
          <SubSystemView
            handleCheckboxCheck={handleCheckboxCheck}
            key={sanitizeString(`${folder}-${subfolder.name}`)}
            onNav={onNav}
            selectedFolders={selectedFolders}
            {...subfolder}
          />
        ))}
      </div>
    </SystemSectionContainer>
  );
};

export interface SystemViewProps extends Checkable, Navigable {
  plant?: Plant;
}

export const SystemView: React.FC<SystemViewProps> = ({
  handleCheckboxCheck,
  onNav,
  plant,
  selectedFolders,
}) => {
  const { subfolders: systems } = plant ?? {};

  return (
    <SystemViewContainer>
      {systems?.map((system) => (
        <SystemSection
          handleCheckboxCheck={handleCheckboxCheck}
          key={system.folder}
          onNav={onNav}
          selectedFolders={selectedFolders}
          {...system}
        />
      ))}
    </SystemViewContainer>
  );
};

const SystemMenu = ({
  handleNavClick,
  targetId,
  name,
}: {
  handleNavClick: () => void;
  targetId: number;
  name: string;
}) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const { canUserReadAlert } = useContext(AuthorizationContext);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup className="menu-select">
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`system_view_menu_${name}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul isOpen={isMenuSelectOpen} position="absolute" className="dropdown" ref={ulRef}>
        <Li data-testid="view-tags" key="view-in-analyze">
          <div onClick={handleNavClick}>View Tags</div>
        </Li>
        {canUserReadAlert && (
          <Li data-testid={`${name}-manage-alert`} key="manage-alert">
            <AlertModal targetType="system" targetId={targetId}>
              <div>Manage Alerts</div>
            </AlertModal>
          </Li>
        )}
      </Ul>
    </OptionsGroup>
  );
};
