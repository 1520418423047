import { Virtuoso } from 'react-virtuoso';
import styled, { css } from 'styled-components';

import { customScrollPrimary, customScrollSecondary } from '../../../app/global-styles';

import { ICONS } from '@controlrooms/constants';

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 71px);
  background: ${({ theme }) => theme.timeSearch.sidebar.background};
  .navHeader {
    background: ${({ theme }) => theme.timeSearch.sidebar.navHeader.background};
    box-shadow: ${({ theme }) => theme.timeSearch.sidebar.navHeader.boxShadow};
  }
  .backBtn {
    text-align: left;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    justify-content: flex-start;
    margin: 0;
    margin-top: 10px;
    color: ${({ theme }) => theme.timeSearch.sidebar.navHeader.goBack};
  }

  .module-title {
    color: ${({ theme }) => theme.timeSearch.sidebar.navHeader.title};
    font-family: Space Grotesk;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin-top: 5px;
    padding: 10px;
    padding-top: 0;
    margin-bottom: 0px;
  }
`;

export const ResultSection = styled.section`
  padding: 10px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex: 2;
  h3 {
    color: ${({ theme }) => theme.timeSearch.sidebar.navHeader.goBack};
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0px;
  }
`;

export const SearchTagElement = styled.div<{ isSelected: boolean }>`
  border-radius: 6px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: pointer;
  .system {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.systemColor};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  .tagDetails {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .tagName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.tagBg};
      color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.tagName};
      border-radius: 4px;
      padding: 1px 5px;
      text-align: center;
    }
    .tagDesc {
      flex: 2;
      color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.tagDesc};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 1px 5px;
    }
  }
  .unitDetails {
    display: flex;
    padding: 5px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    margin-top: 5px;
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.timeSearch.sidebar.referenceTag.borderColor};
    background: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.background};
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    .left-section {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .date-time {
      color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.dateTimeColor};
      border-radius: 4px;
      background: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.dateTimeBg};
      display: inherit;
      font-weight: 600;
      padding: 1px 5px 1px 6px;
      gap: 5px;
    }
    .range {
      display: flex;
      gap: 5px;
    }
  }
`;

export const ResultsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .find-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 10px 0;
    div {
      color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.tagDesc};
      /* CR/11 Data */
      font-family: 'Open Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      max-width: 50%;
    }
  }
`;

export const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.timeSearch.sidebar.referenceTag.tagDesc};
  /* CR/11 Data */
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  .spinner {
    cursor: default;
    svg {
      path {
        fill: ${({ theme }) => theme.timeSearch.sidebar.spinnerColor};
      }
    }
    div[name=${ICONS.Spinner}] {
      display: block;
    }
    div[name=${ICONS.Close}] {
      display: none;
      margin-left: 5px;
      cursor: pointer;
    }
    :hover {
      div[name=${ICONS.Spinner}] {
        display: none;
      }
      div[name=${ICONS.Close}] {
        display: block;
      }
    }
  }
  .filter-section {
    display: flex;
    align-items: center;
    gap: 4px;
    .filter {
      display: flex;
      flex: 1;
      button {
        font-size: 11px;
        width: auto;
      }
    }
    ul[role='listbox'],
    ul {
      width: 150px;
      li {
        padding-right: 8px;
        svg {
          fill: ${({ theme }) => theme.modal.navigation.activeColor};
        }
      }
    }
    .custom-timeframe {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
export const ResultContainer = styled.div<{ isDefaultTab: boolean }>`
  ${customScrollSecondary};
  max-height: calc(100vh - ${(props) => (props.isDefaultTab ? '385px' : '305px')});
  overflow: auto;
  padding: 2px;
  display: flex;
  flex-direction: column;
`;

export const StyledVirtuoso = styled(Virtuoso)`
  ${customScrollPrimary}
`;

export const ChartsContainerWrapper = styled.div`
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: column;
  width: 100%;
  .svg-content-responsive {
    background: ${({ theme }) => theme.timeSearch.viewer.background};
  }
  .chart-area {
    fill: ${({ theme }) => theme.timeSearch.viewer.chart};
    stroke: #ddd;
    border-radius: 5px;
  }
`;
export const DatePlaceholder = styled.div`
  background: ${({ theme }) => theme.timeSearch.viewer.background};
  text-align: center;
  padding: 20px;
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Tz = styled.span`
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  margin-left: 2px;
`;

export const EventCardContainer = styled.div<{ isSelected: boolean; isCurrentEvent: boolean }>`
  display: flex;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  gap: 4px;
  margin: 4px 2px;
  background: ${({ theme }) => theme.timeSearch.sidebar.eventCard.background};
  border: ${(props) =>
    props.isCurrentEvent
      ? ({ theme }) => '2px solid' + theme.timeSearch.sidebar.referenceTag.borderColor
      : ''};

  .info-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 270px;
    flex-grow: 1;
    .date-time {
      padding: 1px 5px 1px 6px;
      border-radius: 4px;
      background: ${({ theme }) => theme.timeSearch.sidebar.eventCard.dateTimeBg};
      color: ${({ theme }) => theme.timeSearch.sidebar.eventCard.dateTimeColor};
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .data-range {
      display: flex;
      gap: 5px;
      .min,
      .max {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 50px;
      }
    }
  }
  :hover {
    box-shadow: 0px 0px 2px 0px #fff, 0px 0px 8px 0px #80f7ff;
  }
  ${(props) =>
    props.isSelected &&
    css`
      background: ${({ theme }) => theme.timeSearch.sidebar.eventCard.selected.background};
      .info-section {
        .date-time {
          background: ${({ theme }) => theme.timeSearch.sidebar.eventCard.selected.dateTimeBg};
        }
      }
    `}
`;

export const EventTabWrapper = styled.div`
  background-color: transparent;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap;
  display: flex;
  gap: 3px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .tab-placeholder {
    border-bottom: 2px solid ${({ theme }) => theme.timeSearch.viewer.tab.borderColor};
    flex: 1;
  }
  .tab {
    width: 100px;
    padding: 10px;
  }
`;

export const EventTabContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  text-align: center;
  padding: 15px;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-bottom: 4px solid ${({ theme }) => theme.timeSearch.viewer.tab.borderColor};
  width: auto;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 0.8;
  }
  .info {
    color: ${({ theme }) => theme.timeSearch.viewer.tab.color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    padding-right: 10px;
    min-width: 75px;
  }
  ${(props) =>
    props.isSelected &&
    css`
      border-bottom: 4px solid ${({ theme }) => theme.timeSearch.viewer.tab.selectedBorderColor};
      opacity: 0.8;
      .info {
        color: ${({ theme }) => theme.timeSearch.viewer.tab.selectedTextColor};
        font-weight: 600;
      }
    `}
`;
