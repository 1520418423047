import { Route, Routes } from 'react-router-dom';

import { ShareV2 } from '../../../app/pages/routes';
import { LayoutProvider } from '../../context/layout-context';
import Sidebar from '../sidebar';

import PanelLayout from './PanelLayout';

export const LayoutV2: React.FC = () => {
  return (
    <>
      <LayoutProvider>
        <Routes>
          <Route
            index
            element={
              <>
                <Sidebar />
                <PanelLayout />
              </>
            }
          />
          <Route path={'/:hash'} element={<ShareV2 />} />
        </Routes>
      </LayoutProvider>
    </>
  );
};
