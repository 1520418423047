import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useAnalytics } from '../../../app/analytics';
import AlertModal from '../../../app/components/alert-modal/alert-modal';
import { analyzeConfig } from '../../../app/constants/page-configs';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTagsByName } from '../../../app/hooks/folders';
import { TimeSearch } from '../../../app/pages/routes';
import { TimeFrameValue } from '../../../app/pages/time-search/search-timerange-dropdown';
import { differenceInMinutes } from '../../../app/utils/time';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';
import { useTenants } from '../../hooks/tenants';

import { appendGradients } from './gradients';
import {
  StyledActionsWrapper,
  StyledChart,
  StyledTagDesc,
  StyledTagHeader,
  StyledTagTitle,
  StyledTagUOM,
} from './styles';
import { InfoTooltip } from './utils/tooltip-components';
import {
  addAnalyzeTooltips,
  handleTooltipMouseMove,
  handleTooltipMouseOut,
  handleTooltipMouseOver,
  resetLimitLines,
} from './utils/tooltips';

import { Button, Icon, Li, OptionsGroup, Tooltip, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside, useKeyPress, useThrottle } from '@controlrooms/hooks';
import {
  AnalyzeChartProps,
  SVGDefsSelection,
  ModeType,
  ViewFiltersValue,
  Mode,
  TimeSeries,
  ParentTag,
  DisplayLimitData,
  TimeSelection,
  ViewType,
} from '@controlrooms/models';
import {
  canApplyChartFilter,
  yAccessorExtentMaxWithLimit,
  yAccessorExtentMinWithLimit,
  yScaleDomain,
  buildMinMaxArea,
  buildMode,
  buildSpline,
  buildAnomalyLines,
  buildRangeAnomalyLimitLines,
  buildLimitArea,
  buildStepLine,
  buildStringLine,
  cleanTagName,
} from '@controlrooms/utils';

dayjs.extend(utc);

const ZOOM_MODIFIERS = ['z'];

const TREND_SEARCH_LIMIT_HR = 10;

export const AnalyzeChart: React.FC<AnalyzeChartProps> = ({
  seriesData,
  modes,
  limits,
  limitsArray,
  folder,
  tag,
  anomalyData,
  pinned,
  isLoading,
  isError,
  isIOWtag, //TODO: IOW-HIDE - Remove after making it generic,
}) => {
  const { data: tagsByName } = useTagsByName();
  const { currentTenant } = useTenants();
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const tagObj = tagsByName[tag];
  const [inZoomModifier, setInZoomModifier] = useState(false);
  const [pinnedHighlighter, setPinnedHighlighter] = useState(false);
  const zoomModifierRef = useRef(inZoomModifier);
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isLinearChart = tagObj?.is_continuous && tagObj?.is_numeric;
  const isDiscreteChart =
    tagObj?.is_continuous !== null && tagObj?.is_continuous === false && tagObj?.is_numeric;
  const isStringValuesChart =
    tagObj?.is_continuous !== null &&
    tagObj?.is_continuous === false &&
    tagObj?.is_numeric !== null &&
    tagObj?.is_numeric === false;

  useEffect(() => {
    zoomModifierRef.current = inZoomModifier;
  }, [inZoomModifier]);
  const { pathname } = useLocation();
  const { timeSearchTimeSelection } = useContext(TimeSearchContext);
  const { viewState, setViewState, searchMode, setTrendSearchData, getQueryKeyWithViewId, viewId } =
    useViewContext();
  const isTimeSearch = matchPath(Paths.TIME_SEARCH, pathname);
  const analyzeTimeSelection: TimeSelection = (
    matchPath(Paths.TIME_SEARCH, pathname) ? timeSearchTimeSelection : viewState.timeSelection
  ) as TimeSelection;
  const { pinnedTags, setSearchTimeRangeData } = useContext(AnalyzeContext);
  const {
    chartHeight: dynamicChartHeight,
    chartViewFilters,
    hideTag,
    zoomIn,
    zoomOut,
  } = useContext(AnalyzeChartContext);
  const svgRef = useRef(null);
  const timezone = viewState.timeSelection?.timezone;

  const theme = useTheme();

  useKeyPress(
    ZOOM_MODIFIERS,
    () => setInZoomModifier(true),
    () => setInZoomModifier(false),
  );

  const throttledZoomIn = useThrottle(zoomIn, 1000);
  const throttledZoomOut = useThrottle(zoomOut, 1000);
  const displayFreqLine = canApplyChartFilter(chartViewFilters, ViewFiltersValue.FREQUENT_VALUE);
  const displayHighLine = canApplyChartFilter(chartViewFilters, ViewFiltersValue.HIGH_VALUE);
  const displayHighHighLine = canApplyChartFilter(
    chartViewFilters,
    ViewFiltersValue.HIGH_HIGH_VALUE,
  );
  const displayLowLine = canApplyChartFilter(chartViewFilters, ViewFiltersValue.LOW_VALUE);
  const displayLowLowLine = canApplyChartFilter(chartViewFilters, ViewFiltersValue.LOW_LOW_VALUE);
  const hideFreqValueOnHover = isDiscreteChart || isStringValuesChart;
  const timeRangeInMinutes = differenceInMinutes(
    analyzeTimeSelection?.startTime,
    analyzeTimeSelection?.endTime,
  );

  useEffect(() => {
    const ChartWrapper = d3.select(`.chart-wrapper-${viewId}[data-tag="${tag}"]`);
    // get the width of the svg parent div for responsive
    const containerWidth = (ChartWrapper.node() as HTMLElement)?.getBoundingClientRect().width;

    const defaultEndTime = dayjs().isBefore(analyzeTimeSelection?.endTime)
      ? dayjs()
      : analyzeTimeSelection?.endTime;

    const { chartMargin, stepLineMarginTop } = analyzeConfig;

    let limitArrayData: Mode[] = [];
    const displayLimitData = {} as DisplayLimitData;
    if (displayHighLine) {
      limitArrayData = [...limitArrayData, ...limits.highs];
      displayLimitData.displayHighLine = displayHighLine;
    }
    if (displayHighHighLine) {
      limitArrayData = [...limitArrayData, ...limits.highHighs];
      displayLimitData.displayHighHighLine = displayHighHighLine;
    }
    if (displayLowLine) {
      limitArrayData = [...limitArrayData, ...limits.lows];
      displayLimitData.displayLowLine = displayLowLine;
    }
    if (displayLowLowLine) {
      limitArrayData = [...limitArrayData, ...limits.lowLows];
      displayLimitData.displayLowLowLine = displayLowLowLine;
    }

    // define the width of the chart as it is smaller and slightly offset
    const chartWidth = containerWidth - chartMargin.left - chartMargin.right;

    // define height of chart as it is set in the context and changes
    const chartHeight = dynamicChartHeight - chartMargin.top - chartMargin.bottom;

    const svgEl = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${containerWidth} ${dynamicChartHeight}`);

    svgEl.selectAll('*').remove(); // Clear svg content before adding new elements

    // // Add gradients
    const svgDefs = svgEl.append('defs') as unknown as SVGDefsSelection;
    appendGradients(svgDefs, theme);

    const chart = svgEl
      .append('g')
      .classed('chart', true)
      .attr('width', chartWidth)
      .attr('height', dynamicChartHeight)
      .attr(
        'transform',
        `translate(${chartMargin.left}, ${chartMargin.top})`,
      ) as unknown as SVGDefsSelection;

    // Append the loading state inside the chart so the height remains the same
    if (isLoading || isError || !seriesData.length) {
      const selection = chart
        .append('g')
        .append('text')
        .attr('x', 30)
        .attr('y', 30)
        .style('fill', theme.chart.loadingTextColor)
        .style('font-size', '12px');

      if (isLoading || isError) {
        selection.text(isLoading ? 'Loading chart...' : 'Uh oh...').raise();
      } else {
        selection.raise();
      }

      return;
    }

    // remove all tooltips
    ChartWrapper.selectAll(`.tooltip-content-${viewId}`).remove();

    const uom = tagsByName[tag]?.uom;

    const tooltipData = seriesData.map((data: TimeSeries) => ({
      ...data,
      frequent: modes?.[0]?.value,
      uom,
    }));

    // add a tooltip for each chart in the data
    const tooltips = ChartWrapper.append('div')
      .data([{ tooltipData, limitArrayData: limitArrayData }])
      .attr('class', `tooltip-content tooltip-content-${viewId}`)
      .attr('id', `tooltip-${viewId}-${folder}-${tag}`);

    // add spans for value and min max
    tooltips.append('span').classed('value', true);
    tooltips.append('span').classed('minMax', true);

    const xScale = d3
      .scaleUtc()
      .domain([analyzeTimeSelection.startTime.valueOf(), analyzeTimeSelection.endTime.valueOf()])
      .range([0, chartWidth]);

    const yScale = d3
      .scaleLinear()
      .domain(yScaleDomain(tooltipData, limitArrayData, displayFreqLine))
      .range([chartHeight, isDiscreteChart ? stepLineMarginTop : 0]);

    const minValue = yAccessorExtentMinWithLimit(tooltipData, limitArrayData, displayFreqLine);
    const maxValue = yAccessorExtentMaxWithLimit(tooltipData, limitArrayData, displayFreqLine);
    const diff = maxValue - minValue;
    const middleTick = diff / 2 + minValue;

    const yAxisNoTicks = d3.axisLeft(yScale).tickValues([]).tickSize(0);

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues([minValue, middleTick, maxValue])
      .tickFormat((d) => {
        if (
          (maxValue === minValue && minValue === middleTick) ||
          ((d as number) > -1 && (d as number) < 1 && d !== 0)
        )
          return parseFloat(Number(d).toPrecision(6)).toFixed(5);

        return parseFloat(Number(d).toPrecision(6)).toFixed(2);
      })
      .tickSize(0);

    // Returns path data for a rectangle with rounded right corners.
    // The top-left corner is ⟨x,y⟩.
    function rightRoundedRect(x: number, y: number, width: number, height: number, radius: number) {
      return (
        'M' +
        x +
        ',' +
        y +
        'h' +
        (width - radius) +
        'a' +
        radius +
        ',' +
        radius +
        ' 0 0 1 ' +
        radius +
        ',' +
        radius +
        'v' +
        (height - 2 * radius) +
        'a' +
        radius +
        ',' +
        radius +
        ' 0 0 1 ' +
        -radius +
        ',' +
        radius +
        'h' +
        (radius - width) +
        'z'
      );
    }

    // add y axis
    chart
      .append('g')
      .call(isDiscreteChart || isStringValuesChart ? yAxisNoTicks : yAxis)
      .call((g) => g.select('.domain').attr('stroke', 'none')) // remove line from axis
      .classed('yaxis', true)
      .append('path')
      .attr('width', chartWidth)
      .attr('height', isStringValuesChart ? 30 : chartHeight)
      .attr('class', 'chart-background')
      .attr('d', function () {
        return rightRoundedRect(1, 0, chartWidth, isStringValuesChart ? 30 : chartHeight, 6);
      });

    // add clipping so everything out of this area won't be drawn
    svgDefs
      .append('clipPath')
      .attr('id', `clip-${viewId}`)
      .append('rect')
      .attr('width', chartWidth)
      .attr('height', chartHeight)
      .attr('x', 0)
      .attr('y', 0);

    // add brushing
    const brush = d3
      .brushX() // Add the brush feature using the d3.brush function
      .extent([
        [0, 0],
        [chartWidth, chartHeight],
      ])
      .on('brush', (e: { selection: number[] }) => {
        if (searchMode) {
          const extent = e?.selection;
          const left = dayjs(xScale.invert(extent[0]));
          const right = dayjs(xScale.invert(extent[1]));
          if (right.diff(left, 'hours') > TREND_SEARCH_LIMIT_HR) {
            const endLimit = xScale(left.add(TREND_SEARCH_LIMIT_HR, 'hours'));
            ChartWrapper.selectAll('.selection').style('width', endLimit - extent[0]);
          } else {
            ChartWrapper.selectAll('.selection').style('width', Math.abs(extent[0] - extent[1]));
          }
        }
      })
      .on('end', (e: { selection: number[] }) => {
        const extent = e?.selection;
        if (!extent || extent.length < 2) return;
        const left = dayjs(xScale.invert(extent[0]));
        let right = dayjs(xScale.invert(extent[1]));
        if (searchMode) {
          track('Analyze Chart - Trend search Drag and Zoom', {
            startTime: left.toISOString(),
            endTime: right.toISOString(),
            tag: tagObj,
            folder,
          });
          if (right.diff(left, 'hours') > TREND_SEARCH_LIMIT_HR) {
            right = left.add(TREND_SEARCH_LIMIT_HR, 'hours');
          }
          (async () => {
            await queryClient.invalidateQueries(getQueryKeyWithViewId('TIMESEARCH'));
            TimeSearch.preload();
            setTrendSearchData({
              startTime: left.toISOString(),
              endTime: right.toISOString(),
              tag: tagObj,
              folder,
              timeRange: TimeFrameValue.PREVIOUS_6_MONTH,
              tagName: tagObj.name,
            });
            return;
          })();
        } else {
          track('Analyze Chart - Drag and Zoom', {
            startTime: left.toISOString(),
            endTime: right.toISOString(),
            tag: tagObj,
            folder,
          });
          zoomIn({ left, right });
        }
      });

    const clippedArea = chart
      .append('g')
      .attr('clip-path', `url(#clip-${viewId})`) as unknown as SVGDefsSelection;

    // define and build min max area
    buildMinMaxArea(clippedArea, xScale, yScale, tooltipData, theme, isDiscreteChart);

    //define and build red shaded limit area
    buildLimitArea(
      clippedArea,
      xScale,
      yScale,
      tooltipData,
      theme,
      limits,
      chartHeight,
      yScaleDomain(tooltipData, limitArrayData, displayFreqLine),
      displayLimitData,
      modes,
    );

    // define and build mode
    if (displayFreqLine) buildMode(clippedArea, xScale, yScale, modes, theme, ModeType.FREQUENT);

    // define and build high limit line
    if (displayHighLine) {
      // buildLimitIndicator(chart, xScale, yScale, limits.highs, theme, ModeType.HLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.highs, theme, ModeType.HLIMIT, tag);
    }

    // define and build high high limit line
    if (displayHighHighLine) {
      // buildLimitIndicator(chart, xScale, yScale, limits.highHighs, theme, ModeType.HHLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.highHighs, theme, ModeType.HHLIMIT, tag);
    }

    // define and build low limit line
    if (displayLowLine) {
      // buildLimitIndicator(chart, xScale, yScale, limits.lows, theme, ModeType.LLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.lows, theme, ModeType.LLIMIT, tag);
    }

    // define and build low low limit line
    if (displayLowLowLine) {
      // buildLimitIndicator(chart, xScale, yScale, limits.lowLows, theme, ModeType.LLLIMIT, 5);
      buildMode(clippedArea, xScale, yScale, limits.lowLows, theme, ModeType.LLLIMIT, tag);
    }

    // define and build line based on chart type
    if (isLinearChart) buildSpline(clippedArea, xScale, yScale, tooltipData, theme);

    if (isDiscreteChart)
      buildStepLine(clippedArea, xScale, yScale, tooltipData, theme, defaultEndTime);

    if (isStringValuesChart)
      buildStringLine(clippedArea, xScale, yScale, seriesData, theme, defaultEndTime);

    // check for normal chart or discrete
    if (isLinearChart || isDiscreteChart) {
      // add anomaly lines
      buildAnomalyLines(
        clippedArea,
        xScale,
        yScale,
        anomalyData,
        theme,
        false,
        containerWidth / timeRangeInMinutes,
      );
      // add range limits
      buildRangeAnomalyLimitLines(
        clippedArea,
        xScale,
        yScale,
        tooltipData,
        limits,
        displayLimitData,
        chartHeight,
        theme,
      );
    }

    // add tooltip for chart
    addAnalyzeTooltips(
      clippedArea,
      analyzeConfig,
      theme,
      tooltipData,
      chartHeight,
      folder,
      tag,
      displayFreqLine,
      limitArrayData,
    );

    // Add the brushing
    const brushContext = clippedArea.append('g').attr('class', 'brush').call(brush);

    // Re-use the brush created rect for tooltip events
    brushContext
      .select('.overlay')
      .on('dblclick', (e: MouseEvent) => {
        const center = dayjs(xScale.invert(e.offsetX - chartMargin.left));
        zoomIn({ center });
      })
      .on('touchstart', function (event) {
        if (event?.touches?.length === 1) {
          // Single-finger touch, disable brush
          event.stopPropagation();
        }
      })
      .on(
        'wheel',
        (e: WheelEvent) => {
          if (!zoomModifierRef.current) return;
          e.stopPropagation();
          e.preventDefault();

          if (e.deltaY < 0) {
            throttledZoomIn();
          } else if (e.deltaY > 0) {
            throttledZoomOut();
          }
        },
        {
          passive: true,
        },
      )
      .on(
        'mouseout',
        () => {
          handleTooltipMouseOut('analyze');
          resetLimitLines(cleanTagName(tag), theme);
        },
        // on mouse out hide line, circles and text
      )
      .on('mouseover', (e: MouseEvent) => {
        // on mouse in show line, circles and text
        handleTooltipMouseOver('analyze', { folder, tag, viewId });
        handleTooltipMouseMove(
          'analyze',
          e,
          xScale,
          timezone,
          theme,
          {
            displayFreqLine,
            chartWidth,
            viewId,
          },
          chart,
          clippedArea,
          // figure out to find the mouse position when we only have a few data points.
        );
      })
      .on('touchmouse mousemove', (e: MouseEvent) => {
        handleTooltipMouseMove(
          'analyze',
          e,
          xScale,
          timezone,
          theme,
          {
            viewId,
            displayFreqLine,
            chartWidth,
            hideFreqValueOnHover,
            isStringValuesChart,
          },
          chart,
          clippedArea,
          // figure out to find the mouse position when we only have a few data points.
        );
      })
      .classed(`analyze-chart-rect-${viewId}`, true)
      .raise();
  }, [
    modes,
    limits,
    limitsArray,
    seriesData,
    tag,
    dynamicChartHeight,
    isLoading,
    isError,
    theme,
    tagsByName,
    anomalyData,
    timezone,
    analyzeTimeSelection.startTime,
    analyzeTimeSelection.endTime,
    folder,
    zoomIn,
    throttledZoomIn,
    throttledZoomOut,
    displayFreqLine,
    displayHighLine,
    displayHighHighLine,
    displayLowLine,
    displayLowLowLine,
    timeRangeInMinutes,
    searchMode,
    navigate,
    currentTenant?.id,
    tagObj,
    queryClient,
    isDiscreteChart,
    isLinearChart,
    isStringValuesChart,
    hideFreqValueOnHover,
    setSearchTimeRangeData,
    track,
    setTrendSearchData,
    getQueryKeyWithViewId,
    viewId,
  ]); // Redraw chart if data or height changes

  const handleUnpinTag = () => {
    const newArr = pinnedTags.filter((t) => !(t.name === tag && t.folder === folder));
    track('Analyze Chart - Unpin tag', {
      unpinTagName: tag,
      currentPinnedTags: [...newArr],
    });
    return setViewState((prev) => {
      const currentView = prev;
      currentView.view[ViewType.ANALYZE].pinnedTags = currentView.view[
        ViewType.ANALYZE
      ]?.pinnedTags?.filter((t) => !(t.name === tag && t.folder === folder));
      return { ...prev, currentView };
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => setPinnedHighlighter(false), 5000);
    return () => clearTimeout(timeout);
  }, [pinnedHighlighter]);

  const handlePinTag = () => {
    setPinnedHighlighter(true);

    const toPin = new ParentTag(tagObj, folder);
    track('Analyze Chart - Pin Tag', {
      pinnedTag: { ...toPin },
    });
    setViewState((prev) => {
      const currentView = prev;
      const currentPinnedTags = currentView.view[ViewType.ANALYZE].pinnedTags as ParentTag[];
      currentView.view[ViewType.ANALYZE].pinnedTags = [toPin, ...currentPinnedTags]?.sort(
        (a, b) => a.folder - b.folder || a.name.localeCompare(b.name),
      );

      return { ...prev, currentView };
    });
  };

  const handleHideTag = useCallback(() => {
    track('Analyze Chart - Hide Tag', {
      hiddenFolder: folder,
      hiddenTag: tag,
    });
    //TODO: IOW-HIDE - Remove after making it generic
    hideTag(folder, tag, isIOWtag ? 'private' : 'temp');
  }, [isIOWtag, folder, tag, hideTag, track]);

  return (
    <StyledChart
      data-testid={`analyze-chart-${tag}`}
      className={`chart-wrapper chart-wrapper-${viewId}`}
      data-tag={tag}
    >
      <StyledTagHeader data-testid="tag-header" pinned={pinned}>
        <div data-testid={`tag-header-content-${tag}`} className="tag-header-content">
          {!isTimeSearch && (
            <button onClick={pinned ? handleUnpinTag : handlePinTag} className="pin-tag">
              <Tooltip label="Pin This Tag" offset="{'left': 5}">
                <Icon
                  data-testid={`pin-tag-${tag}`}
                  name={ICONS.Pin}
                  width="20px"
                  className={pinnedHighlighter ? `pin-icon-highlighted` : `pin-icon`}
                />
              </Tooltip>
            </button>
          )}
          <StyledTagTitle data-testid="tag-name">{tag}</StyledTagTitle>
          {tagObj && (
            <>
              <StyledTagDesc data-testid="tag-description">{tagObj.description}</StyledTagDesc>
              <StyledTagUOM data-testid="tag-uom">{tagObj.uom}</StyledTagUOM>
              {!isTimeSearch && (
                <StyledActionsWrapper>
                  {canUserReadAlert && <TagMenu targetId={tag} />}
                  {!pinned && (
                    <Tooltip label="Hide Tag">
                      <button
                        data-testid={`hide-tag-${tag}`}
                        onClick={handleHideTag}
                        className="hide-tag"
                      >
                        <Icon
                          name={ICONS.ClosedEye}
                          height="14px"
                          width="14px"
                          className="closed-eye-icon"
                        />
                      </button>
                    </Tooltip>
                  )}
                </StyledActionsWrapper>
              )}
            </>
          )}
        </div>
      </StyledTagHeader>
      {(isDiscreteChart || isStringValuesChart) && (
        <div style={{ position: 'absolute', top: isStringValuesChart ? 42 : 65, left: 25 }}>
          <Tooltip
            type="secondary"
            label={<InfoTooltip chartData={seriesData} chartUom={tagObj?.uom} />}
          >
            <Icon width={'12'} name={ICONS.Info} />
          </Tooltip>
        </div>
      )}
      <svg ref={svgRef} />
    </StyledChart>
  );
};

const TagMenu = ({ targetId }: { targetId: string }) => {
  // const tagName = targetId.replaceAll(/[^a-zA-Z0-9]/g, '-');
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup className="menu-select">
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`tag_menu_${targetId}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul isOpen={isMenuSelectOpen} position="absolute" className="dropdown" ref={ulRef}>
        <Li data-testid={`${targetId}-tag-manage-alert`} key="manage-alert">
          <AlertModal targetType="tag" targetId={targetId}>
            <div>Manage Alerts</div>
          </AlertModal>
        </Li>
        {/* <Li data-testid="more-actions" key="more-actions">
          <span>More Actions</span>
        </Li> */}
      </Ul>
    </OptionsGroup>
  );
};
