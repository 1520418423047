import styled from 'styled-components';

import { Button } from '@controlrooms/components';

// Styled component for the sidebar
export const StyledSidebar = styled.div`
  height: 100vh;
  width: 50px;
  background: ${({ theme }) => theme.v2.header.background};
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 8px 12px;
  -webkit-box-pack: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
`;

// Styled component for the logo and footer
export const Logo = styled.div`
  color: white;
  font-size: 24px;
  background: rgba(128, 247, 255, 0.22);
  padding: 15px 8px 10px 8px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  height: 80px;
  display: flex;
`;

export const Profile = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(
    --Heatmap-Dark-01-Gradient,
    linear-gradient(0deg, #202c2e 0%, #273133 23.18%, #303d40 100%)
  );
  margin: 5px 0px;
  padding: 10px;
`;

export const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

// Styled component for the menu
export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Styled component for the menu items
export const MenuItem = styled.div`
  color: white;
  padding: 15px 0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-right: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 1, 1, 0.5); // Adjust hover color as needed
    border-right: 2px solid #c1eff9;
  }
  &.active {
    border-right: 2px solid #c1eff9;
  }
`;

// Styled component for the icon
export const Icon = styled.div`
  font-size: 24px; // Adjust the size as needed
`;

// Optional: styled component for the tooltip text
export const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 110%;
`;

// Modified MenuItem to include tooltip text
export const MenuItemWithTooltip = styled(MenuItem)`
  position: relative;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;
