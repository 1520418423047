import { useContext } from 'react';
import Highlighter from 'react-highlight-words';

import { useAnalytics } from '../../../app/analytics';
import { FolderSort, useFilteredFolders, usePlantFolders } from '../../../app/hooks/folders';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';
import { SidebarFooter } from '../../pages/monitor/styles';

import { SystemSectionContainer } from './styles/system-styles';
import { SubSystemTagView } from './tag-view';

import { SubSystem, Tag } from '@controlrooms/models';
import { ParentTag } from '@controlrooms/models';
import { Checkable, Navigable } from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

export const SearchView: React.FC<Checkable & Navigable> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm, setSearchInputValue, setSearchTermOnNavigate } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);

  const { data: plant } = usePlantFolders(FolderSort.DEFAULT);

  const data = useFilteredFolders(plant?.subfolders || []);

  const { track } = useAnalytics();

  const renderTag = (subfolder: SubSystem & { parentPath?: string }) => {
    let aggregateView: JSX.Element = <></>;
    const view = (viewSf: SubSystem & { parentPath?: string }) => (
      <SubSystemTagView
        {...viewSf}
        tags={viewSf.tags as Tag[] & ParentTag[]}
        key={sanitizeString(`${subfolder.folder}-${viewSf.name}`)}
        handleCheckboxCheck={handleCheckboxCheck}
        parentFolder={0}
        parentPath={viewSf.parentPath as string}
        expanded={true}
        pinnedTags={pinnedTags}
        selectedFolders={selectedFolders}
        onNav={onNav}
      ></SubSystemTagView>
    );

    aggregateView = view(subfolder);

    aggregateView = (
      <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
        {aggregateView}
        {subfolder.subfolders.map((sf2: SubSystem) => renderTag(sf2))}
      </div>
    );

    return aggregateView;
  };

  return (
    <div className="search-view">
      {data?.map((system) => (
        <SystemSectionContainer className="expanded" key={`${system.folder}:${system.description}`}>
          <div className="section-header" data-testid="section-header">
            <Highlighter
              searchWords={[searchTerm]}
              textToHighlight={system.name}
              autoEscape={true}
            ></Highlighter>
            {system.description && system.name && <span>:&nbsp;</span>}
            {system.description && (
              <div className="description">
                <Highlighter
                  searchWords={[searchTerm]}
                  textToHighlight={system.description}
                  autoEscape={true}
                ></Highlighter>
              </div>
            )}
          </div>
          <div className="section-content" data-testid="section-content">
            <div>
              {system.subfolders?.map((subfolder) => {
                return (
                  <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
                    {renderTag(subfolder)}
                  </div>
                );
              })}
            </div>
          </div>
        </SystemSectionContainer>
      ))}

      <SidebarFooter>
        <div className="gta-wrapper">
          <button
            className="link"
            onClick={() => {
              track('Analyze - Search', {
                clearSearch: 'clicked',
              });
              setSearchInputValue('');
              setSearchTermOnNavigate('');
            }}
          >
            <span>Close</span>
          </button>
        </div>
      </SidebarFooter>
    </div>
  );
};
