import { motion } from 'framer-motion';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { FOLDER } from '../../../app/constants/folders';
import { useAnomalousTags, useSubSystemsById } from '../../../app/hooks/folders';
import { useTagMinMax, useTagMinMaxString } from '../../../app/hooks/tags';
import { AnalyzeChartActions } from '../../components/analyze-chart-actions/analyze-chart-actions';
import { AnalyzeChart } from '../../components/analyze-charts/analyze-chart';
import { ChartHeader } from '../../components/chart-header/chart-header';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';

import { Payload } from './analyze';
import { NoDataSelected } from './empty-analyze-view';
import { FolderHeader } from './folder-header';
import { ChartsContainer, ChartsContainerWrapper } from './styled';

import { SUB_SYSTEMS_SEARCH_PARAM, TAGS_SEARCH_PARAM } from '@controlrooms/constants';
import { Tag } from '@controlrooms/models';
import { NumberUtils } from '@controlrooms/utils';

const AnimationContext = React.createContext({
  animationEnabled: true,
});

// This uses the context to set animate back to true when scrolling ends, because
// framer-motion will only animate changes if animate was already set before the change
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnimatedContainer = (props: any) => {
  const { animationEnabled } = useContext(AnimationContext);
  return <motion.div {...props} animate={animationEnabled ? true : undefined} />;
};

const getStringTagNames = (allTags: Tag[]) => {
  if (allTags)
    return allTags?.filter((tag: Tag) => tag.is_numeric === false).map((tag: Tag) => tag.name);
  else return [];
};

export const ChartGroup: React.FC<{ payload: Payload }> = ({ payload }) => {
  const virtuoso = useRef(null);
  const { viewId } = useViewContext();
  const [isScrolling, setIsScrolling] = useState(false);

  const {
    selectedFolders: selectedAnalyzeFolders,
    pinnedTags,
    showAnalyzeLimits,
  } = useContext(AnalyzeContext);
  const { selectedTrendSearchFolders, relatedTags } = useContext(TimeSearchContext);
  const selectedFolders = payload.isTrendSearch
    ? selectedTrendSearchFolders
    : selectedAnalyzeFolders;

  const { chartHeight, chartSortKey, hiddenTags } = useContext(AnalyzeChartContext);

  const {
    data: anomalousAnalyzeTags,
    isLoading,
    isSuccess,
  } = useAnomalousTags(payload, selectedFolders, chartSortKey, showAnalyzeLimits);
  const anomalousTags = (payload.isTrendSearch ? relatedTags : anomalousAnalyzeTags) || [];
  const { data: subsystemById } = useSubSystemsById();
  const [search] = useSearchParams();
  const tagNameParams = search.get(TAGS_SEARCH_PARAM);
  const subSystemsParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);

  // computeItemKey is necessary for animation to ensure Virtuoso reuses the same elements
  const computeItemKey = useCallback(({ folder, index, tags }): string => {
    return `${folder}-${tags[index]}`;
  }, []);

  const isFolderStatic = useCallback(
    (folder: number) => {
      if (!subsystemById[folder]?.tags) {
        return;
      }
      const hasPinned = subsystemById[folder].tags.filter((tag) =>
        pinnedTags?.some((pt) => pt.name === tag.name && pt.folder === folder),
      );
      return hasPinned.length > 0 && !selectedFolders.find((sf) => sf === folder);
    },
    [pinnedTags, selectedFolders, subsystemById],
  );

  const getVisibleTags = useCallback(
    (folder: number, tags: string[]) => {
      return tags?.filter(
        (tag) =>
          !hiddenTags.find((hiddenTag) => hiddenTag.tag === tag && hiddenTag.folder === folder),
      );
    },
    [hiddenTags],
  );
  //filtering the tags based on tagname query param
  const getQueryFilteredTags = useCallback((tags: string[], tagsInQuery: string[]) => {
    return tags.filter((e) => tagsInQuery.indexOf(e) !== -1);
  }, []);

  const equalFolderValues = useMemo(() => {
    return (
      selectedFolders.length === subSystemsParams?.split(',').map(Number).length &&
      selectedFolders.every(
        (value, index) => value === subSystemsParams?.split(',').map(Number)[index],
      )
    );
  }, [selectedFolders, subSystemsParams]);

  const getVirtuosoEl = (folder: number, tags: string[], multiView = false): ReactElement => {
    let props = {
      increaseViewportBy: { top: chartHeight, bottom: chartHeight * 2 },
      style: { height: 'calc(100% - 94px)', flexGrow: '1' },
      className: 'custom-scroll',
    };

    if (multiView) {
      props = {
        increaseViewportBy: { top: chartHeight, bottom: chartHeight },
        style: {
          height: `${
            (chartHeight + 35) *
              Math.min(tags?.length, NumberUtils.clamp(475 / chartHeight, 3, 6)) +
            35 // account for chart header
          }px`,
          flexGrow: '0',
        },
        className: 'custom-scroll custom-scroll--folder',
      };
    }

    const { increaseViewportBy, style, className } = props;

    const stringValueTags = getStringTagNames(subsystemById[folder]?.tags);

    return (
      <AnimationContext.Provider value={{ animationEnabled: !isScrolling }}>
        <Virtuoso
          data-testid="virtuoso-item-list"
          ref={virtuoso}
          computeItemKey={(index) => computeItemKey({ folder, index, tags })}
          components={{ Item: AnimatedContainer }}
          totalCount={tags?.length}
          defaultItemHeight={chartHeight + 35}
          isScrolling={setIsScrolling}
          itemContent={(index: number) => {
            return (
              <AnalyzeChartWrapper
                key={computeItemKey({ folder, index, tags })}
                payload={payload}
                isScrolling={isScrolling}
                folder={folder}
                tag={tags[index]}
                isStringValueTag={stringValueTags.includes(tags[index])}
                isIow={subsystemById[folder]?.infra_type_id === FOLDER.IOW_INFRA_TYPE_ID}
                isTrendSearch={payload?.isTrendSearch}
              />
            );
          }}
          increaseViewportBy={increaseViewportBy}
          style={style}
          className={className}
        />
      </AnimationContext.Provider>
    );
  };

  const getChartsContainer = () => {
    if (isSuccess && anomalousTags?.length > 1) {
      return (
        <Virtuoso
          totalCount={anomalousTags?.length}
          className="custom-scroll"
          data-testid="virtuoso-scroller"
          itemContent={(index: number) => {
            const { folder, tags: _tags } = anomalousTags[index];
            let tags = getVisibleTags(folder, _tags);
            const tagsInQuery = tagNameParams?.split(',');
            if (tagsInQuery) {
              tags = getQueryFilteredTags(tags, tagsInQuery);
            }
            if (!equalFolderValues) {
              tags = getVisibleTags(folder, _tags);
            }

            return (
              <Fragment key={folder}>
                <FolderHeader
                  title={subsystemById[folder]?.name}
                  description={subsystemById[folder]?.description}
                  parentFolderTitle={`${subsystemById[folder]?.parentName}/${subsystemById[folder]?.name}`}
                  folder={folder}
                  pinned={isFolderStatic(folder)}
                  hasTags={tags?.length > 0}
                  isIow={subsystemById[folder]?.infra_type_id === FOLDER.IOW_INFRA_TYPE_ID}
                />
                {tags?.length > 0 && getVirtuosoEl(folder, tags, true)}
              </Fragment>
            );
          }}
        />
      );
    } else if (isSuccess && anomalousTags?.length === 1) {
      const { folder, tags: _tags = [] } = anomalousTags[0];
      let tags = getVisibleTags(folder, _tags);
      const tagsInQuery = tagNameParams?.split(',');
      if (tagsInQuery) {
        tags = getQueryFilteredTags(tags, tagsInQuery);
      }
      if (!equalFolderValues) {
        tags = getVisibleTags(folder, _tags);
      }

      return (
        <>
          <FolderHeader
            title={subsystemById[folder]?.name}
            description={subsystemById[folder]?.description}
            parentFolderTitle={`${subsystemById[folder]?.parentName}/${subsystemById[folder]?.name}`}
            folder={folder}
            pinned={isFolderStatic(folder)}
            hasTags={tags.length > 0}
            isIow={subsystemById[folder]?.infra_type_id === FOLDER.IOW_INFRA_TYPE_ID}
          />
          {getVirtuosoEl(folder, tags)}
        </>
      );
    } else {
      return <NoDataSelected />;
    }
  };

  return (
    <ChartsContainerWrapper>
      <ChartHeader>
        <AnalyzeChartActions />
      </ChartHeader>
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <ChartsContainer id={`analyze-chart-container-${viewId}`}>
          {getChartsContainer()}
        </ChartsContainer>
      )}
    </ChartsContainerWrapper>
  );
};

interface Props {
  payload: Payload;
  isScrolling: boolean;
  folder: number;
  tag: string;
  isIow: boolean; //TODO: IOW-HIDE - Remove after making it generic
  isStringValueTag: boolean;
  isTrendSearch?: boolean;
}

const AnalyzeChartWrapper: React.FC<Props> = ({
  payload,
  isScrolling,
  folder,
  tag,
  isIow,
  isStringValueTag,
  isTrendSearch,
}) => {
  // Disable the API call while the container is scrolling and depending on tag type
  const {
    data: tagData,
    isLoading: isLoadingTagData,
    isError: isErrorTagData,
  } = useTagMinMax({ ...payload, folder: folder, tag }, !isScrolling && !isStringValueTag);

  const {
    data: stringTagData,
    isLoading: isLoadingStringTagData,
    isError: isErrorStringTagData,
  } = useTagMinMaxString({ ...payload, folder, tag: [tag] }, !isScrolling && isStringValueTag);

  const {
    timeseries = [],
    anomalies = [],
    modes = [],
    highs = [],
    highHighs = [],
    lows = [],
    lowLows = [],
  } = tagData ?? {};

  const { timeseries: timeSeriesStringData = [] } = stringTagData ?? {};

  const { pinnedTags: analyzePinnedTags } = useContext(AnalyzeContext);
  const { pinnedTags: TSPinnedTags } = useContext(TimeSearchContext);
  const pinnedTags = !isTrendSearch ? analyzePinnedTags : TSPinnedTags;

  const isChartLoading = !isStringValueTag
    ? (isLoadingTagData && !tagData) || (isScrolling && !tagData)
    : (isLoadingStringTagData && !stringTagData) || (isScrolling && !tagData);

  const hasChartError = !isStringValueTag ? isErrorTagData : isErrorStringTagData;

  // no limits needed to be displayed for string tags
  const chartLimitsArray = !isStringValueTag ? [...highs, ...highHighs, ...lows, ...lowLows] : [];

  const chartLimits = !isStringValueTag
    ? { highs, highHighs, lows, lowLows }
    : { highs: [], highHighs: [], lows: [], lowLows: [] };

  return (
    <AnalyzeChart
      key={tag}
      seriesData={!isStringValueTag ? timeseries : timeSeriesStringData}
      anomalyData={anomalies}
      modes={modes}
      limitsArray={chartLimitsArray}
      limits={chartLimits}
      folder={folder}
      tag={tag}
      isIOWtag={isIow} //TODO: IOW-HIDE - Remove after making it generic
      pinned={pinnedTags.some((t) => t.name === tag && t.folder === folder)}
      isLoading={isChartLoading}
      isError={hasChartError}
    />
  );
};
