import styled, { css } from 'styled-components';

import { colors, Size, text } from '@controlrooms/design-tokens';
import { AnalyzeChartProps } from '@controlrooms/models';

export const StyledChart = styled.div`
  position: relative;
  // box-shadow: ${({ theme }) => theme.chart.boxShadow};

  .chart-background {
    fill: ${({ theme }) => theme.chart.chartBackgroundColor};
    stroke: ${({ theme }) => theme.chart.chartStrokeColor};
  }

  .blurry-line {
    filter: ${({ theme }) => {
      return theme.name === 'Dark' ? 'blur(2px)' : 'blur(1px)';
    }};
  }

  .red-area-high {
    fill: ${({ theme }) => theme.chart.anomalyLimitLines};
    opacity: ${({ theme }) => {
      return theme.name === 'Dark' ? '40%' : '60%';
    }};
  }

  .red-area-low {
    fill: ${({ theme }) => theme.chart.anomalyLimitLines};
    opacity: ${({ theme }) => {
      return theme.name === 'Dark' ? '40%' : '60%';
    }};
  }

  .tag-header {
    display: flex;
    align-items: center;
    padding: 9px;
  }
  svg {
    width: 100%;
    display: inline-block;
    vertical-align: middle;

    .brush {
      rect.selection {
        fill: ${({ theme }) => theme.chart.selection.backgroundColor};
        stroke: ${({ theme }) => theme.chart.selection.borderColor};
        mix-blend-mode: normal;

        // move the top and bottom stroke out of view (clipped)
        transform: translate(0px, -2px) scale(1, 1.1);
      }
    }

    .chart {
      background: ${colors.k[0]};
    }
  }
  .tooltip-limit {
    fill-opacity: 0;
  }
  .tooltip-limit.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.limitColor};
  }
  .tooltip-frequent {
    fill-opacity: 0;
  }
  .tooltip-frequent.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.frequentColor};
  }
  .tooltip-content {
    background-color: #fcfcfa;
    color: #242522;
    position: absolute;
    padding: 4px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    box-shadow: 2px 2px 10px 2px #00000069;
    z-index: 99;
    min-width: 140px;
    justify-content: center;
    flex-direction: column;
    &:after {
      content: '▼';
      font-size: 8px;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      color: #fcfcfa;
    }
    span {
      background-color: #fcfcfa;
      width: 100%;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      &.value {
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 2px;
        width: 100%;
        text-align: center;
        .uom-display {
          padding-left: 3px;
          font-size: 9px;
          line-height: 13px;
          vertical-align: text-top;
          color: #242522;
        }
      }
      &.minMax {
        font-weight: 400;
        font-size: 11px;
        display: flex;
        line-height: 13px;
        place-content: space-between;
      }
      &.min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      &.max {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
    &.active {
      background-color: #fff;
      top: -12px;
      font-weight: 700;
      color: #242522;
      span {
        background-color: #fff;
      }

      &:after {
        content: '▼';
        font-size: 8px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
      }

      .value {
        .uom-display {
          color: #242522;
        }
      }
      .min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        font-weight: bolder;
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      .max {
        font-weight: bolder;
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
  }
  .yaxis {
    text {
      fill: ${({ theme }) => theme.chart.yAxisFill};
    }
  }
`;
export const StyledEventChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .tag-header {
    display: flex;
    align-items: center;
    padding: 9px;
  }
  svg {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;

    .chart {
      background: ${colors.k[0]};
    }
  }
  .tooltip-limit {
    fill-opacity: 1;
  }
  .tooltip-limit.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.limitColor};
  }
  .tooltip-frequent {
    fill-opacity: 1;
  }
  .tooltip-frequent.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.frequentColor};
  }
  .tooltip-content {
    background-color: #fcfcfa;
    color: #242522;
    position: absolute;
    padding: 4px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    box-shadow: 2px 2px 10px 2px #00000069;
    z-index: 99;
    width: 140px;
    flex-direction: column;
    &:after {
      content: '▼';
      font-size: 8px;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      color: #fcfcfa;
    }
    span {
      background-color: #fcfcfa;
      width: 100%;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      &.value {
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 2px;
        width: 100%;
        text-align: center;
        .uom-display {
          padding-left: 3px;
          font-size: 9px;
          line-height: 13px;
          vertical-align: text-top;
          color: #242522;
        }
      }
      &.minMax {
        font-weight: 400;
        font-size: 11px;
        display: flex;
        line-height: 13px;
        place-content: space-between;
      }
      &.min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      &.max {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
    &.active {
      background-color: #fff;
      top: -12px;
      font-weight: 700;
      color: #242522;
      span {
        background-color: #fff;
      }

      &:after {
        content: '▼';
        font-size: 8px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
      }

      .value {
        .uom-display {
          color: #242522;
        }
      }
      .min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        font-weight: bolder;
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      .max {
        font-weight: bolder;
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
  }
  .yaxis {
    text {
      fill: ${({ theme }) => theme.chart.yAxisFill};
    }
  }
`;
export const StyledLimitChart = styled.div`
  position: relative;
  height: 500px;

  .tag-header {
    display: flex;
    align-items: center;
    padding: 9px;
  }
  svg {
    height: 500px;
    display: inline-block;
    vertical-align: middle;

    .chart {
      background: ${colors.k[0]};
    }
  }
  .tooltip-limit {
    fill-opacity: 1;
  }
  .tooltip-limit.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.limitColor};
  }
  .tooltip-frequent {
    fill-opacity: 1;
  }
  .tooltip-frequent.active {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.chart.tooltip.frequentColor};
  }
  .tooltip-content {
    background-color: #fcfcfa;
    color: #242522;
    position: absolute;
    padding: 4px;
    display: flex;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 3px;
    box-shadow: 2px 2px 10px 2px #00000069;
    z-index: 99;
    width: 140px;
    flex-direction: column;
    &:after {
      content: '▼';
      font-size: 8px;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      color: #fcfcfa;
    }
    span {
      background-color: #fcfcfa;
      width: 100%;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      &.value {
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 2px;
        width: 100%;
        text-align: center;
        .uom-display {
          padding-left: 3px;
          font-size: 9px;
          line-height: 13px;
          vertical-align: text-top;
          color: #242522;
        }
      }
      &.minMax {
        font-weight: 400;
        font-size: 11px;
        display: flex;
        line-height: 13px;
        place-content: space-between;
      }
      &.min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      &.max {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
    &.active {
      background-color: #fff;
      top: -12px;
      font-weight: 700;
      color: #242522;
      span {
        background-color: #fff;
      }

      &:after {
        content: '▼';
        font-size: 8px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
      }

      .value {
        .uom-display {
          color: #242522;
        }
      }
      .min {
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        font-weight: bolder;
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
      .max {
        font-weight: bolder;
        color: ${({ theme }) => theme.chart.tooltip.minMaxColor};
        &.warning {
          color: ${({ theme }) => theme.chart.tooltip.warningColor};
        }
      }
    }
  }
  .yaxis {
    text {
      fill: ${({ theme }) => theme.chart.yAxisFill};
    }
  }
`;

export const StyledChartHeader = styled.div`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.chart.headerBackgroundColor};
  z-index: 9999;
  height: 96px;
  align-items: end;
  display: flex;
`;

export const StyledTagTitle = styled.div`
  display: inline-block;
  padding: 2px 5px;
  background: ${({ theme }) => theme.chart.tagTitle.backgroundColor};
  color: ${({ theme }) => theme.chart.tagTitle.textColor};
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  font-size: ${text.size[Size.DATA_13]};
  min-width: 90px;
  text-align: center;
  line-height: 13px;
`;

export const StyledTagDesc = styled.div`
  color: ${({ theme }) => theme.tagHeader.tagDescColor};
  font-size: ${text.size[Size.DATA_11]};
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
  margin-left: 8px;
`;

export const StyledTagUOM = styled.div`
  color: ${({ theme }) => theme.tagHeader.tagUOMColor};
  font-size: ${text.size[Size.DATA_11]};
  font-family: 'Open Sans', sans-serif;
`;

export const StyledAnalyzeTimeline = styled.div`
  margin-top: auto;
  .domain {
    // TODO: need to style timeline
    stroke: ${colors.k[20]};
  }
`;

export const StyledTagHeader = styled.div<Pick<AnalyzeChartProps, 'pinned'>>`
  display: flex;
  width: calc(100% - 1px); // To account for StyledChart:after border
  button.pin-tag {
    visibility: hidden;
    background: none;
    border: none;
    width: 35px;
  }

  button.unpin-tag,
  button.hide-tag {
    background: none;
    border: none;
    width: 35px;
  }

  .close-icon svg {
    fill: ${({ theme }) => theme.tagHeader.closeIconFill};
    cursor: pointer;
    width: auto;
    margin-top: -4px;
  }

  .closed-eye-icon {
    stroke: ${({ theme }) => theme.tagHeader.closedEyeIconFill};
    margin-right: 1px;
  }

  .tag-header-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 8px 9px 2px 9px;
    height: 19px;
    margin-right: -1px;

    button {
      margin-left: 6px;
      height: 19px;
      cursor: pointer;

      .pin-icon {
        display: inline-block;
        margin-left: -3px;
      }
    }

    ${StyledTagTitle} {
      background: ${({ theme }) => theme.tagHeader.tagTitleBackground};
      color: ${({ theme }) => theme.tagHeader.tagTitleColor};

      ${(p) =>
        p.pinned &&
        css`
          background: ${({ theme }) => theme.tagHeader.tagTitleBackgroundPinned};
          color: ${({ theme }) => theme.tagHeader.tagTitleColorPinned};
        `}
    }

    ${StyledTagDesc} {
      ${(p) =>
        p.pinned &&
        css`
          color: ${({ theme }) => theme.tagHeader.tagDescColorPinned};
        `}
    }

    ${StyledTagUOM} {
      flex-grow: 1;
      margin-left: 8px;
    }

    .pin-icon-highlighted {
      visibility: visible;

      svg path {
        fill: ${({ pinned, theme }) =>
          pinned ? theme.tagHeader.pinIconFill : theme.tagHeader.pinIconRowHoverFill};
        stroke: ${({ theme }) => theme.tagHeader.pinIconRowHoverStroke};
      }

      svg {
        filter: drop-shadow(0 0 2px #80f7ff);

        path {
          fill: ${({ pinned, theme }) =>
            pinned ? theme.tagHeader.pinIconFill : theme.tagHeader.pinIconFillHover};
        }
      }
    }

    .pin-icon,
    .closed-eye-icon svg {
      margin-right: 3px;

      ${(p) =>
        p.pinned &&
        css`
          visibility: visible;
          path {
            fill: ${({ theme }) => theme.tagHeader.pinIconFill};
          }
        `}
    }

    .actions {
      text-align: right;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.tagHeader.backgroundHover};
    .close-icon svg {
      fill: ${({ theme }) => theme.tagHeader.closeIconFillHover};
    }

    .closed-eye-icon {
      stroke: ${({ theme }) => theme.tagHeader.closedEyeIconFillHover};
    }

    ${StyledTagTitle} {
      background: ${({ theme }) => theme.tagHeader.tagTitleBackgroundHover};
      box-shadow: ${({ theme }) => theme.tagHeader.tagTitleBoxShadowHover};
      color: ${({ theme }) => theme.tagHeader.tagTitleColorHover};

      ${(props) =>
        props.pinned &&
        css`
          background: ${({ theme }) => theme.tagHeader.tagTitleBackgroundHoverPinned};
          color: ${({ theme }) => theme.tagHeader.tagTitleColorHoverPinned};
        `}
    }

    ${StyledTagDesc} {
      color: ${({ theme }) => theme.tagHeader.tagDescColorHover};
      text-shadow: ${({ theme }) => theme.tagHeader.tagTitleBoxShadowHover};

      ${(props) =>
        props.pinned &&
        css`
          color: ${({ theme }) => theme.tagHeader.tagDescColorHoverPinned};
        `}
    }

    ${StyledTagUOM} {
      color: ${({ theme }) => theme.tagHeader.tagUomColorHover};
      text-shadow: ${({ theme }) => theme.tagHeader.tagTitleBoxShadowHover};
    }

    button.pin-tag {
      visibility: visible;

      svg path {
        fill: ${({ pinned, theme }) =>
          pinned ? theme.tagHeader.pinIconFill : theme.tagHeader.pinIconRowHoverFill};
        stroke: ${({ theme }) => theme.tagHeader.pinIconRowHoverStroke};
      }

      &:hover {
        svg {
          filter: drop-shadow(0 0 2px #80f7ff);

          path {
            fill: ${({ pinned, theme }) =>
              pinned ? theme.tagHeader.pinIconFill : theme.tagHeader.pinIconFillHover};
          }
        }
      }
    }
  }
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 28px;
  .menu-select {
    margin: 0;
    padding: 0;
    button {
      padding: 0;
    }
  }
  .menu-select > ul {
    right: 22px;
  }
`;
