import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TimePresetMap } from '../../app/constants/time-selection-form';
import { EnsembleContext } from '../../app/context/ensemble-context';
import { useTenant } from '../../app/context/tenant-context';
import { useUserId } from '../../app/hooks/accounts';
import { useDeleteView, useSaveView, useUpdateView } from '../../app/hooks/view';

import { ChartMode } from './investigate-chart-context';
import { useLayoutContext } from './layout-context';

import {
  HiddenTag,
  ParentTag,
  PersistTimeSelection,
  TimePresetKeys,
  TimeRanges,
  TimeSelection,
  TrendSearchData,
  ViewState,
  ViewType,
} from '@controlrooms/models';

interface ViewContextProps {
  viewId: string;
  viewState: ViewState;
  setViewState: Dispatch<SetStateAction<ViewState>>;
  selectedView: string;
  setSelectedView: Dispatch<SetStateAction<string>>;
  resetTenantViewState: () => void;
  handleShowLimits: (showLimits: boolean | undefined) => void;
  saveView: (name: string) => void;
  closeView: () => void;
  deleteView: () => void;
  updateView: () => void;
  duplicateView: () => void;
  handleSystemSelection: (systems: number[]) => void;
  updateTimeSelection: (timeselection: TimeSelection, makeDirty?: boolean) => void;
  updateLocalView: (viewName: string, view: ViewState) => void;
  searchMode: boolean;
  setSearchMode: Dispatch<SetStateAction<boolean>>;
  setTrendSearchData: (trendSearchData: TrendSearchData) => void;
  clearTrendSearchData: () => void;
  getQueryKeyWithViewId: (label: string) => string;
}

const defaultTimeSelections: TimeSelection = {
  startTime: dayjs().subtract(12, 'hour'),
  endTime: dayjs(),
  timezone: dayjs.tz.guess(), // Default to user timezone
  timeRange: TimeRanges.PRESET,
  streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
  nowSelected: false,
};

const defaultState = {
  viewId: 'default',
  viewState: {} as ViewState,
  setViewState: () => null,
  selectedView: 'default',
  setSelectedView: () => null,
  resetTenantViewState: () => null,
  handleShowLimits: () => null,
  saveView: () => null,
  closeView: () => null,
  deleteView: () => null,
  updateView: () => null,
  duplicateView: () => null,
  handleSystemSelection: () => null,
  updateTimeSelection: () => null,
  updateLocalView: () => null,
  searchMode: false,
  setSearchMode: () => null,
  setTrendSearchData: () => null,
  clearTrendSearchData: () => null,
  getQueryKeyWithViewId: (label: string) => `${label}-default`,
};

export const ViewContext = createContext<ViewContextProps>(defaultState);

export const ViewContextProvider: React.FC<{ viewId: string; children: ReactNode }> = ({
  viewId,
  children,
}) => {
  const { selectedEnsemble } = useContext(EnsembleContext);
  const { activeModes, activeView, setViewIds, setActiveModes, setActiveView } = useLayoutContext();

  const currentUserId = useUserId();

  const { tenant: currentTenantId } = useTenant();
  const { mutateAsync: createView } = useSaveView();
  const { mutateAsync: deletePersistedView } = useDeleteView();
  const { mutateAsync: updatePersistedView } = useUpdateView();
  const [searchMode, setSearchMode] = useState(defaultState.searchMode);

  const defaultViewState: ViewState = useMemo(
    () => ({
      viewId,
      selectedMode: ViewType.MONITOR,
      isHidden: false,
      isDirty: viewId === 'default' ? false : true,
      isDeleted: false,
      tenant_id: currentTenantId,
      timeSelection: defaultTimeSelections,
      view: {
        [ViewType.MONITOR]: {
          type: ViewType.MONITOR,
          severityFilter: 0,
          showLimits: true,
          pinnedTags: [],
          hiddenTags: [],
          selectedFolders: [],
          selectedTags: [],
        },
        [ViewType.ANALYZE]: {
          type: ViewType.ANALYZE,
          selectedTags: [],
          showLimits: true,
          pinnedTags: [] as ParentTag[],
          hiddenTags: [] as HiddenTag[],
          selectedFolders: [],
          trendSearchData: {} as TrendSearchData,
        },
        [ViewType.INVESTIGATE]: {
          selectedFolders: [] as number[],
          selectedTags: [] as string[],
          showAnomalies: true,
          organize: 'overlays' as ChartMode,
          groupByUom: false,
          showLimits: true,
        },
      },
      view_id: '',
      timeSelectionHistory: [],
      selectedEnsemble: selectedEnsemble,
    }),
    [currentTenantId, viewId, selectedEnsemble],
  );

  const resetTenantViewState = useCallback(() => {
    sessionStorage.clear();
    setViewState(defaultViewState);
  }, [defaultViewState]);

  // Retrieve state from session storage or use default
  const [viewState, setViewState] = useState<ViewState>(() => {
    const savedViewState = sessionStorage.getItem(viewId);
    if (savedViewState) {
      const parsedViewState: ViewState = JSON.parse(savedViewState);
      parsedViewState.timeSelection.startTime = dayjs(parsedViewState.timeSelection.startTime);
      parsedViewState.timeSelection.endTime = dayjs(parsedViewState.timeSelection.endTime);
      return parsedViewState;
    }
    return defaultViewState;
  });

  const [selectedView, setSelectedView] = useState<string>(() => {
    const savedSelectedView = sessionStorage.getItem('selectedView');
    return savedSelectedView ? savedSelectedView : 'default';
  });

  // Persist state to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(viewId, JSON.stringify(viewState));
  }, [viewId, viewState]);

  useEffect(() => {
    sessionStorage.setItem('selectedView', selectedView);
  }, [selectedView]);

  const constructPersistedView = useCallback(
    (view_id, viewName = '') => {
      const selectedMode = activeModes[view_id];
      const persistTimeSelection = PersistTimeSelection.ofTimeSelection(viewState.timeSelection);
      return {
        view: {
          ...viewState.view[selectedMode],
          timeSelection: persistTimeSelection,
          type: selectedMode,
          selectedFolders: viewState.view[selectedMode].selectedFolders,
          name: viewName,
        },
        user_id: currentUserId,
        shared: false,
      };
    },
    [activeModes, currentUserId, viewState.timeSelection, viewState.view],
  );

  const handleShowLimits = useCallback(
    (newShowLimitsValue: boolean | undefined) => {
      setViewState((prevState) => {
        const selectedMode = activeModes[activeView];
        return {
          ...prevState,
          view: {
            ...prevState.view,
            [selectedMode]: {
              ...prevState.view[selectedMode],
              showLimits: !!newShowLimitsValue,
            },
          },
        };
      });
    },
    [activeModes, activeView],
  );

  // Close view
  const closeView = useCallback(() => {
    if (viewState.viewId === 'default') return;

    if (activeView === viewState.viewId) {
      setActiveView('default');
    }
    setViewIds((prev) => {
      const newState = [...prev];
      const index = newState.indexOf(viewState.viewId);
      newState.splice(index, 1);
      return newState;
    });
    sessionStorage.removeItem(viewId);
  }, [activeView, setActiveView, setViewIds, viewId, viewState]);

  // Update time selection in view
  const updateTimeSelection = useCallback((newTimeselection: TimeSelection, makeDirty = false) => {
    setViewState((prev) => ({
      ...prev,
      isDirty: makeDirty !== undefined ? makeDirty : prev.isDirty,
      timeSelection: newTimeselection,
    }));
  }, []);

  // Update view
  const updateView = useCallback(async () => {
    const persistView = constructPersistedView(viewState.viewId);
    await updatePersistedView({ view: persistView, view_id: viewState.viewId });
  }, [constructPersistedView, viewState.viewId, updatePersistedView]);

  // Save view
  const saveView = useCallback(
    async (viewName = '') => {
      const persistView = constructPersistedView(viewState.viewId, viewName);
      if (viewState.viewId === 'default') {
        // TODO Update user tenant preference
        // await updateUserTenantPreference({ user_id: currentUserId, tenant_id: currentTenantId, view: persistView });
        viewState.isDirty = false;

        setViewState((prev) => ({
          ...prev,
          isDirty: false,
        }));
        return;
      }

      if (viewState.view_id != '') {
        try {
          await updateView();
          setViewState((prev) => {
            const newState = { ...prev, isDirty: false };
            return newState;
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const hash = await createView(persistView);
          setViewState((prev) => {
            const newState = { ...prev, isDirty: false, viewId: hash, view_id: hash };
            return newState;
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    [constructPersistedView, createView, updateView, viewState],
  );

  const updateLocalView = useCallback((viewName: string, view: ViewState) => {
    setViewState((prev) => {
      const newState = { ...prev, ...view };
      return newState;
    });
  }, []);

  const handleSystemSelection = useCallback(
    (systems: number[]) => {
      const selectedMode = activeModes[viewId];
      setViewState((prev) => {
        const newState = { ...prev };
        newState.view[selectedMode].selectedFolders = systems;
        return newState;
      });
    },
    [activeModes, viewId],
  );

  const setTrendSearchData = useCallback((trendSearchData: TrendSearchData) => {
    setViewState((prev) => {
      const newState = { ...prev };
      newState.view[ViewType.ANALYZE].trendSearchData = trendSearchData;
      return newState;
    });
  }, []);

  const clearTrendSearchData = useCallback(() => {
    setViewState((prev) => {
      const newState = { ...prev };
      delete newState.view[ViewType.ANALYZE].trendSearchData;
      return newState;
    });
  }, []);

  // Delete view
  const deleteView = useCallback(async () => {
    await deletePersistedView(viewState.viewId);
    //Remove from active views
    if (activeView === viewState.viewId) {
      setActiveView('default');
    }
    //Remove from active modes
    setActiveModes((prev) => {
      const newState = { ...prev };
      delete newState[viewState.viewId];
      return newState;
    });
    //Remove from viewIds
    setViewIds((prev) => {
      const newState = [...prev];
      const index = newState.indexOf(viewState.viewId);
      newState.splice(index, 1);
      return newState;
    });
    //Remove from sessionStorage
    sessionStorage.removeItem(viewState.viewId);
  }, [
    activeView,
    deletePersistedView,
    setActiveModes,
    setActiveView,
    setViewIds,
    viewState.viewId,
  ]);

  // Duplicate view
  const duplicateView = useCallback(() => {
    const uuid = uuidv4();
    const currentSessionView = sessionStorage.getItem(viewId);
    if (!currentSessionView) {
      console.error('No view found with the viewid', viewId);
      return;
    }

    const currView: ViewState = JSON.parse(currentSessionView);
    currView.isDirty = true;
    currView.viewId = uuid;
    sessionStorage.setItem(uuid, JSON.stringify(currView));
    setViewIds((prevItems) => [...prevItems, uuid]);
    setActiveModes((prev) => ({ ...prev, [uuid]: activeModes[viewId] }));
    setActiveView(uuid);
  }, [activeModes, setActiveModes, setActiveView, setViewIds, viewId]);

  const getQueryKeyWithViewId = useCallback((label: string) => `${label}-${viewId}`, [viewId]);

  const appState = useMemo(
    () => ({
      viewId,
      viewState,
      setViewState,
      resetTenantViewState,
      selectedView,
      setSelectedView,
      handleShowLimits,
      saveView,
      closeView,
      deleteView,
      updateView,
      duplicateView,
      updateTimeSelection,
      handleSystemSelection,
      updateLocalView,
      searchMode,
      setSearchMode,
      setTrendSearchData,
      clearTrendSearchData,
      getQueryKeyWithViewId,
    }),
    [
      viewId,
      viewState,
      resetTenantViewState,
      selectedView,
      handleShowLimits,
      saveView,
      closeView,
      deleteView,
      updateView,
      duplicateView,
      updateTimeSelection,
      handleSystemSelection,
      updateLocalView,
      searchMode,
      setSearchMode,
      setTrendSearchData,
      clearTrendSearchData,
      getQueryKeyWithViewId,
    ],
  );

  return <ViewContext.Provider value={appState}>{children}</ViewContext.Provider>;
};

export const useViewContext = (): ViewContextProps => {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('useViewContext must be used within a ViewContextProvider');
  }
  return context;
};
