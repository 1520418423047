import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { useAnalytics } from '../../../app/analytics';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { Chevron } from '../time-display/styles';

import { ViewActionsContainer } from './styles';

import { Checkbox, Li, Ul, OptionsGroup } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';
import { useClickOutside } from '@controlrooms/hooks';
import { ViewFiltersValue } from '@controlrooms/models';

const ButtonGroup = styled.div`
  display: flex;
  span {
    margin-right: 10px;
  }
`;

const ChevronContainer = styled.div`
  padding: unset;
  margin-top: 7px;
`;

const StyledLi = styled(Li)<{ isDisabled?: boolean }>`
  margin-bottom: 5px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  span {
    padding-left: 5px;
    color: ${({ isDisabled, theme }) =>
      isDisabled ? colors.content['neutral-4'] : theme.modal.panel.color};
  }
`;

const Button = styled.button`
  font-family: inherit;
  display: flex;
  padding: 0;
  margin-top: 3px;
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.modal.panel.color};
  width: 80%;
  font-weight: 200;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
`;

const HeaderContainer = styled.span`
  color: ${colors.content['neutral-3']};
  cursor: default;
  display: flex;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 17px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.content['neutral-3']};
`;

export const ViewActions = () => {
  const { chartViewFilters, changeChartViewFilters } = useContext(AnalyzeChartContext);
  const { showAnalyzeLimits } = useContext(AnalyzeContext);
  const [isViewActionsOpen, setIsViewActionsOpen] = useState(false);
  const filtersRef = useRef(null);
  const { track } = useAnalytics();

  const toggleViewActions = () => setIsViewActionsOpen((prevState) => !prevState);

  useClickOutside(filtersRef, () => setIsViewActionsOpen(false));

  return (
    <ViewActionsContainer data-testid="filters-button">
      <OptionsGroup className="view-filters">
        <Button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isViewActionsOpen}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleViewActions();
          }}
        >
          <ButtonGroup>
            <span>Filters</span>
            <ChevronContainer>
              <Chevron />
            </ChevronContainer>
          </ButtonGroup>
        </Button>
        <Ul ref={filtersRef} isOpen={isViewActionsOpen}>
          <div>
            <HeaderContainer>View Filters</HeaderContainer>
            {chartViewFilters.map((option) => {
              const isDisabled =
                option.value !== ViewFiltersValue.FREQUENT_VALUE && !showAnalyzeLimits;
              return (
                <StyledLi
                  isDisabled={isDisabled}
                  data-testid={option.dataTestId}
                  key={option.value}
                >
                  <Checkbox
                    onChange={() => {
                      track('Analyze Chart', {
                        [option.label]: {
                          checked: option.checked,
                        },
                      });
                      changeChartViewFilters(option.value);
                    }}
                    checked={option.checked}
                    disabled={
                      option.value !== ViewFiltersValue.FREQUENT_VALUE && !showAnalyzeLimits
                    }
                    className={isDisabled ? 'disabled' : ''}
                  />
                  <span>{option.label}</span>
                </StyledLi>
              );
            })}
          </div>
        </Ul>
      </OptionsGroup>
    </ViewActionsContainer>
  );
};
