import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { Loading } from '../../../app/components/loading/loading';
import { AnalyzeV2, Investigate, MonitorV2 } from '../../../app/pages/routes';
import { useAppContext } from '../../context/app-context';
import { useLayoutContext } from '../../context/layout-context';
import { ViewContext, ViewContextProvider } from '../../context/view-context';
import TimeSearch from '../../pages/time-search/time-search';
import { TenantSelector } from '../header';

import Panel, { PanelContainer } from './Panel';
import { TabContainer, ViewTab } from './TabbedContainer';

import { TrendSearchData, ViewType } from '@controlrooms/models';

const ViewArea = styled.div`
  position: relative;
`;

const PanelLayout: React.FC = () => {
  const { viewIds } = useLayoutContext();

  return (
    <PanelContainer>
      <Panel>
        <div className="top-panel" style={{ display: 'flex' }}>
          <Panel.Left>
            <TenantSelector />
          </Panel.Left>
          <Panel.Resizer />
          <Panel.Right>
            <TabContainer />
          </Panel.Right>
        </div>
        <ViewArea className="view-area">
          {viewIds.map((viewId) => (
            <React.Fragment key={viewId}>
              <ViewContextProvider viewId={viewId}>
                <ContentArea viewId={viewId} />
              </ViewContextProvider>
            </React.Fragment>
          ))}
        </ViewArea>
      </Panel>
    </PanelContainer>
  );
};

const ContentArea = ({ viewId }: { viewId: string }) => {
  const { activeModes, activeView } = useLayoutContext();
  const { viewState } = useContext(ViewContext);
  const trendSearchData = viewState.view
    ? (viewState?.view[ViewType.ANALYZE]?.trendSearchData as TrendSearchData)
    : null;
  const { isTenantSwitching } = useAppContext();

  const [tabListElement, setTabListElement] = useState<HTMLElement | null>(null);
  const [defaultTabListElement, setDefaultTabListElement] = useState<HTMLElement | null>(null);

  // Effect to find the target for the portal after the component has mounted
  useEffect(() => {
    const tabList = document.getElementById('tab-container');
    const defaultTabList = document.getElementById('default-tab-container');
    if (tabList && defaultTabList) {
      setTabListElement(tabList);
      setDefaultTabListElement(defaultTabList);
    }
  }, []);

  // const tabListElement = document.getElementById('tab-container');
  return (
    <div
      style={{
        left: 0,
        right: 0,
        position: 'absolute',
        flexGrow: 1,
        display: 'flex',
        zIndex: viewId === activeView ? 1 : -1,
        visibility: viewId === activeView ? 'visible' : 'visible',
      }}
    >
      {tabListElement &&
        viewId !== 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, tabListElement)}
      {defaultTabListElement &&
        viewId === 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, defaultTabListElement)}
      {isTenantSwitching && <Loading overlay small={true} />}
      {!isTenantSwitching && (
        <>
          <>{activeModes[viewId] === ViewType.MONITOR && <MonitorV2 />}</>
          <>
            {activeModes[viewId] === ViewType.ANALYZE &&
              (trendSearchData?.startTime ? <TimeSearch /> : <AnalyzeV2 />)}
          </>
          <>{activeModes[viewId] === ViewType.INVESTIGATE && <Investigate />}</>
        </>
      )}
    </div>
  );
};

export default PanelLayout;
