import styled from 'styled-components';

import { breakpoints } from '../../../app/global-styles';

interface IHeaderProps {
  isReport?: boolean;
}

export const StyledFiltering = styled.div`
  display: flex;
  margin: 0 1rem 0 1rem;
  align-items: center;

  .group-tooltip-button {
    margin: 0;
  }

  .label {
    line-height: normal;
    font-weight: 400;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    color: ${({ theme }) => theme.slider.labelColor};
  }
`;

export const DeskWrapper = styled.div`
  display: none;
  ${breakpoints.desk} {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  ${breakpoints.desk} {
    display: none;
  }
`;

export const StyledHeader = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(180deg,${theme.chart.timelineBackgroundGradient.color1} 76%, ${theme.chart.timelineBackgroundGradient.color2} 100%)`};
  .desk-view {
    display: none;
    ${breakpoints.desk} {
      display: flex;
      justify-content: center;
      width: 45%;
    }
  }
  .mobile-view {
    display: flex;
    margin: 2%;
    width: auto;
    align-items: center;
    .select-systems {
      margin-right: 8%;
      margin-left: 1%;
      align-items: center;
    }
    ${breakpoints.desk} {
      display: none;
    }
  }
  .mobile-view-filter {
    right: 0;
    margin-left: 10%;
    margin-right: 5%;
    .filter-clicked {
      div {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .desk-view-timeline-actions {
    display: none;
    ${breakpoints.desk} {
      display: flex;
      justify-content: flex-end;
      width: 10%;
      align-items: center;
    }
  }
  .mobile-view-timeline-actions {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-right: 10%;
    @media only screen and (max-width: 650px) {
      margin-left: 22%;
    }
    ${breakpoints.desk} {
      display: none;
    }
  }
  .mobile-view-action-wrap {
    display: block;
    padding: 5px 0 0 10px;
    ${breakpoints.desk} {
      display: none;
    }
  }
`;

export const StyledHeaderActions = styled.div<IHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 0 10px;
  ${({ isReport }) =>
    !isReport &&
    `
    box-shadow: 5px 0 9px -9px #000000 inset;
  `}

  ${breakpoints.xl} {
    padding-top: 30px;
  }
  .desk-view-action-wrap {
    display: none;
    ${breakpoints.desk} {
      display: block;
    }
  }
`;

export const TimeWrapper = styled.div`
  width: 60%;
  ${breakpoints.desk} {
    display: flex;
    justify-content: center;
    width: auto;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2%;
  ${breakpoints.desk} {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }
`;

export const TimelineActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
  align-items: center;
`;

export const PopupWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  top: 8%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  transition: bottom 0.7s ease-out;
  z-index: 99;
  background: linear-gradient(180deg, #302e2c 0%, #3e4b4d 46.88%, #4d696f 100%);
`;

export const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: transform 0.5s ease-out;
  z-index: 1;
`;

export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  align-self: stretch;
  height: 40px;
  flex-grow: 0;
  border-bottom: 1px solid #464740;
  h4 {
    padding-left: 2%;
  }
`;

export const PopupContent = styled.div`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const SystemsMenu = styled.button`
  background: transparent;
  border: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  margin: 0px 10px;
  cursor: pointer;
`;
