import { useContext } from 'react';

import { useSubSystemsById } from '../../../app/hooks/folders';
import { TimeSearchContext } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';

import { SearchTagElement } from './style';
import { SearchParams } from './time-search';

import { Checkbox, Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { TimeSearchResult } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface SearchTagParams extends SearchParams {
  selectedEvents: TimeSearchResult[];
  handleEventSelect: (event: TimeSearchResult) => void;
}

export const SearchTag = ({
  startTime,
  endTime,
  tag,
  folder,
  selectedEvents,
  handleEventSelect,
}: SearchTagParams) => {
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const { setEvent, selectedTab } = useContext(TimeSearchContext);

  const dateTimeRange = TimeUtils.trendSearchDateTimeRange(startTime, endTime, timezone);
  const { data: subsystemById } = useSubSystemsById();

  return (
    <SearchTagElement
      isSelected={false}
      onClick={() => {
        setEvent({
          id: -1, // To select search tag card
          startTime: startTime,
          endTime: endTime,
          folder,
          tagName: tag.name,
          tag,
        });
      }}
    >
      <div className="system">
        <Icon name={ICONS.System} />
        <span>{subsystemById[folder]?.name}</span>
      </div>
      <div className="tagDetails">
        <span className="tagName">{tag.name}</span>
        <span className="tagDesc">{tag.description}</span>
      </div>
      <div className="unitDetails">
        <div className="left-section">
          {selectedTab?.event_type === 'default' && (
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                className="event-selection"
                checked={selectedEvents?.some(
                  (selectedEvent) =>
                    startTime === selectedEvent.start_time && endTime === selectedEvent.end_time,
                )}
                onChange={() => {
                  handleEventSelect({
                    start_time: startTime || '',
                    end_time: endTime || '',
                    dtw_distance: 0,
                    good_search_result: false,
                    window_data: [0],
                  });
                }}
              />
            </span>
          )}
          <div className="date-time">
            <span className="date">{dateTimeRange}</span>
          </div>
        </div>
        <div className="range">
          <span className="uom">{tag.uom}</span>
        </div>
      </div>
    </SearchTagElement>
  );
};
