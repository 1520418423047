import React, { useContext } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import SeverityFilter from '../monitor-header/severity-filter';
import LimitsToggle from '../monitor-header/severity-limit-exceeded-toggle';

import { SortActions } from './sort-actions';
import { StyledActions, YScaleActions, ActionsContainer } from './styles';
import { TimeSearchAction } from './time-search-actions';
import { ViewActions } from './view-actions';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

export const AnalyzeChartActions: React.FC = () => {
  const { increaseChartHeight, decreaseChartHeight } = useContext(AnalyzeChartContext);
  const { track } = useAnalytics();

  return (
    <StyledActions>
      <Tooltip label="Increase or decrease Y axis of all charts here">
        <YScaleActions>
          <button
            data-testid="y-scale-plus"
            type="button"
            onClick={() => {
              track('Analyze Chart', {
                increaseYAxis: 'clicked',
              });
              increaseChartHeight();
            }}
          >
            <Icon name={ICONS.Plus} width="10" height="10" />
          </button>
          <span>{'Y'}</span>
          <button
            data-testid="y-scale-minus"
            type="button"
            onClick={() => {
              track('Analyze Chart', {
                decreaseYAxis: 'clicked',
              });
              decreaseChartHeight();
            }}
          >
            <Icon name={ICONS.Minus} height="3" />
          </button>
        </YScaleActions>
      </Tooltip>
      <ActionsContainer>
        <TimeSearchAction />
        <SortActions />
        <ViewActions />
        <LimitsToggle />
        <div style={{ display: 'none' }}>
          <SeverityFilter showSlider={true} />
        </div>
      </ActionsContainer>
    </StyledActions>
  );
};
