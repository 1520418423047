import React, { useContext, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import AlertModal from '../../../app/components/alert-modal/alert-modal';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { HeatmapMini } from '../../components/heatmap/heatmap-mini';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { useViewContext } from '../../context/view-context';

import { StyledFolder } from './styled';
import { FolderHeaderProps } from './types';

import { Button, Icon, Li, OptionsGroup, Tooltip, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { ViewType } from '@controlrooms/models';

export const FolderHeader: React.FC<FolderHeaderProps> = ({
  title,
  description,
  parentFolderTitle,
  pinned,
  children,
  folder,
  hasTags,
  isIow,
}) => {
  const { hiddenTags, unhideTagsByFolder } = useContext(AnalyzeChartContext);
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const { track } = useAnalytics();
  const { setViewState } = useViewContext();

  const [searchParams] = useSearchParams();
  const isReport = searchParams.get('report');

  const hiddenTagsCount = hiddenTags?.filter((tag) => tag.folder === folder)?.length;

  const handleDeselectFolder = () => {
    setViewState((prev) => {
      const currentView = prev;
      currentView.view[ViewType.ANALYZE].selectedFolders = currentView.view[
        ViewType.ANALYZE
      ].selectedFolders.filter((item: number) => item !== folder);
      return {
        ...prev,
        currentView,
      };
    });
  };

  const handleUnhideTags = () => {
    unhideTagsByFolder(folder);
  };

  return (
    <StyledFolder data-testid="analyze-folder" pinned={pinned}>
      <div className="top">
        <div className="title" data-testid={`system-title-${title}`}>
          <Tooltip label={parentFolderTitle ?? ''}>{title}</Tooltip>
          <span className="description">
            {description && title && <span>&nbsp;:&nbsp;</span>}
            {description}
          </span>
        </div>
        <div className="actions-wrapepr">
          <div className="upper">
            {!hasTags && (
              <div data-testid={`no-anomalies-${title}`} className="no-tags">
                No anomalous tags
              </div>
            )}
            <button
              data-testid="unpin-tag-button"
              className="unpin-tag"
              type="button"
              onClick={() => {
                track('Folder Header - Close', {
                  closeFolderId: folder,
                  closeFolderTitle: title,
                  parentFolderTitle: parentFolderTitle,
                });
                handleDeselectFolder();
              }}
            >
              <Icon name={ICONS.CloseX} className="close-icon" width="14" height="14" />
            </button>
          </div>
          <div className="lower">
            {canUserReadAlert && <SystemMenu targetId={folder} name={title} />}
            {Boolean(hiddenTagsCount && !isReport && !isIow) && (
              <Tooltip label={`${hiddenTagsCount} Hidden Tags`} place="left">
                <button
                  className="hidden-tags"
                  onClick={() => {
                    track('Folder Header - Unhide', {
                      unHideFolderId: folder,
                      unHideFolderTitle: title,
                      parentFolderTitle: parentFolderTitle,
                    });
                    handleUnhideTags();
                  }}
                >
                  {hiddenTagsCount}
                  <Icon
                    name={ICONS.ClosedEye}
                    height="14px"
                    width="14px"
                    className="closed-eye-icon"
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div>{children}</div>
      <HeatmapMini folder={folder} />
    </StyledFolder>
  );
};

const SystemMenu = ({ targetId, name }: { targetId: number; name: string }) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup className="menu-select">
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`system_menu_${name}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul isOpen={isMenuSelectOpen} position="absolute" className="dropdown" ref={ulRef}>
        <Li data-testid={`${name}-manage-alert`} key="manage-alert">
          <AlertModal targetType="system" targetId={targetId}>
            <div>Manage Alerts</div>
          </AlertModal>
        </Li>
        {/* <Li data-testid="more-actions" key="more-actions">
          <span>More Actions</span>
        </Li> */}
      </Ul>
    </OptionsGroup>
  );
};
