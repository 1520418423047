import * as d3 from 'd3';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { AnomalyRange, SVGDefsSelection, TimeValue } from '@controlrooms/models';
import { Line } from '@controlrooms/models';

export const buildAnomalyLines = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  anomalyData: AnomalyRange[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package,
  exportImg = false, //TODO: Remove once export SSR is fixed - filter blur not working when converting to png in sharp
  minutesPerPixel?: number,
) => {
  const uuid = uuidv4();

  const lineGenerator: Line<TimeValue> = d3
    .line<TimeValue>()
    .defined((d) => d.value !== null && typeof d.value === 'number')
    .x((d) => xScale(dayjs(d.time).valueOf()))
    .y((d) => yScale(d.value));

  anomalyData?.forEach((element) => {
    if (minutesPerPixel && minutesPerPixel < 0.66) {
      chart
        .append('circle')
        .attr('r', 2)
        .attr('fill', theme.chart.anomalyLines)
        .attr('stroke', theme.chart.anomalyLines)
        .attr('stroke-width', 2)
        .attr('stroke-opacity', exportImg ? 0.7 : 1)
        .attr('class', `anomaly-group-${uuid}`)
        .attr('cx', () => xScale(dayjs(element.yScale[0].time).valueOf()))
        .attr('cy', () => yScale(element.yScale[0].value));
    } else {
      chart
        .append('path')
        .datum(element.yScale)
        .attr('fill', 'none')
        .attr('stroke', theme.chart.anomalyLines)
        .attr('stroke-width', 5)
        .attr('stroke-opacity', exportImg ? 0.7 : 1)
        .attr('class', `anomaly-group-${uuid}`)
        .attr('d', lineGenerator);
    }
  });

  const groupSelector = `.anomaly-group-${uuid}`;
  chart.selectAll(groupSelector).each(function () {
    const filterValue = exportImg ? 'none' : 'blur(2px)';
    chart.selectAll(groupSelector).style('filter', filterValue);
  });
};
