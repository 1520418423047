import { DefaultTheme, Gradients } from 'styled-components';

import { GRADIENTS } from '@controlrooms/constants';
import { SVGDefsSelection } from '@controlrooms/models';
export const appendGradients = (svgDefs: SVGDefsSelection, theme: DefaultTheme) => {
  const gradients = theme.chart.gradients as Gradients;

  // tooltip line gradient
  const tooltipLine = svgDefs
    .append('linearGradient')
    .attr('id', 'tooltipLine')
    .attr('gradientTransform', 'rotate(90)');

  tooltipLine
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', gradients[GRADIENTS.tooltipLine].color1);

  tooltipLine
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', gradients[GRADIENTS.tooltipLine].color2);
};
