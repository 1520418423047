import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAnalytics } from '../../../app/analytics';
import { Role } from '../../../app/constants/auth';
import { TimePresetMap } from '../../../app/constants/time-selection-form';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import {
  FolderSort,
  useFlatFolders,
  useSubSystemsById,
  useSystemsById,
} from '../../../app/hooks/folders';
import { SystemView } from '../../components/analyze-system-browser';
import { TimeSearchContext, dateFormat } from '../../context/time-search-context';
import { useViewContext } from '../../context/view-context';

import EventCard from './event-card';
import { SearchTag } from './search-tag';
import { SelectOption, TimeFrameValue, timeFrameOptions } from './search-timerange-dropdown';
import { ResultContainer, ResultHeader, ResultsWrapper, StyledVirtuoso } from './style';

import { Button, DateTimeRangePicker, Icon, Li, OptionsGroup, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { Plant, SubSystem, TimeSearchResult, Tag } from '@controlrooms/models';

export const SearchEventResults = ({
  startTime,
  endTime,
  tagName,
  folder,
  tag,
}: {
  startTime: string;
  endTime: string;
  tagName: string;
  folder: number;
  tag: Tag;
}) => {
  const { track } = useAnalytics();
  const [showAll, setShowAll] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<TimeSearchResult[]>([
    {
      start_time: startTime,
      end_time: endTime,
      dtw_distance: 0,
      good_search_result: false,
      window_data: [0],
    },
  ]);
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const { hasRole } = useContext(AuthorizationContext);
  const { data: subsystemById } = useSubSystemsById();
  const { data: systemsById } = useSystemsById();
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT);
  const flatFoldersIds = useMemo(
    () => flatFolders?.map((folder) => folder.folder) || [],
    [flatFolders],
  );
  const subFolderData = subsystemById[folder];
  const parentFolderData = systemsById[subFolderData?.parentId as number];
  const foldersWithTags = useMemo(() => {
    return {
      name: '',
      folder: 0,
      has_model: false,
      has_limits: false,
      description: '',
      subfolders: [
        {
          ...parentFolderData,
          subfolders: [
            {
              ...subFolderData,
              tags: [tag],
            },
          ],
        },
      ],
    };
  }, [parentFolderData, subFolderData, tag]);

  const {
    event,
    setEvent,
    setSelectedEvents: setSelectedEventsContext,
    selectedTab,
    setSelectedTab,
    selectedTrendSearchFolders,
    setSelectedTrendSearchFolders,
    setTimeSearchTimeSelection,
    timeSearchTimeSelection,
    setRelatedTags,
  } = useContext(TimeSearchContext);

  // Convert the UNIX time from the current timezone to UTC for the search event endpoint
  const eventStart = dayjs(startTime).utc().format(dateFormat);
  const eventEnd = dayjs(endTime).utc().format(dateFormat);

  const { getAccessTokenSilently } = useAuth0();
  const { tenant } = useTenant();
  const ulRef = useRef(null);

  const [eventResults, setEventResults] = useState<TimeSearchResult[]>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState(timeFrameOptions[2]);
  const [customTimeframe, setCustomTimeframe] = useState({
    startTime: eventStart,
    endTime: eventEnd,
  });
  const [tagFolderResults, setTagFolderResults] = useState<Plant>(
    JSON.parse(JSON.stringify(foldersWithTags)),
  );
  const [flattenedTagFolders, setFlattenedTagFolders] = useState<Array<Tag[]>>([]);
  const [isEventFetching, setIsEventFetching] = useState(false);
  const [isRelatedTagFetching, setIsRelatedTagFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isTimeRangeMenuOpen, setIsTimeRangeMenuOpen] = useState(false);
  const eventQueue = useRef<TimeSearchResult[]>([]);
  const debounceTimeout = useRef<NodeJS.Timeout | number | null>(null);

  const eventSearchEventSource = useRef({} as EventSource);
  const tagSearchEventSource = useRef({} as EventSource);

  const isDefaultTab = useMemo(() => selectedTab?.event_type === 'default', [selectedTab]);

  useEffect(() => {
    getEventSearchResults();
    return () => {
      eventSearchEventSource?.current?.close && eventSearchEventSource?.current?.close();
      tagSearchEventSource?.current?.close && tagSearchEventSource?.current?.close();
      // Clear any existing debounce timer
      clearInterval(debounceTimeout.current as number);
    };
    // eslint-disable-next-line
  }, [selectedTimeRange]);

  const getEventSearchResults = async () => {
    setIsEventFetching(true);
    setIsError(false);
    setEventResults([]);
    eventQueue.current = [];

    const SSR_TIME_SEARCH_API = `${import.meta.env.REACT_APP_CONTROLROOMS_API}/ml-v2/event_search`;
    let searchStart = '';
    let searchEnd = '';
    if (selectedTimeRange.value === TimeFrameValue.CUSTOM_TIMEFRAME) {
      searchStart = customTimeframe.startTime;
      searchEnd = customTimeframe.endTime;
    } else if (selectedTimeRange.value !== TimeFrameValue.ALL_TIME) {
      searchStart = dayjs(startTime)
        .subtract(TimePresetMap.get(selectedTimeRange.value as string) as number, 'seconds')
        .utc()
        .format(dateFormat);
      searchEnd = dayjs(startTime).utc().format(dateFormat);
    }
    const queryString = new URLSearchParams({
      tag_name: tagName,
      tag_start_time: eventStart,
      tag_end_time: eventEnd,
      search_start_time: searchStart,
      search_end_time: searchEnd,
    }).toString();
    const authToken = await getAccessTokenSilently({ ignoreCache: true, tenant });

    eventSearchEventSource.current = new EventSourcePolyfill(
      `${SSR_TIME_SEARCH_API}?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    eventSearchEventSource?.current?.addEventListener('message', (e: MessageEvent) => {
      const newEvents: TimeSearchResult[] = JSON.parse(e.data);
      // Add the new event data to the queue
      if (newEvents) {
        // Get the obsolete events list from new events
        const obsoleteEvents = new Set(
          newEvents.filter((e) => e.is_obsolete).map((e) => e.start_time),
        );

        // Filter obsolete events from all events
        const filteredEvents = [...eventQueue.current, ...newEvents].filter(
          (e) => !obsoleteEvents.has(e.start_time),
        );

        // Sorting events based on dtw_distance value
        filteredEvents.sort((a, b) => a.dtw_distance - b.dtw_distance);

        eventQueue.current = filteredEvents;
      }
      // Debounce timer to update the events for each seconds
      if (!debounceTimeout.current) {
        debounceTimeout.current = setInterval(() => {
          setEventResults([...eventQueue.current]);
        }, 1000);
      }
    });
    // eslint-disable-next-line
    eventSearchEventSource?.current?.addEventListener('error', (e: any) => {
      // Clear any existing debounce timer
      clearInterval(debounceTimeout.current as number);
      debounceTimeout.current = null;
      setEventResults([...eventQueue.current]);
      eventSearchEventSource?.current?.close();
      setIsEventFetching(false);
      if (e.error != undefined) {
        setIsError(true);
      } else {
        track('Trend Search - Event search complete', {
          tag_name: tagName,
          tag_start_time: eventStart,
          tag_end_time: eventEnd,
          search_start_time: searchStart,
          search_end_time: searchEnd,
        });
      }
    });
  };

  const getTagSearchResults = async () => {
    setIsRelatedTagFetching(true);
    setIsError(false);
    setTagFolderResults(JSON.parse(JSON.stringify(foldersWithTags)));

    const SSR_TAG_SEARCH_API = `${import.meta.env.REACT_APP_CONTROLROOMS_API}/ml-v2/tag_search`;
    const eventsQuerySting = selectedEvents
      .filter((_, i) => i !== 0 || selectedEvents.length === 1)
      .reduce((acc, event) => {
        return `${acc}&start_time=${encodeURIComponent(
          event.start_time,
        )}&end_time=${encodeURIComponent(event.end_time)}`;
      }, '');
    const queryString = new URLSearchParams({
      tag_name: tagName,
      tag_start_time: eventStart,
      tag_end_time: eventEnd,
      system_id: `${folder}`,
    }).toString();
    const authToken = await getAccessTokenSilently({ ignoreCache: true, tenant });

    tagSearchEventSource.current = new EventSourcePolyfill(
      `${SSR_TAG_SEARCH_API}?${queryString}${eventsQuerySting}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        heartbeatTimeout: 300000,
      },
    );
    tagSearchEventSource?.current?.addEventListener('message', (e: MessageEvent) => {
      const parsedData = JSON.parse(e.data);
      if (parsedData[0]) {
        const newFolders = {
          ...subFolderData,
          ...parsedData[0],
        };

        setTagFolderResults((prev) => {
          if (newFolders?.folder === folder) {
            prev.subfolders[0].subfolders.shift();
          }
          prev.subfolders[0].subfolders.push(newFolders);
          return { ...prev };
        });
        setFlattenedTagFolders((prev) => {
          prev[parsedData[0].folder] = parsedData[0].tags;
          return prev;
        });
        if (newFolders?.folder === folder) {
          constructRelatedTags([folder], true);
        }
      }
    });
    // eslint-disable-next-line
    tagSearchEventSource?.current?.addEventListener('error', (e: any) => {
      tagSearchEventSource.current?.close();
      setIsRelatedTagFetching(false);
      if (e.error != undefined) {
        setIsError(true);
      } else {
        track('Trend Search - Tag search complete', {
          tag_name: tagName,
          tag_start_time: eventStart,
          tag_end_time: eventEnd,
          system_id: `${folder}`,
        });
        // Sorting systems by Infra tag order - same as like analyze page
        setTagFolderResults((prev) => {
          prev.subfolders[0].subfolders.sort(function (a, b) {
            return flatFoldersIds?.indexOf(a.folder) - flatFoldersIds?.indexOf(b.folder);
          });
          return { ...prev };
        });
      }
    });
  };

  const filteredResults = useMemo(
    () => (showAll ? eventResults : eventResults.filter((t) => t.good_search_result)),
    [showAll, eventResults],
  );

  const relatedTagsCount = useMemo(
    () =>
      tagFolderResults?.subfolders[0]?.subfolders.reduce(
        (acc, sf: SubSystem) => sf?.tags.length + acc,
        0,
      ),
    [tagFolderResults],
  );

  const handleEventSelect = useCallback(
    (event: TimeSearchResult) => {
      const isSelected = selectedEvents.some(
        (selectedEvent) =>
          event.start_time === selectedEvent.start_time &&
          event.end_time === selectedEvent.end_time,
      );

      if (isSelected) {
        setSelectedEvents(
          selectedEvents.filter(
            (selectedEvent) =>
              !(
                event.start_time === selectedEvent.start_time &&
                event.end_time === selectedEvent.end_time
              ),
          ),
        );
      } else {
        setSelectedEvents([...selectedEvents, event]);
      }
    },
    [selectedEvents, setSelectedEvents],
  );

  const constructRelatedTags = useCallback(
    (selectedFolders: number[], isDefaultFolder?: boolean) => {
      const selectedTags = selectedFolders.map((folder) => {
        let folderIds = flattenedTagFolders[folder]?.map((tag) => tag?.name) || [];
        if (isDefaultFolder) {
          folderIds = folderIds?.filter((id) => id !== tag.name);
          folderIds = [tag.name, ...folderIds];
        }
        return {
          folder,
          tags: folderIds,
        };
      });
      selectedTags.sort((a, b) => {
        return flatFoldersIds?.indexOf(a.folder) - flatFoldersIds?.indexOf(b.folder);
      });
      setRelatedTags(selectedTags);
    },
    [flattenedTagFolders, flatFoldersIds, setRelatedTags, tag.name],
  );

  const handleCheckboxCheck = useCallback(
    (id: number) => {
      let selectedFolders = [];
      if (selectedTrendSearchFolders?.includes(id)) {
        // remove id of subfolder or parent folder id
        const newArr = selectedTrendSearchFolders.filter((x) => {
          return x !== id;
        });
        selectedFolders = [...newArr];
      } else {
        selectedFolders = [...selectedTrendSearchFolders, id];
      }
      setSelectedTrendSearchFolders(selectedFolders);
      constructRelatedTags(selectedFolders, id === folder);
    },
    [selectedTrendSearchFolders, setSelectedTrendSearchFolders, constructRelatedTags, folder],
  );

  const handleFindRelatedTags = () => {
    track('Trend Search - Find related tags click', {
      selectedEvents,
      folder,
    });
    getTagSearchResults();
    setSelectedEventsContext(selectedEvents);
    setSelectedTab(selectedEvents[0]);
    setTimeSearchTimeSelection({
      ...timeSearchTimeSelection,
      startTime: dayjs.utc(selectedEvents[0]?.start_time).tz(timezone),
      endTime: dayjs.utc(selectedEvents[0]?.end_time).tz(timezone),
    });
    setSelectedTrendSearchFolders([folder]);
    setRelatedTags([
      {
        folder,
        tags: [tagName],
      },
    ]);
  };

  const setDefaultEvent = () => {
    let isFilteredEvent = false;
    if (event.startTime === startTime && event.endTime === endTime) {
      isFilteredEvent = true;
    } else {
      isFilteredEvent = eventResults
        .filter((t) => t.dtw_distance <= 0.2)
        ?.some(
          (result) => event?.startTime === result.start_time && event?.endTime === result.end_time,
        );
    }
    if (!isFilteredEvent) {
      setEvent({
        id: -1,
        startTime,
        endTime,
        folder,
        tagName: tag.name,
        tag,
      });
    }
  };

  // To cancel event and related tag search
  const onCancelSearch = () => {
    if (isDefaultTab) {
      setIsEventFetching(false);
      eventSearchEventSource?.current?.close && eventSearchEventSource?.current?.close();
    } else {
      setIsRelatedTagFetching(false);
      tagSearchEventSource?.current?.close && tagSearchEventSource?.current?.close();
      // Sorting systems by Infra tag order - same as like analyze page
      setTagFolderResults((prev) => {
        prev.subfolders[0].subfolders.sort(function (a, b) {
          return flatFoldersIds?.indexOf(a.folder) - flatFoldersIds?.indexOf(b.folder);
        });
        return { ...prev };
      });
    }
  };

  const handleTimeRageClick = (timeFrame: SelectOption) => {
    setIsTimeRangeMenuOpen(false);
    if (timeFrame.value !== selectedTimeRange.value) {
      setSelectedTimeRange(timeFrame);
      setEventResults([]);
      setShowAll(false);
    }
  };

  if (isError && !eventResults.length) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          marginTop: '30px',
          fontSize: '14px',
        }}
      >
        <span>
          Oops! Something went wrong.
          <Button
            buttonSize="large"
            buttonType="link"
            onClick={() => {
              isDefaultTab ? getEventSearchResults() : getTagSearchResults();
            }}
            style={{
              marginLeft: '5px',
            }}
          >
            Retry?
          </Button>
        </span>
      </div>
    );
  }

  return (
    <ResultsWrapper>
      <SearchTag
        startTime={startTime}
        endTime={endTime}
        tag={tag}
        folder={folder}
        selectedEvents={selectedEvents}
        handleEventSelect={handleEventSelect}
      />
      <ResultHeader>
        {isDefaultTab ? (
          <>
            <>
              {isEventFetching ? (
                <SearchingSpinner onCancel={onCancelSearch} />
              ) : (
                <span>{filteredResults.length} matching events</span>
              )}
            </>
            <div className="filter-section">
              <OptionsGroup className="match-select">
                <Button
                  data-testid="match-type"
                  buttonSize="small"
                  buttonType="menu-text"
                  className="match-title"
                  onClick={() => {
                    setIsTimeRangeMenuOpen(!isTimeRangeMenuOpen);
                  }}
                  aria-haspopup="listbox"
                  aria-expanded={isTimeRangeMenuOpen}
                >
                  {`Within ${selectedTimeRange?.label}`}
                </Button>
                <Ul isOpen={isTimeRangeMenuOpen} className="match-dropdown" ref={ulRef}>
                  {timeFrameOptions?.map((timeFrame) => (
                    <React.Fragment key={timeFrame.dataTestId}>
                      {timeFrame.value !== TimeFrameValue.CUSTOM_TIMEFRAME ? (
                        <Li
                          className="match-menu-item"
                          data-testid={timeFrame.dataTestId}
                          onClick={() => {
                            handleTimeRageClick(timeFrame);
                          }}
                        >
                          <span>{timeFrame.label}</span>
                        </Li>
                      ) : (
                        <Li className="match-menu-item" data-testid={timeFrame.dataTestId}>
                          <DateTimeRangePicker
                            initialValues={{
                              start: dayjs(customTimeframe.startTime),
                              end: dayjs(customTimeframe.endTime),
                            }}
                            onApply={([start, end]) => {
                              setCustomTimeframe({
                                startTime: dayjs
                                  .tz(start.format('YYYY-MM-DDTHH:mm:ss'), timezone)
                                  .utc()
                                  .format(dateFormat),
                                endTime: dayjs
                                  .tz(end.format('YYYY-MM-DDTHH:mm:ss'), timezone)
                                  .utc()
                                  .format(dateFormat),
                              });
                              setIsTimeRangeMenuOpen(false);
                              setSelectedTimeRange({ ...timeFrame });
                            }}
                            positions={['right']}
                            align="center"
                          >
                            <div className="custom-timeframe">
                              <span>{timeFrame.label}</span>
                              <Icon name="chevron" rotate={-90} height="6" />
                            </div>
                          </DateTimeRangePicker>
                        </Li>
                      )}
                    </React.Fragment>
                  ))}
                </Ul>
              </OptionsGroup>
            </div>
          </>
        ) : (
          <>
            {isRelatedTagFetching ? (
              <SearchingSpinner onCancel={onCancelSearch} />
            ) : (
              <span>{relatedTagsCount || 0} related tags</span>
            )}
          </>
        )}
      </ResultHeader>
      <ResultContainer isDefaultTab={isDefaultTab}>
        {isDefaultTab ? (
          <StyledVirtuoso
            className="custom-scroll"
            style={{
              overflow: 'auto',
              height: `calc(100vh - 385px)`,
            }}
            totalCount={filteredResults.length}
            itemContent={(index) => (
              <>
                <EventCard
                  event={filteredResults[index]}
                  selectedEvents={selectedEvents}
                  tagName={tagName}
                  folder={folder}
                  handleEventSelect={handleEventSelect}
                  tag={tag}
                />
                {index + 1 === filteredResults.length && (
                  <>
                    {eventResults.length > 0 && isDefaultTab && (
                      <Button
                        buttonType="link"
                        buttonSize="small"
                        style={{ width: '100%' }}
                        onClick={() => {
                          if (showAll) {
                            setDefaultEvent();
                          }
                          setShowAll(!showAll);
                        }}
                      >
                        {`${showAll ? 'Hide' : 'Show'} less similar matches`}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          />
        ) : (
          <SystemView
            handleCheckboxCheck={handleCheckboxCheck}
            plant={tagFolderResults}
            selectedFolders={selectedTrendSearchFolders}
          />
        )}
      </ResultContainer>
      {isDefaultTab && hasRole(Role.TAG_SECONDARY_SEARCH) && (
        <div className="find-more">
          <div>Find other tags that moved during selected events.</div>
          <Button
            buttonType="primary"
            buttonSize="large"
            onClick={handleFindRelatedTags}
            disabled={selectedEvents.length ? false : true}
          >
            Find related tags
          </Button>
        </div>
      )}
    </ResultsWrapper>
  );
};

const SearchingSpinner = ({ onCancel }: { onCancel: () => void }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
    className="spinner"
  >
    <span>Searching...</span>
    <Icon name={ICONS.Spinner} />
    <Icon
      name={ICONS.Close}
      onClick={() => {
        onCancel();
      }}
    />
  </div>
);
